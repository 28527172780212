import * as React from 'react';

import { Box, Fab, Popover, Typography } from '@mui/material';
import { IconDots, IconDotsVertical } from '@tabler/icons-react';

interface props {
  children: React.ReactNode;
  onCloseHover?: () => void;
  rotateDot?: boolean;
}

function ExpandAction({ children, onCloseHover, rotateDot = false }: props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onCloseHover && onCloseHover();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Fab
        sx={{
          backgroundColor: 'inherit',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
          },
        }}
        color="default"
        size="small"
        aria-describedby={id}
        onClick={handleClick}
      >
        {rotateDot ? <IconDots /> : <IconDotsVertical />}
      </Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <Box onClick={handleClose}>{children}</Box>
        </Typography>
      </Popover>
    </Box>
  );
}

export default React.memo(ExpandAction);
