import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AutoComplete from 'components/shared/AutoComplete';
import TextInput from 'components/shared/TextInput';
import TransactionChip from 'components/shared/TransactionChip';
import TableNormal from 'components/table/TableNormal';
import { DATE_FORMAT, DATE_TIME_FORMAT, DEFAULT_DEBOUNCE } from 'constants/common';
import { ROUTES } from 'constants/router';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from 'enums';
import { useDirection } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { customizerSelector } from 'store/customizer';
import {
  getFloorInBuildingAction,
  getListRoomInFloorAction,
  propertySelector,
} from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import {
  getListTransactionInBuildingPagingAction,
  getOverviewTransactionInBuildingAction,
  transactionSelector,
} from 'store/transaction';
import {
  cleanSearchString,
  formatDate,
  formatPrice,
  isAllow,
  parseStringToDate,
  showData,
} from 'utils/common';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { IconAlignJustified, IconDoor, IconSearch, IconStack2 } from '@tabler/icons-react';
import DateInput from 'components/shared/DateInput';
import moment from 'moment';
import { PERMISSION } from 'constants/permission';

const TRANSACTION_TABLE_HEADER = [
  'transaction-code',
  'status',
  'invoice-code',
  'booking-code',
  'floor',
  'room',
  'transaction-type',
  'collect',
  'expenses',
  'payment-method',
  'creation-date',
  'executor',
  'payer',
];

const dataInitial = {
  startDate: moment().startOf('month').format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  transactionTypeId: '',
  paymentMethodId: '',
  searchValue: '',
  roomId: '',
  floorId: '',
  status: '',
};

const InvoiceTransactionContainerTab = () => {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const dispatch = useDispatch();
  const { listTransactionInBuildingPaging } = useSelector(transactionSelector);
  const { buildingId, listRoomInFloor, floorsInBuilding } = useSelector(propertySelector);
  const [filterData, setFilterData] = useState(dataInitial);
  const debounceSearch = useDebounce(
    cleanSearchString(filterData.searchValue || ''),
    DEFAULT_DEBOUNCE,
  );
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { isLanguage } = useSelector(customizerSelector);

  useEffect(() => {
    //**NOTE: Get list floor */
    dispatch(getFloorInBuildingAction({ buildingId }));
  }, [buildingId, dispatch]);

  useEffect(() => {
    //**NOTE: Get list room in floor */
    if (filterData.floorId)
      dispatch(getListRoomInFloorAction({ buildingId, floorId: filterData.floorId }));
  }, [buildingId, dispatch, filterData.floorId]);

  useEffect(() => {
    dispatch(
      getOverviewTransactionInBuildingAction({
        buildingId,
        startDate: moment(parseStringToDate(filterData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD'),
      }),
    );
  }, [buildingId, dispatch, filterData.endDate, filterData.startDate]);

  const handleFilterData = useCallback(() => {
    dispatch(
      getListTransactionInBuildingPagingAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
        q: debounceSearch,
        floorId: filterData.floorId,
        roomId: filterData.roomId,
        status: filterData.status,
        startDate: moment(parseStringToDate(filterData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD'),
      }),
    );
  }, [
    dispatch,
    buildingId,
    currentPage,
    rowsPerPage,
    debounceSearch,
    filterData.roomId,
    filterData.floorId,
    filterData.status,
    filterData.startDate,
    filterData.endDate,
  ]);

  const handleChangeFilterData = (key: string) => (value: string) => {
    if (key === 'startDate') {
      const formattedStartDate = moment(parseStringToDate(value)).format('YYYY-MM-DD');
      const formattedEndDate = moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD');
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
        endDate: moment(formattedStartDate).isAfter(moment(formattedEndDate))
          ? value
          : filterData.endDate,
      }));
    } else if (key === 'floorId')
      setFilterData((prev) => ({
        ...prev,
        roomId: '',
        [key]: value,
      }));
    else
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
      }));
  };

  useEffect(() => {
    //**NOTE: Filter Table */
    handleFilterData();
  }, [handleFilterData]);

  const transactionTableRows = useMemo(() => {
    return listTransactionInBuildingPaging.data.map((transaction) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          {isAllow([PERMISSION.INVOICE_DETAIL]) ? (
            <Link
              onClick={goTo(`${ROUTES.INVOICE.LIST}/${transaction.invoiceId}`)}
              underline="none"
            >
              <Typography variant="subtitle1">{showData(transaction.name)}</Typography>
            </Link>
          ) : (
            <Typography variant="subtitle1">{showData(transaction.name)}</Typography>
          )}
        </Stack>,
        <TransactionChip key={uniqueId()} status={transaction.transactionType?.type} />,
        isAllow([PERMISSION.INVOICE_DETAIL]) ? (
          <Link
            key={uniqueId()}
            onClick={goTo(`${ROUTES.INVOICE.LIST}/${transaction.invoiceId}`)}
            underline="none"
          >
            <Typography variant="subtitle1">{showData(transaction?.invoice?.name)}</Typography>
          </Link>
        ) : (
          <Typography variant="subtitle1">{showData(transaction?.invoice?.name)}</Typography>
        ),
        <Link key={uniqueId()} href="#" underline="none">
          <Typography color="textSecondary" variant="subtitle1">
            {showData(transaction.contract?.bookingId)}
          </Typography>
        </Link>,
        <Typography key={uniqueId()}>
          {showData(transaction.contract?.infoRoom?.floor?.name)}
        </Typography>,
        isAllow([PERMISSION.GET_ROOM_DETAIL]) ? (
          <Link
            key={uniqueId()}
            onClick={goTo(`${ROUTES.ROOM.LIST}/${transaction.contract?.infoRoom?.room?.id}`)}
            underline="none"
          >
            {showData(transaction.contract?.infoRoom?.room?.name)}
          </Link>
        ) : (
          <Typography>{showData(transaction.contract?.infoRoom?.room?.name)}</Typography>
        ),
        <Typography key={uniqueId()} color="textSecondary" variant="subtitle1" fontWeight="400">
          {showData(
            isLanguage === 'vi'
              ? transaction.transactionType?.nameVi
              : transaction.transactionType?.nameEn,
          )}
        </Typography>,
        showData(
          transaction.transactionType?.type === TRANSACTION_STATUS.INCOME
            ? formatPrice(String(transaction.transactionAmount))
            : null,
        ),
        showData(
          transaction.transactionType?.type === TRANSACTION_STATUS.EXPENSE
            ? formatPrice(String(transaction.transactionAmount))
            : null,
        ),
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {showData(transaction.paymentMethod?.name)}
        </Typography>,
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.createdBy?.fullName)}</Typography>
        </Link>,
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.paidUser?.fullName)}</Typography>
        </Link>,
      ];
    });
  }, [listTransactionInBuildingPaging.data, goTo, isLanguage]);

  return (
    <Box>
      <Box
        sx={{
          flex: '1 1 100%',
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid container spacing="12px">
          <Grid item xs={12} sm={12} lg={3}>
            <TextInput
              placeholder={`${t('placeholder.search-invoice')}` || ''}
              Icon={IconSearch}
              value={filterData.searchValue}
              onChange={handleChangeFilterData('searchValue')}
            />
          </Grid>
          <Grid item xs={6} sm={6} lg={2}>
            <AutoComplete
              options={floorsInBuilding.map((floor) => ({
                label: showData(floor.name),
                value: String(floor.id),
              }))}
              placeHolder="placeholder.choose-floor"
              Icon={IconStack2}
              value={filterData.floorId}
              onChange={handleChangeFilterData('floorId')}
            />
          </Grid>
          <Grid item xs={6} sm={6} lg={2}>
            <AutoComplete
              options={listRoomInFloor.map((floor) => ({
                label: showData(floor.name),
                value: String(floor.id),
              }))}
              placeHolder="placeholder.choose-room"
              Icon={IconDoor}
              value={filterData.roomId}
              onChange={handleChangeFilterData('roomId')}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <AutoComplete
              options={Object.keys(TRANSACTION_STATUS).map((status) => ({
                label: t(showData(TRANSACTION_TYPE[status as keyof typeof TRANSACTION_TYPE])),
                value: status,
              }))}
              placeHolder="placeholder.choose-status"
              Icon={IconAlignJustified}
              value={filterData.status}
              onChange={handleChangeFilterData('status')}
            />
          </Grid>
          <Grid
            item
            lg={3}
            xs={12}
            sm={6}
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={5.5}>
              <DateInput
                onChange={handleChangeFilterData('startDate')}
                value={String(filterData.startDate)}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography textAlign={'center'} py={1}>
                {t('to')}
              </Typography>
            </Grid>
            <Grid item xs={5.5}>
              <DateInput
                onChange={handleChangeFilterData('endDate')}
                value={String(filterData.endDate)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <TableNormal
        header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
        data={transactionTableRows}
        total={listTransactionInBuildingPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
    </Box>
  );
};
export default InvoiceTransactionContainerTab;
