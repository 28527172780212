import { CustomResponseType } from 'types/common.type';
import { Contract, GetListContractRoomRes } from 'types/contract.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getContractAvailableAction,
  getContractDetailAction,
  getContractInBuildingPagingAction,
  getListContractInRoomAction,
  getListContractPagingAction,
} from './actions';
import { IncurredExpensesType } from 'types/fee.type';
import { STORAGE_KEY } from 'enums/localStorage';
import { LocalStorage } from 'utils/localStorage';

interface ContractState {
  listContractPaging: { data: GetListContractRoomRes[]; total: number };
  listContractInBuildingPaging: { data: GetListContractRoomRes[]; total: number };
  listContractAvailable: GetListContractRoomRes[];
  listContractInRoom: GetListContractRoomRes[];
  contractDetail: Contract;
  incurredExpensesList: { data: IncurredExpensesType[]; total: number };
}
const initialState: ContractState = {
  listContractPaging: {
    data: [],
    total: 0,
  },
  listContractAvailable: [],
  listContractInBuildingPaging: {
    data: [],
    total: 0,
  },
  listContractInRoom: [],
  contractDetail: {},
  incurredExpensesList: { data: [], total: 0 },
};

const Storage = new LocalStorage();

export const contractSlice = createSlice({
  name: 'contract',
  reducers: {
    createIncurredExpenses: (state, action) => {
      if (action.payload) {
        const incurredExpensesListStore = Storage.getStorageItem(STORAGE_KEY.INCURRED_EXPENSES);
        const newDataList = [...(incurredExpensesListStore || []), action.payload];

        const total = newDataList
          .filter((item: IncurredExpensesType) => item.bookingId === action.payload.bookingId)
          .reduce((accumulator, currentValue) => accumulator + Number(currentValue.price), 0);

        state.incurredExpensesList = {
          data: newDataList,
          total: total,
        };

        Storage.setStorageItem(STORAGE_KEY.INCURRED_EXPENSES, newDataList);
      }
    },

    getIncurredExpensesList: (state, action) => {
      if (action.payload.bookingId) {
        const bookingId = action.payload.bookingId;
        const incurredExpensesListStore = Storage.getStorageItem(STORAGE_KEY.INCURRED_EXPENSES);

        if (incurredExpensesListStore) {
          const filteredList = incurredExpensesListStore.filter(
            (item: IncurredExpensesType) => item.bookingId === bookingId,
          );

          const total = filteredList.reduce(
            (accumulator: number, currentValue: IncurredExpensesType) =>
              accumulator + Number(currentValue.price),
            0,
          );

          state.incurredExpensesList = {
            data: filteredList,
            total: total,
          };
        }
      } else {
        state.incurredExpensesList = initialState.incurredExpensesList;
      }
    },
    removeIncurredExpense: (state, action) => {
      if (action.payload.id) {
        const incurredExpensesListStore = Storage.getStorageItem(STORAGE_KEY.INCURRED_EXPENSES);

        if (incurredExpensesListStore) {
          const findItemById = incurredExpensesListStore.find(
            (item: IncurredExpensesType) => String(item.id) === String(action.payload.id),
          );

          if (findItemById) {
            const filteredList = incurredExpensesListStore.filter(
              (item: IncurredExpensesType) => String(item.id) !== String(action.payload.id),
            );
            Storage.setStorageItem(STORAGE_KEY.INCURRED_EXPENSES, filteredList);

            const total = filteredList
              .filter((item: IncurredExpensesType) => item.bookingId === findItemById.bookingId)
              .reduce(
                (accumulator: number, currentValue: IncurredExpensesType) =>
                  accumulator + Number(currentValue.price),
                0,
              );

            state.incurredExpensesList = {
              data: filteredList,
              total: total,
            };
          }
        }
      }
    },
  },
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<ContractState>) => {
    builder.addCase(
      getListContractPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listContractPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListContractInRoomAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listContractInRoom = action.payload.data;
        }
      },
    );
    builder.addCase(
      getContractDetailAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.contractDetail = action.payload.data;
        }
      },
    );
    builder.addCase(
      getContractInBuildingPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listContractInBuildingPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getContractAvailableAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listContractAvailable = action.payload.data;
        }
      },
    );
  },
});

export const { getIncurredExpensesList, createIncurredExpenses, removeIncurredExpense } =
  contractSlice.actions;
export default contractSlice.reducer;
