import { isUndefined } from 'lodash';
import { centralGW } from 'services/axios.service';
import { IdCardReq } from 'types/client.type';
import { CommonReq } from 'types/common.type';
import { MaintenanceReq, MoveTab, MoveTask, TaskComment } from 'types/maintenance.type';

export const getKanbanList = (payload: CommonReq) => {
  const { buildingId } = payload;

  return centralGW.get(`/api/v1/kanban/building/${buildingId}/board`, {
    isDisableToast: true,
  });
};
export const getLabelTaskList = () => {
  return centralGW.get(`/api/v1/kanban/label-types`, {
    isDisableToast: true,
  });
};

// NOTE: maintenance tab
export const getKanbanTabs = (payload: CommonReq) => {
  const { boardId } = payload;

  return centralGW.get(`/api/v1/kanban/board/${boardId}/tabs`, {
    isDisableToast: true,
  });
};
export const createTab = (payload: CommonReq & MaintenanceReq) => {
  const { boardId, name } = payload;

  return centralGW.post(`/api/v1/kanban/board/${boardId}/tab/create`, {
    name,
  });
};
export const deleteTab = (payload: CommonReq) => {
  const { tabId } = payload;

  return centralGW.delete(`/api/v1/kanban/tab/${tabId}/delete`);
};
export const updateTab = (payload: MaintenanceReq) => {
  const { tabId, name } = payload;

  return centralGW.put(`/api/v1/kanban/tab/${tabId}/update-name`, {
    name,
  });
};
export const moveTab = (payload: CommonReq & MoveTab) => {
  const { tabId, updatedTabs } = payload;

  return centralGW.put(
    `/api/v1/kanban/tab/${tabId}/move`,
    {
      updatedTabs,
    },
    {
      isDisableToast: true,
      isDisableLoading: true,
    },
  );
};

export const getKanbanTasks = (payload: CommonReq) => {
  const { boardId } = payload;

  return centralGW.get(`/api/v1/kanban/board/${boardId}/tasks`, {
    isDisableToast: true,
  });
};

export const getKanbanTaskDetail = (payload: MoveTask) => {
  const { taskId } = payload;

  return centralGW.get(`/api/v1/kanban/task/${taskId}`, {
    isDisableToast: true,
  });
};
export const createTaskMaintenance = (payload: CommonReq & MaintenanceReq) => {
  const { name, description, assignedEmployeeId, labelTypes, tabId, endAt } = payload;

  return centralGW.post(`/api/v1/kanban/task`, {
    name,
    description,
    assignedEmployeeId: isUndefined(assignedEmployeeId) ? null : assignedEmployeeId,
    tabId,
    endAt,
    labelTypes,
  });
};

export const moveTaskItem = (payload: CommonReq & MoveTask) => {
  const { taskId, sourceTabId, destinationTabId, destinationTasks, sourceTasks } = payload;

  return centralGW.put(
    `/api/v1/kanban/task/${taskId}/move`,
    {
      taskId,
      sourceTabId,
      destinationTabId,
      destinationTasks,
      sourceTasks,
    },
    {
      isDisableToast: true,
      isDisableLoading: true,
    },
  );
};

export const deleteTaskItem = (payload: CommonReq & MoveTask) => {
  const { taskId } = payload;

  return centralGW.delete(`/api/v1/kanban/task/${taskId}/delete`);
};

export const updateTaskMaintenance = (payload: MoveTask & MaintenanceReq) => {
  const { name, description, assignedEmployeeId, endAt, taskId, labelTypes } = payload;

  return centralGW.put(`/api/v1/kanban/task/${taskId}/update-info`, {
    name,
    description,
    assignedEmployeeId: isUndefined(assignedEmployeeId) ? null : assignedEmployeeId,
    ...(endAt ? { endAt } : {}),
    labelTypes,
  });
};

// NOTE: task history
export const getKanbanTaskHistory = (payload: MoveTask) => {
  const { taskId } = payload;

  return centralGW.get(`/api/v1/kanban/task/${taskId}/history`, {
    isDisableToast: true,
  });
};

// NOTE: task comment
export const getKanbanTaskComment = (payload: MoveTask) => {
  const { taskId } = payload;

  return centralGW.get(`/api/v1/kanban/task/${taskId}/comments`, {
    isDisableToast: true,
  });
};
export const createTaskComment = (payload: TaskComment) => {
  const { taskId, comment, imageUrl } = payload;

  return centralGW.post(
    `/api/v1/kanban/task/${taskId}/comment/create`,
    {
      comment,
      imageUrl,
    },
    {
      isDisableToast: true,
    },
  );
};
export const uploadFileComment = async (payload: IdCardReq & { isDisableToast?: boolean }) => {
  const formData = new FormData();
  formData.append('image', payload.image);

  return centralGW.post('/api/v1/external/image/upload', formData, {
    isDisableToast: payload.isDisableToast,
  });
};
