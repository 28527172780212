import React, { useState } from 'react';

import Scrollbar from 'components/custom-scroll/Scrollbar';
import BlankCard from 'components/shared/BlankCard';
import CopyOnHover from 'components/shared/CopyOnHover';
import { DEFAULT_LIMIT, ROW_PER_PAGE_OPTIONS } from 'constants/common';
import { useTranslation } from 'react-i18next';
import { DropDownTableProps } from 'types/table.type';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

const DropdownTable = ({
  header = [],
  data = [],
  total = 0,
  currentPage = 1,
  limit = DEFAULT_LIMIT,
  isAction = false,
  onChangePage,
  onChangeLimit,
}: DropDownTableProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [ids, setIds] = useState<any[]>([]);
  const onToggle = (id: number) => () => {
    if (ids.includes(id)) {
      setIds((prev) => prev.filter((i) => i !== id));
    } else {
      setIds((prev) => prev.concat([id]));
    }
  };
  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    onChangePage && onChangePage(page);
  };

  const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChangeLimit && onChangeLimit(Number(event.target.value));
  };

  return (
    <BlankCard>
      <TableContainer component={Paper}>
        <Scrollbar>
          <Table
            size="medium"
            sx={{
              minWidth: '750px',
              whiteSpace: {
                xs: 'nowrap',
                sm: 'unset',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {header.map((item, cIdx) => (
                  <TableCell
                    key={cIdx}
                    sx={{
                      textAlign: 'center',
                      minWidth: '150px',
                      position: isAction && cIdx === header.length - 1 ? 'sticky' : 'initial',
                      right: isAction && cIdx === header.length - 1 ? 0 : 'auto',
                      backgroundColor:
                        isAction && cIdx === header.length - 1
                          ? 'var(--header-table-color)'
                          : 'inherit',
                      boxShadow:
                        isAction && cIdx === header.length - 1
                          ? `2px 0 0 ${theme.palette.divider} inset`
                          : '',
                      zIndex: isAction && cIdx === header.length - 1 ? 1 : 'auto',
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.length > 0 &&
                data.map((item, rIdx) => (
                  <React.Fragment key={rIdx}>
                    <TableRow>
                      <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={onToggle(rIdx)}>
                          {ids.includes(rIdx) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      {item.row?.map((col: any, cIdx: number) => (
                        <TableCell
                          key={`${rIdx}${cIdx}`}
                          sx={{
                            textAlign: 'center',
                            position:
                              isAction && cIdx === item.row!.length - 1 ? 'sticky' : 'initial',
                            right: isAction && cIdx === item.row!.length - 1 ? 0 : 'auto',
                            backgroundColor:
                              isAction && cIdx === item.row!.length - 1 ? 'white' : 'inherit',
                            boxShadow:
                              isAction && cIdx === item.row!.length - 1
                                ? `2px 0 0 ${theme.palette.divider} inset`
                                : '',
                            zIndex: isAction && cIdx === item.row!.length - 1 ? 1 : 'auto',
                          }}
                        >
                          <CopyOnHover text={col} />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={ids.includes(rIdx)} timeout="auto" unmountOnExit>
                          <Table size="small">
                            <TableHead color="">
                              <TableRow>
                                {item.children?.header?.map((item, cIdx) => (
                                  <TableCell key={cIdx}>{t(item)}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.children?.row?.map((dataRow: any, idx) => (
                                <TableRow key={idx}>
                                  {dataRow.map((col: any, cIdx: number) => (
                                    <TableCell key={`${rIdx}${cIdx}`}>{col}</TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </Scrollbar>
        {!data.length && (
          <Box width="100%" display="flex" justifyContent="center" p={5}>
            <Typography>{t('no-data')}</Typography>
          </Box>
        )}
        <TablePagination
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
          rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
          colSpan={header.length + 1}
          count={total}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeLimit}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </BlankCard>
  );
};

export default React.memo(DropdownTable);
