import React, { forwardRef, useImperativeHandle, useState } from 'react';

import InputNumber from 'components/shared/InputNumber';
import InputPrice from 'components/shared/InputPrice';
import TextInput from 'components/shared/TextInput';
import { JOI } from 'constants/joi';
import { RoomCategoryType } from 'types/room.type';
import { createSchema, validateSchema } from 'utils/joi';

import { Grid } from '@mui/material';
import { IconBrandAppleArcade, IconCoin, IconUsers } from '@tabler/icons-react';

interface Props {
  data: RoomCategoryType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
  isDisable?: boolean;
}

const RoomTypeForm = forwardRef(({ data, onChange, onSubmit, isDisable = false }: Props, ref) => {
  const handleChange = (key: string) => (value: string | boolean) => {
    onChange(key, value);
  };

  const schema = createSchema({
    name: JOI.REQUIRE,
    maxUser: JOI.NUMBER,
    referencePrice: JOI.PRICE,
  });
  const [roomTypeError, setRoomTypeError] = useState({
    name: '',
    maxUser: '',
    referencePrice: '',
  });

  const handleSubmitRoomType = () => {
    const resError = validateSchema(schema, data);
    setRoomTypeError(resError.errorMessage);
    if (!resError.isError) {
      onSubmit && onSubmit();
    }
  };
  useImperativeHandle(ref, () => ({
    handleSubmitRoomType,
  }));

  return (
    <Grid container spacing="12px">
      <Grid item xs={12}>
        <TextInput
          isRequired
          title="room-type-name"
          Icon={IconBrandAppleArcade}
          value={data.name}
          placeholder="placeholder.room-type"
          onChange={handleChange('name')}
          errorMessage={roomTypeError.name}
          isDisable={isDisable}
          autoFocus={true}
        />
      </Grid>
      <Grid item xs={12}>
        <InputNumber
          isRequired
          Icon={IconUsers}
          title="capacity"
          value={data.maxUser}
          onChange={handleChange('maxUser')}
          errorMessage={roomTypeError.maxUser}
          placeholder={'capacity'}
          isDisable={isDisable}
        />
      </Grid>
      <Grid item xs={12}>
        <InputPrice
          isRequired
          Icon={IconCoin}
          title="reference-room-rate"
          value={data.referencePrice}
          onChange={handleChange('referencePrice')}
          errorMessage={roomTypeError.referencePrice}
          placeholder="2.500.000VNĐ..."
          isDisable={isDisable}
        />
      </Grid>
    </Grid>
  );
});

export default React.memo(RoomTypeForm);
