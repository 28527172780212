import React, { useEffect, useRef } from 'react';

import { useDisclosure } from 'hooks';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { bookingSelector, resetSelectedId } from 'store/booking';
import {
  getListInvoiceInContractPagingAction,
  getUnpaidInvoiceListAction,
  invoiceSelector,
  resetListInvoiceInContractPaging,
} from 'store/invoice';
import { useDispatch, useSelector } from 'store/Store';
import { isAllow, isSuccessCode } from 'utils/common';

import ConfirmModal from 'components/modals/ConfirmModal';
import { cancelInvoice } from 'services/user/invoice.service';
import CreateIncomeTransactionModal from 'components/modals/CreateIncomeTransactionModal';
import {
  getListOptionPaymentMethodAction,
  getListOptionTransactionTypeAction,
  getListServiceAction,
  propertySelector,
} from 'store/property';
import { CreateTransactionType } from 'types/transaction.type';
import { createTransaction } from 'services/user/transaction.service';
import { getContractFeeListAction, resetListContractFee } from 'store/contractFee';
import InvoiceFeeDetailTable from 'components/table/InvoiceFeeDetailTable';
import { useParams } from 'react-router';
import { PERMISSION } from 'constants/permission';

const InvoiceTabContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { roomId } = useParams();
  const { listService } = useSelector(propertySelector);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenCreateUnpaidInvoiceTransaction,
    onClose: onCloseCreateUnpaidInvoiceTransaction,
    onOpen: onOpenCreateUnpaidInvoiceTransaction,
  } = useDisclosure();

  const { buildingId } = useSelector(propertySelector);
  const { listInvoiceInContractPaging } = useSelector(invoiceSelector);
  const { currentBookingInRoom, selectedBookingId, selectedContractId } =
    useSelector(bookingSelector);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const invoiceIdRef = useRef<string | number>('');

  const INVOICE_TABLE_FIRST_HEADER = [
    'invoice-code',
    'status',
    'payment-period',
    'price',
    'amount-paid',
    'total-refund',
  ];
  const INVOICE_TABLE_SECOND_HEADER = ['expired-date', 'transaction-code'];

  // NOTE: fetch api init
  useEffect(() => {
    if (currentBookingInRoom.currentBooking?.id || selectedBookingId) {
      dispatch(
        getListInvoiceInContractPagingAction({
          bookingId:
            selectedBookingId !== 0 ? selectedBookingId : currentBookingInRoom.currentBooking?.id,
          limit: rowsPerPage,
          page: currentPage,
        }),
      );
    } else {
      dispatch(resetListInvoiceInContractPaging());
      dispatch(resetSelectedId());
    }
  }, [
    dispatch,
    currentBookingInRoom.currentBooking?.id,
    rowsPerPage,
    currentPage,
    selectedBookingId,
  ]);
  useEffect(() => {
    // NOTE: api service fee by contract
    // TODO: change to call api by booking
    if (currentBookingInRoom.currentContract?.id || selectedContractId) {
      dispatch(
        getContractFeeListAction({
          contractId:
            Number(selectedContractId) !== 0
              ? selectedContractId
              : currentBookingInRoom.currentContract?.id,
        }),
      );
    } else {
      dispatch(resetListContractFee());
    }
  }, [currentBookingInRoom.currentContract, dispatch, buildingId, selectedContractId]);

  useEffect(() => {
    dispatch(
      getListServiceAction({
        buildingId: buildingId,
      }),
    );
  }, [dispatch, buildingId]);

  const handleOpenConfirmModal = (invoiceId: string | number) => {
    invoiceIdRef.current = invoiceId;
    onOpen();
  };
  const handleCancelInvoice = async () => {
    const response = await cancelInvoice({
      invoiceId: invoiceIdRef.current,
    });
    const { code } = response.data;
    if (isSuccessCode(code)) {
      dispatch(
        getListInvoiceInContractPagingAction({
          bookingId: currentBookingInRoom.currentBooking?.id,
          limit: rowsPerPage,
          page: currentPage,
        }),
      );
      onClose();
    }
  };

  // NOTE: create unpaid invoice transaction
  const handleOpenIncomeModal = (invoiceId: string | number) => {
    invoiceIdRef.current = invoiceId;
    dispatch(getListOptionTransactionTypeAction({}));
    dispatch(
      getListOptionPaymentMethodAction({
        buildingId,
      }),
    );

    dispatch(
      getUnpaidInvoiceListAction({
        buildingId,
      }),
    );
    onOpenCreateUnpaidInvoiceTransaction();
  };

  const handleCreateTransaction = async (payload: CreateTransactionType) => {
    onClose();
    const response = await createTransaction({
      transactionAmount: payload.transactionAmount,
      isRefund: payload.isRefund,
      paymentMethodId: payload.paymentMethodId,
      paidUserId: payload.userId,
      invoiceId: payload.invoiceId,
    });
    const { code } = response.data;
    if (isSuccessCode(code)) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  return (
    <>
      <ConfirmModal isOpen={isOpen} onClose={onClose} onSubmit={handleCancelInvoice} />
      <CreateIncomeTransactionModal
        isOpen={isOpenCreateUnpaidInvoiceTransaction}
        onClose={onCloseCreateUnpaidInvoiceTransaction}
        onSubmit={handleCreateTransaction}
        isPayInvoiceInRoom={true}
        invoiceIdInRoom={String(invoiceIdRef.current)}
        roomId={roomId}
      />
      <InvoiceFeeDetailTable
        services={listService}
        invoices={listInvoiceInContractPaging.data}
        firstHeaders={INVOICE_TABLE_FIRST_HEADER.map((cell) => String(t(cell)))}
        secondHeaders={INVOICE_TABLE_SECOND_HEADER.map((cell) => String(t(cell)))}
        header={INVOICE_TABLE_FIRST_HEADER.concat(INVOICE_TABLE_SECOND_HEADER).map((cell) =>
          String(t(cell)),
        )}
        onOpenIncomeModal={handleOpenIncomeModal}
        onOpenConfirmModal={handleOpenConfirmModal}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
        limit={rowsPerPage}
        currentPage={currentPage}
        total={listInvoiceInContractPaging.total}
        disableButton={selectedBookingId !== currentBookingInRoom.currentBooking?.id}
        fixedAmount={3}
        disableDirect={!isAllow([PERMISSION.INVOICE_DETAIL])}
      />
    </>
  );
};

export default InvoiceTabContainer;
