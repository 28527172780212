import React, { useRef } from 'react';
import { ModalActionType } from 'types/common.type';
import { DistrictRes, ProvinceRes } from 'types/general.type';
import DrawerWrapper from 'components/shared/DrawerWrapper';
import FloorForm from 'components/forms/FloorForm';
import { FloorNameType } from 'types/floor.type';

interface Props extends ModalActionType {
  data: FloorNameType;
  listCityOptions?: ProvinceRes[];
  listDistrictOptions?: DistrictRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
}

const FloorDrawer = ({ data, isOpen, onClose, onChange, onSubmit }: Props) => {
  const submitRef = useRef<{ handleSubmitFloor: () => void }>();

  const handleSubmitFloor = () => {
    submitRef.current?.handleSubmitFloor();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DrawerWrapper
      title={'floor-information'}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitFloor}
    >
      <FloorForm ref={submitRef} data={data} onChange={onChange} onSubmit={onSubmit} />
    </DrawerWrapper>
  );
};

export default React.memo(FloorDrawer);
