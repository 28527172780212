/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';

import { EMPTY_CONTENT } from 'constants/common';
import { MAINTENANCE_TAB_STATUS, TASK_HISTORY_STATUS, TASK_HISTORY_TYPE } from 'enums';
import { useTranslation } from 'react-i18next';
import { GetKanbanTab, TaskHistory } from 'types/maintenance.type';
import { getDateTime } from 'utils/common';

import { Avatar, Box, Chip, Stack, Typography } from '@mui/material';
import { IconArrowNarrowRight, IconCircle } from '@tabler/icons-react';
import { ColorType } from 'types/color.type';

type Props = {
  item?: TaskHistory;
  avatar?: string;
  username?: string;
  date?: string | Date;
  actionType?: string;
  tabList?: GetKanbanTab[];
};
const HistoryTab = ({ avatar, username, date, actionType, tabList, item }: Props) => {
  const { t } = useTranslation();
  const getTabLabel = (tabId: number) => {
    const tabDetail = tabList?.find((item) => item.id === tabId);
    if (tabDetail) {
      return t(String(tabDetail?.name?.toLocaleLowerCase()));
    } else {
      return t('column-deleted');
    }
  };

  const color = useMemo(() => {
    return (tabId: number) => {
      const tabDetail = tabList?.find((item) => item.id === tabId);
      if (tabDetail) {
        if (tabDetail.name) {
          switch (tabDetail.name) {
            case MAINTENANCE_TAB_STATUS.WAIT_FOR_APPROVAL:
              return 'default';
            case MAINTENANCE_TAB_STATUS.APPROVED:
              return 'info';
            case MAINTENANCE_TAB_STATUS.IN_PROGRESS:
              return 'warning';
            case MAINTENANCE_TAB_STATUS.ACCEPTANCE:
              return 'purple';
            case MAINTENANCE_TAB_STATUS.COMPLETED:
              return 'success';
            case MAINTENANCE_TAB_STATUS.REJECTED:
              return 'error';
            default:
              return 'default';
          }
        }
      }

      return undefined;
    };
  }, [tabList]);

  return (
    <Box mb={3}>
      <Stack direction={'row'} gap={2} alignItems="center">
        <Avatar src={avatar} sx={{ width: '33px', height: '33px' }} />
        <Stack direction={'column'}>
          <Stack direction={'row'} gap={2} alignItems="center">
            <Typography variant="h6" fontSize={13}>
              {username ?? t('employee-removed')}
            </Typography>
            <Typography variant="body1">
              {t(TASK_HISTORY_TYPE[actionType as keyof typeof TASK_HISTORY_TYPE] ?? EMPTY_CONTENT)}
            </Typography>
            <Stack direction="row" alignItems={'center'} justifyItems="center" gap={1}>
              <IconCircle size="7" fill="" fillOpacity={'0.1'} strokeOpacity="0.1" />
              {getDateTime(String(date))}
            </Stack>
          </Stack>
          {item?.destinationTabId && item?.sourceTabId && actionType === TASK_HISTORY_STATUS.MOVE && (
            <Stack direction={'row'} mt={1} gap={1} alignItems="center">
              <Chip
                label={getTabLabel(Number(item?.sourceTabId))}
                size="small"
                color={color(Number(item?.sourceTabId)) as ColorType}
              />
              <IconArrowNarrowRight size="20" />
              <Chip
                label={getTabLabel(Number(item?.destinationTabId))}
                color={color(Number(item?.destinationTabId)) as ColorType}
                size="small"
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default HistoryTab;
