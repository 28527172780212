import { forwardRef, useImperativeHandle, useState } from 'react';

import TextInput from 'components/shared/TextInput';
import { JOI } from 'constants/joi';
import { FloorNameType } from 'types/floor.type';
import { createSchema, validateSchema } from 'utils/joi';

import { IconStack2 } from '@tabler/icons-react';

interface Props {
  data: FloorNameType;
  onChange: (key: string, value: string) => void;
  onSubmit?: () => void;
  isDisable?: boolean;
}

const initError = {
  name: '',
};

const FloorForm = forwardRef(({ data, onChange, onSubmit, isDisable = false }: Props, ref) => {
  const handleChange = (key: string) => (value: string) => {
    onChange(key, value);
  };

  const schema = createSchema({
    name: JOI.REQUIRE,
  });

  const [floorError, setFloorError] = useState<FloorNameType>(initError);

  const handleSubmitFloor = () => {
    const resError = validateSchema(schema, data);
    setFloorError(resError.errorMessage);
    if (!resError.isError) {
      onSubmit && onSubmit();
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmitFloor,
  }));

  return (
    <TextInput
      isRequired
      title="floor-name"
      Icon={IconStack2}
      value={data.name}
      placeholder="placeholder.floor-name"
      onChange={handleChange('name')}
      errorMessage={floorError.name}
      isDisable={isDisable}
      autoFocus={true}
    />
  );
});

export default FloorForm;
