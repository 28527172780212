import Toast from 'components/shared/Toast';
import { useDirection } from 'hooks';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { closeToast } from 'store/common';
import { useDispatch } from 'store/Store';
import { isAllow } from 'utils/common';

type Props = {
  permission: string[];
  children: React.ReactNode;
};

function PermissionPageWrapper({ permission, children }: Props) {
  const dispatch = useDispatch();
  const { goTo } = useDirection();
  const [isShowToast, setIsShowToast] = useState(false);
  const isHavePermission = useMemo(() => {
    return isAllow(permission);
  }, [permission]);

  useEffect(() => {
    if (!isHavePermission) {
      setIsShowToast(true);
    } else {
      setIsShowToast(false);
    }
  }, [goTo, isHavePermission]);

  return isHavePermission ? (
    <>{children}</>
  ) : (
    <>
      <Toast
        status={'error'}
        isOpen={isShowToast}
        code={403}
        onClose={() => dispatch(closeToast())}
        message={String(t('not-permission'))}
      />
    </>
  );
}

export default PermissionPageWrapper;
