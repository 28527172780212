/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { ROUTES } from 'constants/router';
import { setLoading, showToast } from 'store/common';
import { dispatch } from 'store/Store';
import { LocalStorage } from 'utils/localStorage';

const storage = new LocalStorage();

/**NOTE: CENTRAL GATEWAY AXIOS */
export const centralGW = axios.create({
  baseURL: process.env.REACT_APP_CENTRAL_GATEWAY, // Replace with your API base URL
});

// Request interceptor e
centralGW.interceptors.request.use(
  (config) => {
    const { isDisableLoading } = config;
    const accessToken = storage.getStorageItem(storage.availableKey.ACCESS_TOKEN);

    !isDisableLoading && dispatch(setLoading(true));

    if (accessToken) {
      if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    dispatch(setLoading(false));
    throw error;
  },
);

// Response interceptor
centralGW.interceptors.response.use(
  (response) => {
    const { isDisableLoading, isDisableToast } = response.config;

    !isDisableLoading && dispatch(setLoading(false));
    !isDisableToast &&
      dispatch(
        showToast({
          status: 'success',
          ...response.data,
        }),
      );

    return response;
  },
  (error) => {
    const { response } = error;
    dispatch(setLoading(false));
    dispatch(
      showToast({
        status: 'error',
        ...response.data,
      }),
    );

    if (response?.status === 401) {
      storage.clearAllStorage();
      window.location.href = ROUTES.AUTH.LOGIN;
    }

    // if (response?.status === 403) {
    //   window.location.href = ROUTES.ERROR.NOT_FOUND;
    // }

    return response;
  },
);
