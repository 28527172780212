import React, { useRef } from 'react';

import TransactionForm from 'components/forms/TransactionForm';
import DrawerWrapper from 'components/shared/DrawerWrapper';
import { ModalActionType } from 'types/common.type';

type TransactionType = {
  nameVi: string;
  type: string;
  isActive?: boolean;
};

interface Props extends ModalActionType {
  data: TransactionType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
}

const TransactionDrawer = ({ data, isOpen, onClose, onChange, onSubmit }: Props) => {
  const submitRef = useRef<{ handleSubmitTransaction: () => void }>();

  const handleSubmitTransaction = () => {
    submitRef.current?.handleSubmitTransaction();
  };

  const onCloseTransaction = () => {
    onClose && onClose();
  };

  return (
    <DrawerWrapper
      onSubmit={handleSubmitTransaction}
      title={'transaction-type-information'}
      isOpen={isOpen}
      onClose={onCloseTransaction}
    >
      <TransactionForm
        isColumn
        isDisable={false}
        data={data}
        onChange={onChange}
        ref={submitRef}
        onSubmit={onSubmit}
      />
    </DrawerWrapper>
  );
};

export default React.memo(TransactionDrawer);
