import { CommonReq } from 'types/common.type';
import { centralGW } from './axios.service';
import { DashboardType } from 'types/dashboard.type';

export const getRoomPercentageDashboard = (payload: CommonReq) => {
  const { buildingId } = payload;

  return centralGW.get(`/api/v1/dashboard/building/${buildingId}/room/percentage`, {
    isDisableToast: true,
  });
};

export const getRevenueDashboard = (payload: CommonReq & DashboardType) => {
  const { buildingId, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/dashboard/building/${buildingId}/revenue`, {
    params: {
      startDate,
      endDate,
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getRevenueCurrentMonth = (payload: CommonReq & DashboardType) => {
  const { buildingId, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/dashboard/building/${buildingId}/revenue`, {
    params: {
      startDate,
      endDate,
    },
    isDisableToast: true,
  });
};

export const getRevenueCashFlow = (payload: CommonReq & DashboardType) => {
  const { buildingId, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/dashboard/building/${buildingId}/cashflow`, {
    params: {
      startDate,
      endDate,
    },
    isDisableToast: true,
    isDisableLoading: true,
  });
};
