import { PERMISSION } from 'constants/permission';
import ServiceTabContainer from 'containers/management/tab/ServiceTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const ServiceTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_SERVICE_FEE_TYPES]}>
      <ServiceTabContainer />
    </PermissionPageWrapper>
  );
};

export default ServiceTab;
