import { isUndefined } from 'lodash';
import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import {
  CreateInvoiceTemplateReq,
  GetInvoiceTemplateReq,
  GetInvoiceType,
} from 'types/invoice.type';

// TODO: get list invoice by user
export const getListInvoice = (payload: CommonReq & PagingReq) => {
  const { userId, page, limit } = payload;

  return centralGW.get(`/api/v1/user/${userId}/invoice`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const getListInvoiceInContractPaging = (payload: CommonReq & PagingReq) => {
  const { bookingId, page, limit, isDebit, isFull } = payload;

  return centralGW.get(`/api/v1/user/booking/${bookingId}/invoices`, {
    params: {
      page,
      limit,
      ...(isFull ? { isFull } : {}),
      ...(isDebit ? { isDebit } : {}),
    },
    isDisableToast: true,
  });
};
export const getListInvoiceInContract = (payload: CommonReq & PagingReq) => {
  const { bookingId, isDebit, isFull } = payload;

  return centralGW.get(`/api/v1/user/booking/${bookingId}/invoices`, {
    params: {
      ...(isFull ? { isFull } : {}),
      ...(isDebit ? { isDebit } : {}),
    },
    isDisableToast: true,
  });
};

export const getListOptionInvoiceDebitInContract = (payload: CommonReq & PagingReq) => {
  const { contractId, isDebit } = payload;

  return centralGW.get(`/api/v1/user/contract/${contractId}/invoices`, {
    params: {
      isFull: true,
      ...(isDebit ? { isDebit } : {}),
    },
    isDisableToast: true,
    isDisableLoading: true,
  });
};

export const getDepositInvoiceInBuilding = (payload: CommonReq & PagingReq) => {
  const { buildingId, isUnpaid, isFull, isPaid } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/deposit-invoices`, {
    params: {
      ...(!isUndefined(isUnpaid) ? { isUnpaid } : {}),
      ...(!isUndefined(isPaid) ? { isPaid } : {}),
      ...(!isUndefined(isFull) ? { isFull } : {}),
    },
    isDisableToast: true,
    isDisableLoading: true,
  });
};

export const getInvoiceTemporaryInContract = (payload: CommonReq & PagingReq) => {
  const { contractId, page, limit } = payload;

  // NOTE: get data contractId 67
  return centralGW.get(`/api/v1/user/contract/${contractId}/invoice/template`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const getListInvoiceInBuildingPaging = (payload: CommonReq & PagingReq) => {
  const { buildingId, page, limit, floorId, roomId, status, q } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/invoices`, {
    params: {
      page,
      limit,
      ...(q ? { q } : {}),
      ...(roomId ? { roomId } : {}),
      ...(floorId ? { floorId } : {}),
      ...(status ? { status: status } : {}),
    },
    isDisableToast: true,
  });
};

export const getInvoiceDetail = (payload: CommonReq) => {
  const { invoiceId } = payload;

  return centralGW.get(`/api/v1/user/invoice/${invoiceId}/detail`, {
    isDisableToast: true,
  });
};

export const createContractInvoice = (payload: CommonReq & GetInvoiceType) => {
  const { contractId, startAt, expiredAt, contract, contractFees, month, year } = payload;

  return centralGW.post(`/api/v1/user/contract/${contractId}/invoice`, {
    startAt,
    expiredAt,
    contract,
    contractFees,
    month,
    year,
  });
};

export const getOverviewInvoiceInBuilding = (payload: CommonReq) => {
  const { buildingId } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/invoices/overview`, {
    isDisableToast: true,
  });
};

export const createInvoiceTemplateInBuilding = (payload: CommonReq & CreateInvoiceTemplateReq) => {
  const { buildingId, year, month, expiredAt } = payload;

  return centralGW.post(`/api/v1/user/building/${buildingId}/invoices/template/all`, {
    year,
    month,
    expiredAt,
  });
};

export const getInvoiceTemplateInBuilding = (payload: CommonReq & GetInvoiceTemplateReq) => {
  const { buildingId, year, month } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/invoices/template/all`, {
    params: { year, month },
    isDisableToast: true,
  });
};

export const getListInvoiceByClientPaging = (payload: CommonReq & PagingReq) => {
  const { userId, page, limit } = payload;

  return centralGW.get(`/api/v1/user/${userId}/invoices`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const confirmInvoice = (payload: CommonReq & GetInvoiceType) => {
  const { invoiceId, contract, contractFees } = payload;

  return centralGW.put(`/api/v1/user/invoice/${invoiceId}/confirm`, {
    contract,
    contractFees,
  });
};
export const confirmInvoiceTemplate = (payload: CommonReq & GetInvoiceType) => {
  const { invoiceId, contract, contractFees } = payload;

  return centralGW.put(`/api/v1/user/invoice/${invoiceId}/save`, {
    contract,
    contractFees,
  });
};

export const cancelInvoice = (payload: CommonReq) => {
  const { invoiceId } = payload;

  return centralGW.put(`/api/v1/user/invoice/${invoiceId}/cancel`);
};

export const getUnpaidInvoiceList = (payload: CommonReq) => {
  const { buildingId } = payload;

  return centralGW.get(`/api/v1/user/building/${buildingId}/unpaid-invoices`, {
    isDisableToast: true,
  });
};
