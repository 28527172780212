import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteClient, fptRecognizeIdCard } from 'services/client.service';
import { IdCardReq } from 'types/client.type';
import { CommonReq } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

export const fptRecognizeIdCardAction = createAsyncThunk(
  'client/fptRecognizeIdCardAction',
  async (payload: IdCardReq & { callback?: () => void }) => {
    const response = await fptRecognizeIdCard({ image: payload.image, isFront: payload.isFront });
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const deleteClientAction = createAsyncThunk(
  'client/deleteClientAction',
  async (payload: CommonReq) => {
    const response = await deleteClient(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
