import { CustomResponseType } from 'types/common.type';
import { GetListVehicleRes } from 'types/vehicle.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getListVehiclePagingAction } from './actions';

interface VehicleState {
  listVehiclePaging: { data: GetListVehicleRes[]; total: number };
}
const initialState: VehicleState = {
  listVehiclePaging: {
    data: [],
    total: 0,
  },
};
export const vehicleSlice = createSlice({
  name: 'vehicle',
  reducers: {},
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<VehicleState>) => {
    builder.addCase(
      getListVehiclePagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listVehiclePaging = action.payload.data;
        }
      },
    );
  },
});

export const {} = vehicleSlice.actions;
export default vehicleSlice.reducer;
