import React, { forwardRef, useImperativeHandle, useState } from 'react';

import AutoComplete from 'components/shared/AutoComplete';
import SwitchLabel from 'components/shared/SwitchLabel';
import TextInput from 'components/shared/TextInput';
import { JOI } from 'constants/joi';
import { TRANSACTION_TYPE } from 'enums';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils/common';
import { createSchema, validateSchema } from 'utils/joi';

import { Grid } from '@mui/material';
import { IconNotes, IconTransfer } from '@tabler/icons-react';

type TransactionType = {
  nameVi: string;
  type: string;
  isActive?: boolean;
};
interface Props {
  data: TransactionType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
  isDisable?: boolean;
}
const initError = { nameVi: '', type: '' };

const TransactionForm = forwardRef(
  ({ data, onChange, onSubmit, isDisable = false }: Props, ref) => {
    const { t } = useTranslation();
    const [transactionError, setTransactionError] = useState(initError);
    const schema = createSchema({
      nameVi: JOI.REQUIRE,
      type: JOI.REQUIRE,
    });

    const handleChange = (key: string) => (value: string | boolean) => {
      onChange(key, value);
    };

    const handleSubmitTransaction = () => {
      const clone = { ...data };
      delete clone.isActive;
      const resError = validateSchema(schema, clone);
      setTransactionError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit && onSubmit();
      }
    };
    useImperativeHandle(ref, () => ({
      handleSubmitTransaction,
    }));

    return (
      <Grid container spacing="12px">
        <Grid item container spacing="12px" lg={12}>
          <Grid item xs={12} lg={12}>
            <TextInput
              isRequired
              title="transaction-name"
              Icon={IconNotes}
              value={data.nameVi}
              placeholder="placeholder.transaction-name"
              onChange={handleChange('nameVi')}
              errorMessage={transactionError.nameVi}
              isDisable={isDisable}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <AutoComplete
              isRequired
              title={'transaction-type'}
              options={Object.keys(TRANSACTION_TYPE).map((transaction) => ({
                label: t(showData(String(transaction)).toLocaleLowerCase()),
                value: transaction,
              }))}
              value={data.type}
              placeHolder="placeholder.choose-transaction"
              Icon={IconTransfer}
              onChange={handleChange('type')}
              errorMessage={transactionError.type}
              isDisable={isDisable}
            />
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <SwitchLabel
            title="active"
            isChecked={Boolean(data.isActive)}
            onChange={handleChange('isActive')}
            isDisable={isDisable}
          />
        </Grid>
      </Grid>
    );
  },
);

export default React.memo(TransactionForm);
