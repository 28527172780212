import { CurrentBooking, GetListBookingRes } from 'types/booking.type';
import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getCurrentBookingInRoomAction,
  getListBookingInBuildingOptionAction,
  getListBookingInBuildingPagingAction,
  getListBookingInRoomPagingAction,
  getListBookingPagingAction,
} from './actions';

interface BookingState {
  listBookingPaging: { data: GetListBookingRes[]; total: number };
  listBookingInBuildingPaging: { data: GetListBookingRes[]; total: number };
  listBookingInBuilding: GetListBookingRes[];
  listBookingInRoomPaging: { data: GetListBookingRes[]; total: number };
  currentBookingInRoom: CurrentBooking;
  selectedContractId: number;
  selectedBookingId: number;
}
const initialState: BookingState = {
  listBookingPaging: {
    data: [],
    total: 0,
  },
  listBookingInBuilding: [],
  listBookingInBuildingPaging: {
    data: [],
    total: 0,
  },
  listBookingInRoomPaging: {
    data: [],
    total: 0,
  },
  currentBookingInRoom: {},
  selectedContractId: 0,
  selectedBookingId: 0,
};
export const bookingSlice = createSlice({
  name: 'booking',
  reducers: {
    resetCurrentBookingInRoom: (state) => {
      state.currentBookingInRoom = initialState.currentBookingInRoom;
    },
    setSelectedBookingId: (state, actions) => {
      state.selectedBookingId = actions.payload.bookingId;
    },
    setSelectedContractId: (state, actions) => {
      state.selectedContractId = actions.payload.contractId;
    },
    resetSelectedId: (state) => {
      state.selectedContractId = initialState.selectedContractId;
      state.selectedBookingId = initialState.selectedBookingId;
    },
  },
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<BookingState>) => {
    builder.addCase(
      getListBookingPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listBookingPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListBookingInBuildingPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listBookingInBuildingPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListBookingInRoomPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listBookingInRoomPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getCurrentBookingInRoomAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.currentBookingInRoom = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListBookingInBuildingOptionAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listBookingInBuilding = action.payload.data;
        }
      },
    );
  },
});

export const {
  resetCurrentBookingInRoom,
  setSelectedBookingId,
  setSelectedContractId,
  resetSelectedId,
} = bookingSlice.actions;
export default bookingSlice.reducer;
