import React, { useRef } from 'react';

import ServiceForm from 'components/forms/ServiceForm';
import DrawerWrapper from 'components/shared/DrawerWrapper';
import { ModalActionType } from 'types/common.type';
import { UnitTypeRes } from 'types/general.type';
import { ServiceType } from 'types/service.type';

interface Props extends ModalActionType {
  data: ServiceType;
  listUnitTypeOptions?: UnitTypeRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
  isDisable?: boolean;
}

const ServiceDrawer = ({
  data,
  isOpen,
  listUnitTypeOptions = [],
  onClose,
  onChange,
  onSubmit,
  isColumn,
  isDisable,
}: Props) => {
  const submitRef = useRef<{ handleSubmitService: () => void }>();

  const handleSubmitService = () => {
    submitRef.current?.handleSubmitService();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DrawerWrapper
      title={'service-information'}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitService}
      isDisable={isDisable}
    >
      <ServiceForm
        data={data}
        listUnitTypeOptions={listUnitTypeOptions}
        onChange={onChange}
        ref={submitRef}
        isColumn={isColumn}
        onSubmit={onSubmit}
        isDisable={isDisable}
      />
    </DrawerWrapper>
  );
};

export default React.memo(ServiceDrawer);
