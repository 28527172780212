import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { GetListVehicleRes, VehicleReq } from 'types/vehicle.type';

export const getListVehicle = (payload: CommonReq & PagingReq) => {
  const { userId, page, limit } = payload;

  return centralGW.get(`/api/v1/user/${userId}/vehicles`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const createVehicle = async (payload: CommonReq & GetListVehicleRes) => {
  const { userId, buildingId, isActive, vehicleTypeId, licensePlate, name } = payload;

  return centralGW.post(`/api/v1/user/${userId}/building/${buildingId}/vehicle`, {
    isActive,
    vehicleTypeId,
    licensePlate,
    name,
  });
};

export const deleteVehicle = (payload: CommonReq & VehicleReq) => {
  const { vehicleId } = payload;

  return centralGW.delete(`/api/v1/user/vehicle/${vehicleId}`, {});
};

export const updateVehicle = (payload: CommonReq & GetListVehicleRes) => {
  const { id: vehicleId, isActive, name, licensePlate, vehicleTypeId } = payload;

  return centralGW.put(`/api/v1/user/vehicle/${vehicleId}`, {
    isActive,
    name,
    licensePlate,
    vehicleTypeId,
  });
};
