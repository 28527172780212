import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { getListServiceFeeUnitAction } from '../actions';
import { GeneralState } from '../reducer';

export const serviceFeeUnitReducer = {
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GeneralState>) => {
    builder.addCase(
      getListServiceFeeUnitAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listServiceFeeUnit = action.payload.data;
        }
      },
    );
  },
};
