import React, { useEffect, useMemo, useState } from 'react';

import TransactionChip from 'components/shared/TransactionChip';
import TableNormal from 'components/table/TableNormal';
import { DATE_TIME_FORMAT, IMAGE_URL } from 'constants/common';
import { TRANSACTION_STATUS } from 'enums';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { propertySelector } from 'store/property';
import { getListOtherTransactionAction } from 'store/property/actions/otherTransaction.action';
import { useDispatch, useSelector } from 'store/Store';
import { formatDate, formatPrice, showData } from 'utils/common';

import { Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { IconEye } from '@tabler/icons-react';
import ImagePopup from 'components/shared/ImagePopup';
import { useDisclosure } from 'hooks';

const TRANSACTION_TABLE_HEADER = [
  'transaction-code',
  'status',
  'collect',
  'expenses',
  'payment-method',
  'note',
  'image',
  'creation-date',
  'creator',
];

const OtherTransactionContainerTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { listOtherTransactionPaging } = useSelector(propertySelector);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { buildingId } = useSelector(propertySelector);
  const [image, setOpenImage] = useState<string[]>();
  const {
    isOpen: isOpenImage,
    onClose: onCloseImage,
    onOpen: onOpenDeleteTabConfirm,
  } = useDisclosure();

  useEffect(() => {
    dispatch(
      getListOtherTransactionAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, buildingId, rowsPerPage, currentPage]);
  const handleOpenPopup = (images: string[]) => () => {
    setOpenImage(images);
    onOpenDeleteTabConfirm();
  };

  const transactionTableRows = useMemo(() => {
    return listOtherTransactionPaging.data.map((transaction) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link href="#" underline="none">
            <Typography variant="subtitle1">{showData(transaction.id)}</Typography>
          </Link>
        </Stack>,
        <TransactionChip
          key={uniqueId()}
          status={transaction.isIncome ? TRANSACTION_STATUS.INCOME : TRANSACTION_STATUS.EXPENSE}
        />,
        showData(transaction.isIncome ? formatPrice(String(transaction.transactionAmount)) : null),
        showData(!transaction.isIncome ? formatPrice(String(transaction.transactionAmount)) : null),
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {showData(transaction.paymentMethod?.name)}
        </Typography>,
        transaction.note,
        <Tooltip title={t('view-image')} key={uniqueId()}>
          <IconButton
            color="info"
            disabled={transaction?.otherTransactionImages?.length === 0}
            onClick={handleOpenPopup(
              transaction?.otherTransactionImages?.map((img) =>
                String(`${IMAGE_URL}?id=${img?.imageUrl}`),
              ) ?? [''],
            )}
          >
            <IconEye size={21} />
          </IconButton>
        </Tooltip>,
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.createdBy?.fullName)}</Typography>
        </Link>,
      ];
    });
  }, [listOtherTransactionPaging.data]);

  return (
    <Box>
      <TableNormal
        header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
        data={transactionTableRows}
        total={listOtherTransactionPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
      <ImagePopup isOpen={isOpenImage} onClose={onCloseImage} images={image} />
    </Box>
  );
};
export default OtherTransactionContainerTab;
