/* eslint-disable @typescript-eslint/no-unused-vars */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { TablerIconsProps } from '@tabler/icons-react';
import { useDirection } from 'hooks';
import { useState, ComponentType, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router';
import _ from 'lodash';
import BlankCard from './BlankCard';
import { isAllow, isMobile } from 'utils/common';

interface Props {
  tabList: {
    value: string;
    Icon: ComponentType<TablerIconsProps>;
    label: string;
    path: string;
    disabled?: boolean;
    permission?: string[];
  }[];
}

function TabWrapper({ tabList }: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const location = useLocation();
  const filterTabList = tabList.filter((tab) => isAllow([String(tab.permission)]));
  const defaultTabValue = filterTabList.length > 0 ? filterTabList[0].value : '';
  const [tab, setTab] = useState<string>(defaultTabValue);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    const keyMap = _.mapKeys(filterTabList, 'value');
    goTo(keyMap[newValue].path)();
    setTab(newValue);
  };

  useEffect(() => {
    const path = location.pathname.split('/');
    const keyMap = _.mapKeys(filterTabList, 'value');
    const route = keyMap[path[path.length - 1] as keyof typeof keyMap];
    if (route) {
      setTab(route.value);
    } else {
      setTab(String(defaultTabValue));
    }
  }, [location.pathname, filterTabList, defaultTabValue]);

  useEffect(() => {
    const defaultTab = filterTabList.find((tab) => isAllow([String(tab.permission)]));
    if (defaultTab) {
      setTab(defaultTab.value);
      goTo(defaultTab.path)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlankCard>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} borderRadius={0}>
          <TabList variant={isMobile ? 'scrollable' : 'fullWidth'} onChange={handleChange}>
            {filterTabList.map((tab) => {
              return (
                <Tab
                  key={tab.value}
                  icon={<tab.Icon width={20} height={20} />}
                  label={isMobile ? '' : t(tab.label)}
                  value={tab.value}
                  disabled={tab.disabled}
                />
              );
            })}
          </TabList>
        </Box>
        {filterTabList.map((tab, idx) => {
          return (
            <TabPanel key={idx} value={tab.value} sx={{ padding: isMobile ? '10px' : '24px' }}>
              <Outlet />
            </TabPanel>
          );
        })}
      </TabContext>
    </BlankCard>
  );
}

export default memo(TabWrapper);
