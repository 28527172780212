import React from 'react';

import ContractTabContainer from 'containers/client/tab/ContractTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import { PERMISSION } from 'constants/permission';

const ContractTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_CONTRACTS_WITH_USER]}>
      <ContractTabContainer />
    </PermissionPageWrapper>
  );
};

export default ContractTab;
