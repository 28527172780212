import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fptRecognizeIdCardAction } from './actions';
import { IdCardType } from 'types/client.type';
import { CustomResponseType } from 'types/common.type';

interface State {
  code: number;
  clientIdCardData: IdCardType;
}

const initialState: State = {
  code: -1,
  clientIdCardData: {},
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fptRecognizeIdCardAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        state.clientIdCardData = action.payload.data;
        state.code = action.payload.code || -1;
      },
    );
  },
});

export const {} = clientSlice.actions;
export default clientSlice.reducer;
