import { getListOtherTransaction } from 'services/property/otherTransaction.service';
import { CommonReq, PagingReq } from 'types/common.type';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListOtherTransactionAction = createAsyncThunk(
  'property/getListOtherTransactionAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListOtherTransaction(payload);

    return response.data;
  },
);
