import React, {
  useEffect,
  useMemo,
} from 'react';

import TableNormal from 'components/table/TableNormal';
import {
  BOOKING_STATUS,
  BOOKING_TYPE,
} from 'enums';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  bookingSelector,
  getListBookingPagingAction,
} from 'store/booking';
import {
  useDispatch,
  useSelector,
} from 'store/Store';
import {
  formatDate,
  showData,
} from 'utils/common';

import {
  Chip,
  Link,
  Stack,
  Typography,
} from '@mui/material';

const BOOKING_TABLE_HEADER = [
  'booking-code',
  'status',
  'room',
  'room-type',
  'floor',
  'check-in-date',
  'check-out-date',
];

const BookingTabContainer = () => {
  const { t } = useTranslation();
  const { listBookingPaging } = useSelector(bookingSelector);
  const dispatch = useDispatch();
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();

  const { clientId } = useParams();
  useEffect(() => {
    if (clientId) {
      dispatch(
        getListBookingPagingAction({
          page: currentPage,
          limit: rowsPerPage,
          userId: Number(clientId),
        }),
      );
    }
  }, [dispatch, clientId, currentPage, rowsPerPage]);

  const bookingTableRows = useMemo(() => {
    return listBookingPaging.data.map((booking) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link href="#" underline="none">
            <Typography variant="subtitle1">{showData(booking.id)}</Typography>
          </Link>
        </Stack>,
        <Chip
          key="chip"
          size="small"
          label={t(
            booking.status !== undefined && booking.status === BOOKING_STATUS.STAYING
              ? BOOKING_TYPE.STAYING
              : booking.status === BOOKING_STATUS.WAITING_FOR_CHECKIN
              ? BOOKING_TYPE.WAITING_FOR_CHECKIN
              : booking.status === BOOKING_STATUS.WAITING_FOR_DEPOSIT
              ? BOOKING_TYPE.WAITING_FOR_DEPOSIT
              : booking.status === BOOKING_STATUS.FINISHED
              ? BOOKING_TYPE.FINISHED
              : BOOKING_TYPE.CANCELED,
          )}
          color={
            booking.status === BOOKING_STATUS.STAYING
              ? 'success'
              : booking.status === BOOKING_STATUS.WAITING_FOR_CHECKIN
              ? 'info'
              : booking.status === BOOKING_STATUS.WAITING_FOR_DEPOSIT
              ? 'default'
              : booking.status === BOOKING_STATUS.FINISHED
              ? 'warning'
              : 'error'
          }
        />,
        booking.infoRoom?.room?.name,
        booking.infoRoom?.roomType?.name,
        booking.infoRoom?.floor?.name,
        formatDate(booking.checkInAt),
        formatDate(booking.checkOutAt),
      ];
    });
  }, [listBookingPaging.data, t]);

  return (
    <TableNormal
      header={BOOKING_TABLE_HEADER.map((cell) => t(cell))}
      data={bookingTableRows}
      total={listBookingPaging?.total}
      limit={rowsPerPage}
      currentPage={currentPage}
      onChangePage={handleChangeCurrentPage}
      onChangeLimit={handleChangeLimit}
    />
  );
};

export default BookingTabContainer;
