import HistoryTab from 'components/shared/HistoryTab';
import React, { useEffect } from 'react';
import avatar from 'assets/images/profile/user-1.jpg';
import { useDispatch, useSelector } from 'store/Store';
import { getKanbanTaskHistoryAction, kanbanSelector } from 'store/kanban';
import { Box } from '@mui/material';

const HistoryTabContainer = () => {
  const dispatch = useDispatch();
  const { taskHistory, kanbanTabs, taskId } = useSelector(kanbanSelector);

  useEffect(() => {
    if (taskId) {
      dispatch(
        getKanbanTaskHistoryAction({
          taskId: taskId,
        }),
      );
    }
  }, [dispatch, taskId]);

  return (
    <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
      {taskHistory.map((history) => (
        <HistoryTab
          key={history.id}
          item={history}
          avatar={avatar}
          actionType={history.actionType}
          date={history.createdAt}
          username={history.assignedEmployee?.accountDetail?.fullName}
          tabList={kanbanTabs}
        />
      ))}
    </Box>
  );
};

export default HistoryTabContainer;
