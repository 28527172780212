import React, {
  useEffect,
  useMemo,
} from 'react';

import InvoiceChip from 'components/shared/InvoiceChip';
import DropdownTable from 'components/table/DropdownTable';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  getListInvoiceByClientPagingAction,
  invoiceSelector,
} from 'store/invoice';
import {
  useDispatch,
  useSelector,
} from 'store/Store';
import {
  formatDate,
  formatPrice,
  showData,
} from 'utils/common';

import {
  Link,
  Typography,
} from '@mui/material';

const INVOICE_TABLE_HEADER = [
  'invoice-code',
  'status',
  'payment-period',
  'price',
  'amount-paid',
  'expired-date',
  'reservation-clerk',

  'transaction-code',
];

const InvoiceTabContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { listInvoiceByClientPaging } = useSelector(invoiceSelector);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();

  useEffect(() => {
    dispatch(
      getListInvoiceByClientPagingAction({
        userId: Number(clientId),
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [clientId, dispatch, currentPage, rowsPerPage]);

  const invoiceTableRows = useMemo(() => {
    return listInvoiceByClientPaging.data.map((invoice) => {
      return {
        row: [
          <Link key={uniqueId()} href="#" underline="none">
            {showData(invoice.id)}
          </Link>,
          <InvoiceChip key={uniqueId()} status={invoice.status} />,
          <Typography key={uniqueId()}>{invoice.contractPeriod?.name}</Typography>,
          <Typography key={uniqueId()}>
            {showData(invoice.totalPrice ? formatPrice(invoice.totalPrice) : null)}
          </Typography>,
          <Typography key={uniqueId()}>
            {formatPrice(Number(invoice.transactionAmount))}
          </Typography>,
          <Typography key={uniqueId()}>{formatDate(invoice.expiredAt)}</Typography>,
          <Link key={uniqueId()} href="#" underline="none">
            {showData(invoice.createdBy?.fullName)}
          </Link>,
          <Link key={uniqueId()} href="#" underline="none">
            {showData(invoice.contractFeeTransactions?.map((ele) => ele?.id).join(', '))}
          </Link>,
        ],
        children: {
          header: ['service-code', 'service-name', 'price'],
          row: invoice.contractPeriodFees?.map((service) => {
            return [
              <Link key={uniqueId()} href="#" underline="none">
                {showData(service.id)}
              </Link>,
              <Typography
                key={uniqueId()}
                color="textSecondary"
                variant="subtitle1"
                fontWeight="400"
              >
                {showData(service.name)}
              </Typography>,
              <Typography
                key={uniqueId()}
                color="textSecondary"
                variant="subtitle1"
                fontWeight="400"
              >
                {showData(service.totalPrice ? formatPrice(service.totalPrice) : null)}
              </Typography>,
            ];
          }),
        },
      };
    });
  }, [listInvoiceByClientPaging.data]);

  return (
    <DropdownTable
      header={INVOICE_TABLE_HEADER.map((cell) => t(cell))}
      data={invoiceTableRows}
      total={listInvoiceByClientPaging?.total}
      limit={rowsPerPage}
      currentPage={currentPage}
      onChangePage={handleChangeCurrentPage}
      onChangeLimit={handleChangeLimit}
    />
  );
};

export default InvoiceTabContainer;
