import { centralGW } from 'services/axios.service';
import { ListServiceFeeUnitReq } from 'types/general.type';

export const getListServiceFeeUnit = async (payload: ListServiceFeeUnitReq) => {
  const { isMeter } = payload;

  return centralGW.get('/api/v1/general/service-fee/unit', {
    params: {
      isMeter,
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};
