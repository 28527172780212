import { useMemo } from 'react';
import { isAllow } from 'utils/common';

type Props = {
  permission: string[];
  children: React.ReactNode;
};

function PermissionRender({ permission, children }: Props) {
  const isHavePermission = useMemo(() => {
    return isAllow(permission);
  }, [permission]);

  return isHavePermission ? <>{children}</> : <></>;
}

export default PermissionRender;
