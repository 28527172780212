import { TRANSACTION_STATUS } from 'enums';
import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { TransactionType, UpdateTransactionType } from 'types/property.type';
import { getBranchId } from 'utils/common';

export const getListTransactionType = async (payload: CommonReq & PagingReq) => {
  const { page, limit } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/transaction-type`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const getListOptionTransactionType = async (payload: { actionType?: TRANSACTION_STATUS }) => {
  const { actionType } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/transaction-type`, {
    params: {
      isFull: true,
      actionType
    },
    isDisableToast: true,
  });
};

export const createTransactionType = async (payload: TransactionType) => {
  const { isActive, nameVi, nameEn, actionType } = payload;
  const branchId = getBranchId();

  return await centralGW.post(`/api/v1/property/branch/${branchId}/transaction-type`, {
    isActive,
    nameVi,
    nameEn,
    actionType,
  });
};
export const deleteTransactionType = async (payload: CommonReq) => {
  const { transactionTypeId } = payload;
  const branchId = getBranchId();

  return await centralGW.delete(
    `/api/v1/property/branch/${branchId}/transaction-type/${transactionTypeId}`,
  );
};

export const updateTransactionType = async (payload: CommonReq & UpdateTransactionType) => {
  const { transactionTypeId, isActive, nameVi, actionType } = payload;
  const branchId = getBranchId();

  return centralGW.put(
    `/api/v1/property/branch/${branchId}/transaction-type/${transactionTypeId}`,
    {
      isActive,
      nameVi,
      actionType,
    },
  );
};
