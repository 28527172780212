import { getRoomDetail } from 'services/property';
import { CommonReq } from 'types/common.type';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getRoomDetailAction = createAsyncThunk(
  'room/getRoomDetailAction',
  async (payload: CommonReq) => {
    const response = await getRoomDetail(payload);

    return response.data;
  },
);
