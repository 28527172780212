import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { PropertyState } from '../reducer';
import { getListServiceAction, updateServiceAction } from '../actions';
import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';
import _ from 'lodash';
import { GetListServiceRes } from 'types/property.type';

export const serviceFeeReducer = {
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PropertyState>) => {
    builder.addCase(
      getListServiceAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listService = action.payload.data;
        }
      },
    );
    builder.addCase(
      updateServiceAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          const dataRes = action.payload.data as GetListServiceRes;
          const hashList = _.mapKeys(state.listService, 'id');
          state.listService = state.listService.map((service) => {
            return service.id == dataRes.id
              ? dataRes
              : hashList[service.id as keyof typeof hashList];
          });
        }
      },
    );
  },
};
