/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import Logo from 'assets/images/products/room.jpg';
import TextOverflow from 'components/shared/TextOverflow';
import { ROOM_STATUS } from 'enums';
import { useTranslation } from 'react-i18next';
import { formatPrice, isMobile, showData, truncateString } from 'utils/common';

import { Box, Button, Card, Chip, Tooltip, Typography, useTheme } from '@mui/material';
import { IconCoin, IconDoor, IconFrame, IconTrash, IconUsers } from '@tabler/icons-react';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

type Props = {
  name?: string;
  capacity?: number;
  area?: number;
  price?: number;
  status?: string;
  typeName?: string;
  isActive?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  isBooking?: boolean;
  isPaid?: boolean;
  isStaying?: boolean;
  isMaintenance?: boolean;
};

function RoomCardConfig({
  name,
  capacity,
  area,
  price,
  status,
  typeName,
  isActive,
  isBooking,
  isStaying,
  isMaintenance,
  onClick,
  onDelete,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleDeleteRoom = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <Card
      sx={{
        p: '12px',
        cursor: 'pointer',
        maxWidth: '100%',
        height: '180px',
        boxShadow: theme.shadows[5],
        '&:hover': {
          boxShadow: theme.shadows[0],
        },
        position: 'relative',
        opacity: isActive ? 1 : 0.6,
        bgcolor: isActive ? 'white' : 'var(--gray-04)',
      }}
      onClick={onClick}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="12px">
        <TextOverflow name={String(name)} variant="subtitle1" />
        <Chip
          label={t(
            isStaying || isBooking
              ? ROOM_STATUS.USING
              : isMaintenance
              ? ROOM_STATUS.MAINTENANCE
              : ROOM_STATUS.EMPTY,
          )}
          color={isStaying || isBooking ? 'info' : isMaintenance ? 'warning' : 'success'}
          size="small"
        />
      </Box>
      <Box display="flex" alignItems="center" gap="12px">
        <Box
          width="120px"
          maxHeight="120px"
          height="100%"
          borderRadius="6px"
          overflow="hidden"
          boxShadow={theme.shadows[5]}
        >
          <img
            alt=""
            width="100%"
            height="100px"
            src={Logo}
            style={{ objectFit: 'cover', height: '100%' }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="6px"
          width={`calc(100% - ${isMobile ? 20 : 162}px)`}
        >
          <Box display="flex" alignItems="flex-start">
            <IconUsers width="24px" color="var(--gray-01)" />
            <Typography
              textAlign="left"
              variant="subtitle1"
              ml="6px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              width="calc(100% - 30px)"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {showData(capacity)} {t('people')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <IconFrame width="24px" color="var(--gray-01)" />
            <Typography
              textAlign="left"
              variant="subtitle1"
              ml="6px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              width="calc(100% - 30px)"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {showData(area)} m&sup2;
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <IconCoin width="24px" color="var(--gray-01)" />
            <Typography
              textAlign="left"
              variant="subtitle1"
              ml="6px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              width="calc(100% - 30px)"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {showData(price ? formatPrice(price) : null)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <IconDoor width="24px" color="var(--gray-01)" />
            <Typography
              textAlign="left"
              variant="subtitle1"
              ml="6px"
              width="calc(100% - 30px)"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {showData(truncateString(typeName, 10))}
            </Typography>
          </Box>
        </Box>
      </Box>
      <PermissionRender permission={[PERMISSION.DELETE_ROOM]}>
        <Tooltip
          placement="top"
          title={t('delete-room')}
          sx={{
            position: 'absolute',
            bottom: '12px',
            right: '12px',
          }}
        >
          <Button variant="outlined" color="error" onClick={handleDeleteRoom}>
            <IconTrash />
          </Button>
        </Tooltip>
      </PermissionRender>
    </Card>
  );
}

export default React.memo(RoomCardConfig);
