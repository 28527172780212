import {
  createTab,
  createTaskComment,
  createTaskMaintenance,
  deleteTab,
  deleteTaskItem,
  getKanbanList,
  getKanbanTabs,
  getKanbanTaskComment,
  getKanbanTaskDetail,
  getKanbanTaskHistory,
  getKanbanTasks,
  getLabelTaskList,
  moveTab,
  moveTaskItem,
  updateTab,
  updateTaskMaintenance,
  uploadFileComment,
} from 'services/kanban/maintenance.service';
import { CommonReq } from 'types/common.type';
import { MaintenanceReq, MoveTab, MoveTask, TaskComment } from 'types/maintenance.type';
import { isAllow, isSuccessCode } from 'utils/common';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { PERMISSION } from 'constants/permission';

export const getKanbanListAction = createAsyncThunk(
  'kanban/getKanbanListAction',
  async (payload: CommonReq) => {
    if (isAllow([PERMISSION.SB_MAINTENANCE])) {
      const response = await getKanbanList(payload);

      return response.data;
    }
  },
);
export const getLabelTaskListAction = createAsyncThunk(
  'kanban/getLabelTaskListAction',
  async () => {
    if (isAllow([PERMISSION.SB_MAINTENANCE])) {
      const response = await getLabelTaskList();

      return response.data;
    }
  },
);

// NOTE: maintenance tab
export const getKanbanTabsAction = createAsyncThunk(
  'kanban/getKanbanTabsAction',
  async (payload: CommonReq) => {
    if (isAllow([PERMISSION.SB_MAINTENANCE])) {
      const response = await getKanbanTabs(payload);

      return response.data;
    }
  },
);
export const createTabAction = createAsyncThunk(
  'kanban/createTabAction',
  async (payload: CommonReq & MaintenanceReq) => {
    const response = await createTab(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
export const deleteTabAction = createAsyncThunk(
  'kanban/deleteTabAction',
  async (payload: CommonReq) => {
    const response = await deleteTab(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
export const updateTabAction = createAsyncThunk(
  'kanban/updateTabAction',
  async (payload: MaintenanceReq & CommonReq) => {
    const response = await updateTab(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
export const moveTabAction = createAsyncThunk(
  'kanban/moveTabAction',
  async (payload: CommonReq & MoveTab) => {
    const response = await moveTab(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const getKanbanTasksAction = createAsyncThunk(
  'kanban/getKanbanTasksAction',
  async (payload: CommonReq) => {
    if (isAllow([PERMISSION.SB_MAINTENANCE])) {
      const response = await getKanbanTasks(payload);

      return response.data;
    }
  },
);
export const getKanbanTaskHistoryAction = createAsyncThunk(
  'kanban/getKanbanTaskHistoryAction',
  async (payload: MoveTask) => {
    const response = await getKanbanTaskHistory(payload);

    return response.data;
  },
);
export const getKanbanTaskCommentAction = createAsyncThunk(
  'kanban/getKanbanTaskCommentAction',
  async (payload: MoveTask) => {
    const response = await getKanbanTaskComment(payload);

    return response.data;
  },
);
export const getKanbanTaskDetailAction = createAsyncThunk(
  'kanban/getKanbanTaskDetailAction',
  async (payload: MoveTask) => {
    const response = await getKanbanTaskDetail(payload);

    return response.data;
  },
);

export const createTaskMaintenanceAction = createAsyncThunk(
  'kanban/createTaskMaintenanceAction',
  async (payload: CommonReq & MaintenanceReq) => {
    const response = await createTaskMaintenance(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const moveTaskAction = createAsyncThunk(
  'kanban/moveTaskAction',
  async (payload: CommonReq & MoveTask) => {
    const response = await moveTaskItem(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const deleteTaskAction = createAsyncThunk(
  'kanban/deleteTaskAction',
  async (payload: CommonReq & MoveTask) => {
    const response = await deleteTaskItem(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const updateTaskMaintenanceAction = createAsyncThunk(
  'kanban/updateTaskMaintenanceAction',
  async (payload: CommonReq & MoveTask & MaintenanceReq) => {
    const response = await updateTaskMaintenance(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const createTaskCommentAction = createAsyncThunk(
  'kanban/createTaskCommentAction',
  async (payload: TaskComment & CommonReq) => {
    const response = await createTaskComment(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const uploadFileCommentAction = createAsyncThunk(
  'kanban/uploadFileCommentAction',
  async (payload: { image: string | Blob; isDisableToast?: boolean; callback?: () => void }) => {
    const response = await uploadFileComment({
      image: payload.image,
      isDisableToast: Boolean(payload.isDisableToast),
    });
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
