import { getListServiceFeeUnit } from 'services/general';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListServiceFeeUnitAction = createAsyncThunk(
  'general/getListServiceFeeUnitAction',
  async () => {
    const response = await getListServiceFeeUnit({ isMeter: null });

    return response.data;
  },
);
