import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDistrictInProvince, getListCountry, getListProvinceInCountry } from 'services/general';
import { ListDistrictReq, ListProvinceReq } from 'types/general.type';

export const getListCountryAction = createAsyncThunk('general/getListCountryAction', async () => {
  const response = await getListCountry();

  return response.data;
});

export const getListProvinceAction = createAsyncThunk(
  'general/getListProvinceAction',
  async (payload: ListProvinceReq) => {
    const response = await getListProvinceInCountry(payload);

    return response.data;
  },
);

export const getListDistrictAction = createAsyncThunk(
  'general/getListDistrictAction',
  async (payload: ListDistrictReq) => {
    const response = await getDistrictInProvince(payload);

    return response.data;
  },
);
