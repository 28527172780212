import _, { isEmpty } from 'lodash';
import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import {
  createBuildingInformationAction,
  createFloorInBuildingAction,
  deleteFloorInBuildingAction,
  getBuildingActiveInBranchAction,
  getBuildingDetailAction,
  getBuildingInBranchAction,
  getFloorInBuildingAction,
  getListOptionPaymentMethodAction,
  getListPaymentMethodPagingAction,
  updateBuildingInformationAction,
} from '../actions';
import { PropertyState } from '../reducer';
import { LocalStorage } from 'utils/localStorage';
import { STORAGE_KEY } from 'enums/localStorage';

const Storage = new LocalStorage()

export const buildingReducer = {
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PropertyState>) => {
    builder.addCase(
      getBuildingInBranchAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.buildingsInBranch = action.payload.data;
        }
      },
    );
    builder.addCase(
      getBuildingActiveInBranchAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.buildingsActiveInBranch = action.payload.data;
          const storedBuildingId = Storage.getStorageItem(STORAGE_KEY.BUILDING_ID);
          const keyIds = Object.keys(_.mapKeys(action.payload.data, "id"));
          if (isEmpty(storedBuildingId) || !keyIds?.includes(storedBuildingId)) {
            state.buildingId = action.payload.data[0].id;
            Storage.setStorageItem(STORAGE_KEY.BUILDING_ID, action.payload.data[0].id)
          } else if (keyIds?.includes(storedBuildingId)) {
            state.buildingId = storedBuildingId;
          }
        }
      },
    );
    builder.addCase(
      getBuildingDetailAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.buildingDetail = action.payload.data;
        }
      },
    );
    builder.addCase(
      updateBuildingInformationAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.buildingDetail = action.payload.data;
        }
      },
    );
    builder.addCase(
      createBuildingInformationAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.buildingsInBranch = state.buildingsInBranch.concat([action.payload.data]);
        }
      },
    );
    builder.addCase(
      createFloorInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          const cloneListRoom = _.cloneDeep(state.listRoom);
          cloneListRoom.floors = cloneListRoom.floors?.concat([
            { ...action.payload.data, rooms: [] },
          ]);
          state.listRoom = cloneListRoom;
        }
      },
    );

    builder.addCase(
      deleteFloorInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          const cloneListRoom = _.cloneDeep(state.listRoom);
          cloneListRoom.floors = cloneListRoom.floors?.filter(
            (floor) => floor.id !== action.payload.data?.floorId,
          );
          state.listRoom = cloneListRoom;
        }
      },
    );

    builder.addCase(
      getFloorInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.floorsInBuilding = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListPaymentMethodPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listPaymentMethodPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListOptionPaymentMethodAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listPaymentMethod = action.payload.data;
        }
      },
    );
  },
};
