import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { getListOtherTransactionAction } from '../actions/otherTransaction.action';
import { PropertyState } from '../reducer';

export const otherTransactionReducer = {
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PropertyState>) => {
    builder.addCase(
      getListOtherTransactionAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listOtherTransactionPaging = action.payload.data;
        }
      },
    );
  },
};
