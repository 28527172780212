import * as React from 'react';

import CustomFormLabel from 'components/theme-elements/CustomFormLabel';
import CustomSwitch from 'components/theme-elements/CustomSwitch';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

type Props = {
  title?: string;
  isChecked?: boolean;
  onChange?: (checked: boolean) => void;
  isDisable?: boolean;
};

function SwitchLabel({ title = '', isChecked, onChange, isDisable = false }: Props) {
  const { t } = useTranslation();

  const handleCheck = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange && onChange(checked);
  };

  return (
    <Box width="100%">
      <Box display="flex" alignItems="center">
        <CustomSwitch
          checked={Boolean(isChecked)}
          onChange={handleCheck}
          sx={{
            margin: 0,
            position: 'relative',
          }}
          disabled={isDisable}
        />
        <CustomFormLabel marginLeft="6px" sx={{ mt: '0px', mb: '0px' }}>
          {t(title)}
        </CustomFormLabel>
      </Box>
    </Box>
  );
}

export default React.memo(SwitchLabel);
