/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ComponentType, useState } from 'react';
import CustomFormLabel from 'components/theme-elements/CustomFormLabel';
import CustomOutlinedInput from 'components/theme-elements/CustomOutlinedInput';
import { useTranslation } from 'react-i18next';
import { formatNumber, formatPrice } from 'utils/common';
import { Box, InputAdornment, SxProps } from '@mui/material';
import { TablerIconsProps } from '@tabler/icons-react';
import { Theme } from '@emotion/react';
import { isUndefined } from 'lodash';

type Props = {
  isRequired?: boolean;
  isDisable?: boolean;
  Icon?: ComponentType<TablerIconsProps>;
  onChange?: (newValue: string) => void;
  errorMessage?: string;
  value?: string;
  title?: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  maxValue?: number;
  autoFocus?: boolean;
};

const InputPrice = ({
  onChange,
  errorMessage = '',
  value = '',
  title = '',
  placeholder = '',
  isRequired = false,
  isDisable = false,
  Icon,
  sx,
  maxValue,
  autoFocus = false,
}: Props) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectionStart = event.target.selectionStart;
    const selectionEnd = Number(event.target.selectionEnd);
    const newValue =
      !isUndefined(maxValue) && formatNumber(event.target.value) > Number(maxValue)
        ? formatPrice(Number(maxValue))
        : event.target.value;
    onChange && onChange(newValue);

    if (selectionEnd !== newValue.length) {
      setTimeout(() => {
        event.target.setSelectionRange(selectionStart, selectionEnd);
      }, 10);
    }
  };

  return (
    <Box width="100%">
      {title && (
        <CustomFormLabel className={`${isRequired ? 'required' : ''}`} sx={{ mt: '0px' }}>
          {t(title)}
        </CustomFormLabel>
      )}
      <CustomOutlinedInput
        type="text"
        value={value ? formatPrice(value) : ''}
        onChange={handleChange}
        size="medium"
        sx={{
          padding: '5px',
          ...sx,
        }}
        startAdornment={
          Icon && (
            <InputAdornment position="start" sx={{ paddingLeft: '10px', marginRight: '10px' }}>
              {Icon && <Icon size="20px" />}
            </InputAdornment>
          )
        }
        placeholder={t(placeholder)}
        fullWidth
        inputProps={{
          minLength: 4,
          maxLength: 14,
        }}
        error={Boolean(errorMessage)}
        errorMessage={t(`validate-message.${errorMessage}`)}
        isDisable={isDisable}
        autoFocus={autoFocus}
      />
    </Box>
  );
};

export default React.memo(InputPrice);
