import React, { useEffect, useMemo } from 'react';

import TableNormal from 'components/table/TableNormal';
import CustomSwitch from 'components/theme-elements/CustomSwitch';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { updatePaymentMethod } from 'services/property';
import { getListPaymentMethodPagingAction, propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { isAllow, isSuccessCode, showData } from 'utils/common';

import { Box } from '@mui/material';
import { PERMISSION } from 'constants/permission';

const PaymentMethodTabContainer = () => {
  const { t } = useTranslation();
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { buildingId } = useParams();
  const { listPaymentMethodPaging } = useSelector(propertySelector);
  const dispatch = useDispatch();

  const handleToggleActive =
    (methodId: number) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const response = await updatePaymentMethod({
        isActive: checked,
        buildingId,
        paymentMethodId: methodId,
      });

      const { code } = response.data;
      if (isSuccessCode(code)) {
        dispatch(
          getListPaymentMethodPagingAction({
            buildingId: buildingId,
            page: currentPage,
            limit: rowsPerPage,
          }),
        );
      }
    };

  const dataRow = useMemo(() => {
    return listPaymentMethodPaging?.data?.map((method) => {
      return [
        showData(method.name),
        <CustomSwitch
          key={uniqueId()}
          checked={method?.isActive}
          onChange={handleToggleActive(method?.id)}
          disabled={!isAllow([PERMISSION.UPDATE_PAYMENT_METHOD])}
        />,
      ];
    });
  }, [listPaymentMethodPaging?.data]);

  useEffect(() => {
    dispatch(
      getListPaymentMethodPagingAction({
        buildingId: buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [buildingId, currentPage, dispatch, rowsPerPage]);

  return (
    <Box textAlign="right">
      <TableNormal
        header={[t('payment-method'), t('active')]}
        data={dataRow}
        total={listPaymentMethodPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
    </Box>
  );
};

export default PaymentMethodTabContainer;
