import React from 'react';

import { CONTRACT_STATUS } from 'enums';
import { useTranslation } from 'react-i18next';

import { Chip } from '@mui/material';

type Props = {
  isExpired?: boolean;
};
const ContractChip = ({ isExpired }: Props) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t((isExpired ? CONTRACT_STATUS.INVALIDATED : CONTRACT_STATUS.VALIDITY).toLowerCase())}
      color={isExpired ? 'default' : 'success'}
      size="small"
    />
  );
};

export default React.memo(ContractChip);
