import TheHomeeImage from 'assets/images/logos/thehomee.png';

export const ROW_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];

export const DEFAULT_LIMIT = ROW_PER_PAGE_OPTIONS[2];

export const DATE_FORMAT = 'DD-MM-YYYY';

export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm:ss';

export const PERIOD_FORMAT = 'MM-YYYY';

export const PAY_DUE_DATE = 5;

export const EMPTY_CONTENT = '-';

export const DEFAULT_DEBOUNCE = 500;

export const PAYOS_ID = 1;

export const MAX_UPLOAD_LIMIT = 5;

export const MAX_FILE_SIZE = 500 * 1024;

export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export const BRANCH_NAME = 'The Homee Apartment';

export const THE_HOMEE_LOGO = TheHomeeImage;

export const WHITE_SPACE = '\u00A0';

export const APP_NAME = 'MORA';
