import { Box, Button, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import Scrollbar from 'components/custom-scroll/Scrollbar';

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { isMobile, showData } from 'utils/common';

interface Props {
  title?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  children?: React.ReactNode;
  isDisable?: boolean;
}

function DrawerWrapper({
  title = '',
  isOpen,
  children,
  onClose,
  onSubmit,
  isDisable = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      variant={'temporary'}
      onClose={onClose}
      sx={{
        position: 'relative',
      }}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            md: '50%',
            lg: '30%',
          },
        },
      }}
    >
      <Box p={2} display="flex" justifyContent={'space-between'} alignItems="center">
        <Typography variant="h4">{showData(t(title))}</Typography>
        <IconButton color="inherit" onClick={onClose}>
          <IconX size="1rem" />
        </IconButton>
      </Box>
      <Divider />

      <Box
        component={isMobile ? Box : Scrollbar}
        p="12px"
        height="calc(100vh - 150px)"
        overflow="auto"
      >
        {children}
      </Box>

      <Divider />
      {!isDisable && (
        <Box
          p={3}
          width="100%"
          position="absolute"
          display="flex"
          gap="12px"
          bottom="0"
          sx={{
            justifyContent: {
              xs: 'center',
              md: 'flex-start',
            },
          }}
        >
          <Button variant="contained" onClick={onSubmit} autoFocus disabled={false}>
            {t('save')}
          </Button>
          <Button variant="contained" color="error" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Box>
      )}
    </Drawer>
  );
}

export default memo(DrawerWrapper);
