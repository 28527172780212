import { PERMISSION } from 'constants/permission';
import OtherTransactionContainerTab from 'containers/report/tab/OtherTransactionContainerTab';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const OtherTransactionTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_OTHER_TRANSACTION]}>
      <OtherTransactionContainerTab />
    </PermissionPageWrapper>
  );
};

export default OtherTransactionTab;
