import { PERMISSION } from 'constants/permission';
import PaymentMethodTabContainer from 'containers/management/tab/PaymentMethodTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const PaymentMethodTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_PAYMENT_METHOD]}>
      <PaymentMethodTabContainer />;
    </PermissionPageWrapper>
  );
};

export default PaymentMethodTab;
