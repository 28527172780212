import React, { useEffect, useMemo, useState } from 'react';

import AddActionCard from 'components/card/AddActionCard';
import ServiceCard from 'components/card/ServiceCard';
import ServiceDrawer from 'components/drawers/ServiceDrawer';
import ConfirmModal from 'components/modals/ConfirmModal';
import ServiceModal from 'components/modals/ServiceModal';
import { useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { createService, deleteService, updateService } from 'services/property';
import { generalSelector, getListServiceFeeUnitAction } from 'store/general';
import { getListServiceAction, propertySelector, updateServiceAction } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { CustomResponseType } from 'types/common.type';
import { UnitTypeRes } from 'types/general.type';
import { compareObject, formatNumber, isAllow, isSuccessCode } from 'utils/common';

import { Grid } from '@mui/material';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

const initFormService = {
  name: '',
  isMeter: 'metered',
  unitId: '',
  isActive: true,
  isDefault: true,
  referencePrice: '',
  isPaidStartOfMonth: 0,
};

const ServiceTabContainer = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenService, onOpen: onOpenService, onClose: onCloseService } = useDisclosure();
  const {
    isOpen: isOpenServiceConfirm,
    onOpen: onOpenServiceConfirm,
    onClose: onCloseServiceConfirm,
  } = useDisclosure();
  const dispatch = useDispatch();
  const { buildingId } = useParams();
  const { listServiceFeeUnit } = useSelector(generalSelector);
  const { listService } = useSelector(propertySelector);
  const [service, setService] = useState(initFormService);
  const [selectedId, setSelectedId] = useState<number>();
  const [serviceDetail, setServiceDetail] = useState(initFormService);
  const [listUnitService, setListUnitService] = useState<UnitTypeRes[]>();
  const [listUnitEdit, setListUnitEdit] = useState<UnitTypeRes[]>();

  const handleChangeService = (key: string, value: string | boolean) => {
    if (key === 'isMeter') {
      setService((prev) => ({
        ...prev,
        isMeter: String(value),
        unitId: '',
      }));
    } else if (key === 'unitId' && value === '6') {
      setService((prev) => ({
        ...prev,
        isPaidStartOfMonth: 0,
      }));
    }
    setService((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleChangeEditService = (key: string, value: string | boolean) => {
    if (key === 'isMeter') {
      setServiceDetail((prev) => ({
        ...prev,
        isMeter: String(value),
        unitId: '',
      }));
    } else if (key === 'unitId' && value === '6') {
      setServiceDetail((prev) => ({
        ...prev,
        isPaidStartOfMonth: 0,
      }));
    }
    setServiceDetail((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCreateService = async () => {
    const response = await createService({
      buildingId,
      name: service.name,
      isActive: service.isActive,
      referencePrice: formatNumber(service.referencePrice),
      unitId: service.unitId,
      isDefault: service.isDefault,
      isMeter: service.isMeter === 'metered' ? true : false,
      isPaidStartOfMonth: Number(service.isPaidStartOfMonth),
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getListServiceAction({
          buildingId: buildingId,
        }),
      );
      setService(initFormService);
      onClose();
    }
  };

  const handleOpenServiceEdit = (serviceId: number | undefined) => () => {
    const serviceEdit = listService?.find((service) => service.id === serviceId);
    setSelectedId(serviceId);
    onOpenService();
    setServiceDetail({
      name: serviceEdit?.name as string,
      isActive: serviceEdit?.isActive as boolean,
      isDefault: serviceEdit?.isDefault as boolean,
      referencePrice: String(serviceEdit?.referencePrice),
      isMeter: serviceEdit?.isMeter ? 'metered' : 'unit',
      unitId: serviceEdit?.unitId as string,
      isPaidStartOfMonth: Number(serviceEdit?.isPaidStartOfMonth),
    });
  };

  const handleSubmitService = async () => {
    const selectedService = listService.find((service) => service.id === selectedId);
    let serviceFilter = {};
    if (selectedService) {
      const { name, isActive, referencePrice, unitId, isDefault, isMeter, isPaidStartOfMonth } =
        selectedService;
      serviceFilter = {
        name: name,
        isActive: isActive,
        referencePrice: referencePrice,
        isMeter: Number(isMeter) === 0 ? 'unit' : 'metered',
        isDefault: isDefault,
        unitId: unitId,
        isPaidStartOfMonth: isPaidStartOfMonth,
      };
    }
    if (!compareObject(serviceFilter, serviceDetail)) {
      const response = await updateService({
        buildingId,
        serviceId: selectedId,
        name: serviceDetail.name,
        isActive: serviceDetail.isActive,
        referencePrice: formatNumber(serviceDetail.referencePrice),
        unitId: serviceDetail.unitId,
        isDefault: serviceDetail.isDefault,
        isMeter: serviceDetail.isMeter === 'metered' ? true : false,
        isPaidStartOfMonth: serviceDetail.isPaidStartOfMonth,
      });
      const dataRes = response.data as CustomResponseType;
      if (isSuccessCode(dataRes.code)) {
        dispatch(
          getListServiceAction({
            buildingId: buildingId,
          }),
        );
        onCloseService();
      }
    }
  };
  const handleOpenConfirmServiceModal = (serviceId: number) => () => {
    setSelectedId(serviceId);
    onOpenServiceConfirm();
  };
  const handleDeleteService = async () => {
    const response = await deleteService({
      buildingId,
      serviceId: selectedId,
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getListServiceAction({
          buildingId: buildingId,
        }),
      );
      onCloseServiceConfirm();
    }
  };
  const getServiceFeeUnitName = (unitId: string | undefined) => {
    const unitById = listServiceFeeUnit.find((unit) => String(unit.id) === unitId);

    return unitById?.nameVi;
  };
  const handleChangeActiveService = (serviceId: number) => (isDefault?: boolean) => {
    dispatch(
      updateServiceAction({
        buildingId,
        serviceId,
        isDefault,
      }),
    );
  };

  useEffect(() => {
    const listMeterFilterServiceEdit = listServiceFeeUnit.filter(
      (unit) => unit.isMeter === Number(serviceDetail.isMeter === 'metered' ? 1 : 0),
    );
    const listMeterFilterService = listServiceFeeUnit.filter(
      (unit) => unit.isMeter === Number(service.isMeter === 'metered' ? 1 : 0),
    );
    setListUnitEdit(listMeterFilterServiceEdit);
    setListUnitService(listMeterFilterService);
  }, [listServiceFeeUnit, service.isMeter, serviceDetail.isMeter]);

  const processedServiceList = useMemo(() => {
    return listService?.map((service) => {
      return {
        id: service.id ?? -1,
        name: service?.name,
        price: Number(service?.referencePrice),
        unit: getServiceFeeUnitName(service?.unitId),
        isMeter: service?.isMeter,
        isDefault: service?.isDefault,
        isActive: service?.isActive,
      };
    });
  }, [listService]);

  useEffect(() => {
    dispatch(
      getListServiceAction({
        buildingId: buildingId,
      }),
    );
    dispatch(getListServiceFeeUnitAction());
  }, [buildingId, dispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {processedServiceList?.map((ele, idx) => {
          return (
            <Grid key={idx} item xs={12} md={6} lg={4}>
              <ServiceCard
                name={ele.name}
                price={ele.price}
                unit={ele.unit}
                isDefault={ele.isDefault}
                isMeter={ele.isMeter}
                isChecked={ele.isActive}
                onSwitchDefault={handleChangeActiveService(ele.id)}
                onClick={handleOpenServiceEdit(ele.id)}
                onDelete={handleOpenConfirmServiceModal(ele.id)}
              />
            </Grid>
          );
        })}
        <PermissionRender permission={[PERMISSION.CREATE_SERVICE_FEE_TYPE]}>
          <Grid item xs={12} md={6} lg={4}>
            <AddActionCard height="165px" label={t('create-service') || ''} onClick={onOpen} />
          </Grid>
        </PermissionRender>
      </Grid>
      <ServiceModal
        data={service}
        onChange={handleChangeService}
        onClose={onClose}
        isOpen={isOpen}
        listUnitTypeOptions={listUnitService}
        onSubmit={handleCreateService}
      />
      <ServiceDrawer
        data={serviceDetail}
        onChange={handleChangeEditService}
        onClose={onCloseService}
        isOpen={isOpenService}
        onSubmit={handleSubmitService}
        listUnitTypeOptions={listUnitEdit}
        isColumn={true}
        isDisable={!isAllow([PERMISSION.UPDATE_SERVICE_FEE_TYPE])}
      />
      <ConfirmModal
        isOpen={isOpenServiceConfirm}
        onClose={onCloseServiceConfirm}
        onSubmit={handleDeleteService}
      />
    </React.Fragment>
  );
};

export default ServiceTabContainer;
