export enum STATUS_CODE {
  SUCCESS = 200,
}

export enum ROOM_STATUS {
  EMPTY = 'empty',
  USING = 'using',
  MAINTENANCE = 'maintenance',
  INACTIVE = 'inactive',
}

export enum ROOM_STATUS_ACTIVE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  MAINTENANCE = 'maintenance',
}
export enum ROOM_PAID_MONTH {
  PREV = 1,
  LAST = 0,
}

export enum ROOM_DETAIL_TAB {
  INVOICE = 'invoice',
  TRANSACTION = 'transaction',
  SERVICE = 'service',
}

export enum BUILDING_DETAIL_TAB {
  ROOM_FLOOR = 'room-floor',
  ROOM_TYPE = 'room-type',
  SERVICE = 'service',
  TRANSACTION_TYPE = 'transaction-type',
  PAYMENT_METHOD = 'payment-method',
}

export enum TRANSACTION_TAB {
  INVOICE_TRANSACTION = 'room-transaction',
  DEPOSIT_TRANSACTION = 'deposit-transaction',
  OTHER_TRANSACTION = 'other-transaction',
  DEBT_TRANSACTION = 'debt-transaction',
}

export enum TRANSACTION_STATUS {
  INCOME = 'INCOME',
  EXPENSE = 'EXPENSE',
}
export enum DEPOSIT_TRANSACTION_STATUS {
  DEPOSIT = 'DEPOSIT',
  REFUND = 'REFUND',
  CONVERT_TO_INVOICE_REVENUE = 'CONVERT_TO_INVOICE_REVENUE',
  CONVERT_TO_OTHER_REVENUE = 'CONVERT_TO_OTHER_REVENUE',
}
export enum DEPOSIT_TRANSACTION_TYPE {
  DEPOSIT = 'deposit',
  REFUND = 'deposit-refund',
  CONVERT_TO_INVOICE_REVENUE = 'convert-to-invoice-revenue',
  CONVERT_TO_OTHER_REVENUE = 'convert-to-other-revenue',
}
export enum TRANSACTION_TYPE {
  INCOME = 'income',
  EXPENSE = 'expense',
}

export enum USER_DETAIL_TAB {
  BOOKING = 'booking',
  CONTRACT = 'contract',
  TRANSACTION = 'transaction',
  INVOICE = 'invoice',
  VEHICLE = 'vehicle',
}
export enum MAINTENANCE_TAB {
  COMMENT = 'comment',
  HISTORY = 'history',
}
export enum TASK_HISTORY_TYPE {
  CREATE = 'created-task',
  UPDATE_INFO = 'updated-info',
  MOVE = 'change-tab',
  DELETE = 'deleted-comment',
}
export enum TASK_HISTORY_STATUS {
  CREATE = 'CREATE',
  UPDATE_INFO = 'UPDATE_INFO',
  MOVE = 'MOVE',
  DELETE = 'DELETE',
}
export enum MAINTENANCE_TAB_STATUS {
  TODO = 'TODO',
  DONE = 'DONE',
  REVIEW = 'REVIEW',

  IN_PROGRESS = 'IN_PROGRESS',
  WAIT_FOR_APPROVAL = 'WAIT_FOR_APPROVAL',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  ACCEPTANCE = 'ACCEPTANCE',
}
export enum BOOKING_TYPE {
  WAITING_FOR_CHECKIN = 'waiting-for-check-in',
  STAYING = 'staying',
  WAITING_FOR_DEPOSIT = 'waiting-for-deposit',
  FINISHED = 'finished',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
}
export enum BOOKING_STATUS {
  WAITING_FOR_CHECKIN = 'WAITING_FOR_CHECKIN',
  STAYING = 'STAYING',
  WAITING_FOR_DEPOSIT = 'WAITING_FOR_DEPOSIT',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  PARTIAL = 'PARTIAL',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  PAID = 'PAID',
}

export enum INVOICE_STATUS {
  WAITING_FOR_CONFIRM = 'WAITING_FOR_CONFIRM',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  PAID = 'PAID',
  PARTIAL = 'PARTIAL',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  UNPAID = 'UNPAID',
}
export enum INVOICE_TYPE {
  WAITING_FOR_CONFIRM = 'waiting-for-confirm',
  WAITING_FOR_PAYMENT = 'waiting-for-payment',
  PAID = 'paid',
  PARTIAL = 'partial-paid',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  UNPAID = 'unable-pay',
}

export enum CONTRACT_STATUS {
  VALIDITY = 'VALIDITY',
  INVALIDATED = 'INVALIDATED',
}
export enum CONTRACT_FEE_STATUS {
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  PAID = 'PAID',
  PARTIAL = 'PARTIAL',
  CANCELED = 'CANCELED',
  WAITING_FOR_CONFIRM = 'WAITING_FOR_CONFIRM',
}

export enum PAYMENT_TYPE {
  DEPOSIT = 'DEPOSIT',
  INVOICE = 'INVOICE',
  OTHER = 'OTHER',
}

export enum DEPOSIT_STATUS {
  REFUNDED = 'REFUNDED',
  PAID = 'PAID',
}

export enum REPORT_TAB {
  APPROACHING_EXPIRED_REPORT = 'approaching-expired-report',
  CHECKIN_REPORT = 'checkin-report',
  TRANSACTION_REPORT = 'transaction-report',
}
