import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getRevenueCashFlowAction,
  getRevenueCurrentMonthAction,
  getRevenueDashboardAction,
  getRoomPercentageDashboardAction,
} from './actions';
import { DashboardType, RevenueCashFlowType, RevenueType } from 'types/dashboard.type';

interface DashboardState {
  roomPercentageDashboard: DashboardType;
  revenueDashboard: RevenueType;
  revenueCurrentMonth: RevenueType;
  revenueCashFlow: RevenueCashFlowType;
}
const initialState: DashboardState = {
  roomPercentageDashboard: {},
  revenueDashboard: {},
  revenueCurrentMonth: {},
  revenueCashFlow: {},
};
export const dashboardSlice = createSlice({
  name: 'dashboard',
  reducers: {},
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<DashboardState>) => {
    builder.addCase(
      getRoomPercentageDashboardAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.roomPercentageDashboard = action.payload.data;
        }
      },
    );
    builder.addCase(
      getRevenueDashboardAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.revenueDashboard = action.payload.data;
        }
      },
    );
    builder.addCase(
      getRevenueCurrentMonthAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.revenueCurrentMonth = action.payload.data;
        }
      },
    );
    builder.addCase(
      getRevenueCashFlowAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.revenueCashFlow = action.payload.data;
        }
      },
    );
  },
});

export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;
