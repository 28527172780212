import React, { useEffect, useRef, useState } from 'react';

import Logo from 'assets/images/products/service-fee.jpg';
import CustomSwitch from 'components/theme-elements/CustomSwitch';
import { useTranslation } from 'react-i18next';
import { formatPrice, isAllow, isMobile, showData } from 'utils/common';

import { Box, Button, Card, Tooltip, Typography, useTheme } from '@mui/material';
import { IconClock, IconCoin, IconSquareForbid2, IconTrash } from '@tabler/icons-react';
import { PERMISSION } from 'constants/permission';
import PermissionRender from 'layouts/permission/PermissionRender';

type Props = {
  name?: string;
  price?: number;
  unit?: string;
  isMeter?: boolean;
  isDefault?: boolean;
  isChecked?: boolean;
  onSwitchDefault?: (checked: boolean) => void;
  onClick?: () => void;
  onDelete?: () => void;
};

function ServiceCard({
  name,
  price,
  unit,
  isMeter,
  isDefault,
  isChecked,
  onSwitchDefault,
  onClick,
  onDelete,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const textElementRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const compareSize = () => {
    const element = textElementRef.current;
    const compare = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;

    setIsOverflow(compare);
  };

  useEffect(() => {
    compareSize();
  }, []);

  const handleCheckedDefault = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onSwitchDefault?.(checked);
    event.stopPropagation();
  };

  const handleClickSwitchDefault = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    onClick && onClick();
  };

  const handleOnDelete = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <Card
      sx={{
        p: '12px',
        position: 'relative',
        cursor: 'pointer',
        maxWidth: '100%',
        height: '165px',
        boxShadow: theme.shadows[5],
        '&:hover': {
          boxShadow: theme.shadows[0],
        },
        opacity: isChecked ? 1 : 0.6,
        bgcolor: isChecked ? 'white' : 'var(--gray-04)',
      }}
      onClick={handleOnClick}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="12px">
        <Tooltip placement="top" title={showData(name)} disableHoverListener={!isOverflow}>
          <Typography
            ref={textElementRef}
            variant="h5"
            ml="6px"
            whiteSpace="nowrap"
            width="calc(100% - 80px)"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {showData(name)}
          </Typography>
        </Tooltip>
        <PermissionRender permission={[PERMISSION.DELETE_SERVICE_FEE_TYPE]}>
          <Tooltip
            placement="top"
            title={t('delete-service')}
            sx={{ position: 'absolute', top: '12px', right: '12px' }}
          >
            <Button variant="outlined" color="error" onClick={handleOnDelete}>
              <IconTrash />
            </Button>
          </Tooltip>
        </PermissionRender>
      </Box>
      <Box display="flex" gap="12px">
        <Box
          width="150px"
          maxHeight="100px"
          height="100%"
          borderRadius="6px"
          overflow="hidden"
          boxShadow={theme.shadows[5]}
        >
          <img
            alt=""
            width="100%"
            height="100px"
            src={Logo}
            style={{ objectFit: 'contain', height: '100%' }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="6px"
          width={`calc(100% - ${isMobile ? 20 : 162}px)`}
        >
          <Box display="flex" alignItems="flex-start">
            <IconCoin width="24px" color="var(--gray-01)" />
            <Typography
              variant="subtitle1"
              ml="6px"
              width="calc(100% - 30px)"
              textOverflow="ellipsis"
              overflow="hidden"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {showData(price ? formatPrice(price) : null)}/{showData(unit)}
            </Typography>
          </Box>
          {isMeter ? (
            <Box display="flex" alignItems="flex-start">
              <IconClock width="24px" color="var(--gray-01)" />
              <Typography
                variant="subtitle1"
                ml="6px"
                whiteSpace="nowrap"
                width="calc(100% - 30px)"
                textOverflow="ellipsis"
                overflow="hidden"
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                  },
                }}
              >
                {t('calculated-by-meter')}
              </Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="flex-start">
              <IconSquareForbid2 width="24px" color="var(--gray-01)" />
              <Typography
                variant="subtitle1"
                ml="6px"
                whiteSpace="nowrap"
                width="calc(100% - 30px)"
                textOverflow="ellipsis"
                overflow="hidden"
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                  },
                }}
              >
                {t('calculated-by-unit')}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <CustomSwitch
              checked={Boolean(isDefault)}
              onChange={handleCheckedDefault}
              onClick={handleClickSwitchDefault}
              disabled={!isAllow([PERMISSION.UPDATE_SERVICE_FEE_TYPE])}
            />

            <Typography
              variant="h6"
              ml="6px"
              width="calc(100% - 30px)"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {t('default-in-contract')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default React.memo(ServiceCard);
