import React from 'react';

import BookingTabContainer from 'containers/client/tab/BookingTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import { PERMISSION } from 'constants/permission';

const BookingTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_BOOKINGS_WITH_USER]}>
      <BookingTabContainer />
    </PermissionPageWrapper>
  );
};

export default BookingTab;
