import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getListApproachingRoomExpiredDateAction,
  getListTransactionReportAction,
  getListWaitingCheckInReportAction,
} from './actions';
import {
  ApproachingExpiredDateType,
  TransactionReportType,
  WaitingCheckInReport,
} from 'types/report.type';

interface ReportState {
  listApproachingRoomExpiredDate: { data: ApproachingExpiredDateType[]; total: number };
  listWaitingCheckInReport: { data: WaitingCheckInReport[]; total: number };
  listTransactionReport: { data: TransactionReportType[]; total: number };
}
const initialState: ReportState = {
  listApproachingRoomExpiredDate: {
    data: [],
    total: 0,
  },
  listWaitingCheckInReport: {
    data: [],
    total: 0,
  },
  listTransactionReport: {
    data: [],
    total: 0,
  },
};
export const reportSlice = createSlice({
  name: 'report',
  reducers: {},
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<ReportState>) => {
    builder.addCase(
      getListApproachingRoomExpiredDateAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listApproachingRoomExpiredDate = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListWaitingCheckInReportAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listWaitingCheckInReport = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListTransactionReportAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listTransactionReport = action.payload.data;
        }
      },
    );
  },
});

export const {} = reportSlice.actions;
export default reportSlice.reducer;
