import {
  cancelInvoice,
  confirmInvoice,
  confirmInvoiceTemplate,
  getDepositInvoiceInBuilding,
  getInvoiceDetail,
  getInvoiceTemporaryInContract,
  getListInvoiceByClientPaging,
  getListInvoiceInBuildingPaging,
  getListInvoiceInContract,
  getListInvoiceInContractPaging,
  getOverviewInvoiceInBuilding,
  getUnpaidInvoiceList,
} from 'services/user/invoice.service';
import { getDepositTransactionInBooking } from 'services/user/transaction.service';
import { CommonReq, PagingReq } from 'types/common.type';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { isSuccessCode } from 'utils/common';
import { GetInvoiceType } from 'types/invoice.type';

export const getListInvoiceInContractPagingAction = createAsyncThunk(
  'invoice/getListInvoiceInContractPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListInvoiceInContractPaging(payload);

    return response.data;
  },
);
export const getListInvoiceInContractAction = createAsyncThunk(
  'invoice/getListInvoiceInContractAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListInvoiceInContract(payload);

    return response.data;
  },
);
export const getListInvoiceInBuildingPagingAction = createAsyncThunk(
  'invoice/getListInvoiceInBuildingPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListInvoiceInBuildingPaging(payload);

    return response.data;
  },
);

export const getInvoiceTemporaryInContractAction = createAsyncThunk(
  'invoice/getInvoiceTemporaryInContractAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getInvoiceTemporaryInContract(payload);

    return response.data;
  },
);

export const getInvoiceDetailAction = createAsyncThunk(
  'invoice/getInvoiceDetailAction',
  async (payload: CommonReq) => {
    const response = await getInvoiceDetail(payload);

    return response.data;
  },
);
export const getOverviewInvoiceInBuildingAction = createAsyncThunk(
  'invoice/getOverviewInvoiceInBuildingAction',
  async (payload: CommonReq) => {
    const response = await getOverviewInvoiceInBuilding(payload);

    return response.data;
  },
);
export const getDepositTransactionInBookingAction = createAsyncThunk(
  'invoice/getDepositTransactionInBookingAction',
  async (payload: CommonReq) => {
    const response = await getDepositTransactionInBooking(payload);

    return response.data;
  },
);

export const getListInvoiceByClientPagingAction = createAsyncThunk(
  'invoice/getListInvoiceByClientPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListInvoiceByClientPaging(payload);

    return response.data;
  },
);

export const getDepositInvoiceInBuildingAction = createAsyncThunk(
  'invoice/getDepositInvoiceInBuildingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getDepositInvoiceInBuilding(payload);

    return response.data;
  },
);

export const getUnpaidInvoiceListAction = createAsyncThunk(
  'invoice/getUnpaidInvoiceListAction',
  async (payload: CommonReq) => {
    const response = await getUnpaidInvoiceList(payload);

    return response.data;
  },
);

export const cancelInvoiceAction = createAsyncThunk(
  'invoice/cancelInvoiceAction',
  async (payload: CommonReq) => {
    const response = await cancelInvoice(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const confirmInvoiceAction = createAsyncThunk(
  'invoice/confirmInvoiceAction',
  async (payload: CommonReq & GetInvoiceType) => {
    const response = await confirmInvoice(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const saveTemplateInvoiceAction = createAsyncThunk(
  'invoice/confirmInvoiceAction',
  async (payload: CommonReq & GetInvoiceType) => {
    const response = await confirmInvoiceTemplate(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
