import { PERMISSION } from 'constants/permission';
import CommentTabContainer from 'containers/maintenance/tab/CommentTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const CommentTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.GET_COMMENT_TAB]}>
      <CommentTabContainer />
    </PermissionPageWrapper>
  );
};

export default CommentTab;
