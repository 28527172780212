import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { PropertyState } from '../reducer';
import { getListOptionTransactionTypeAction, getTransactionTypePagingAction, updateTransactionTypeAction } from '../actions';
import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';
import { GetListTransactionTypeRes } from 'types/property.type';
import _ from 'lodash';
import { TRANSACTION_STATUS } from 'enums';

export const transactionTypeReducer = {
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PropertyState>) => {
    builder.addCase(
      getTransactionTypePagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listTransactionTypePaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListOptionTransactionTypeAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listTransactionTypeIncome = action.payload.data?.filter((transaction: GetListTransactionTypeRes) => transaction.type === TRANSACTION_STATUS.INCOME);
          state.listTransactionTypeExpense = action.payload.data?.filter((transaction: GetListTransactionTypeRes) => transaction.type === TRANSACTION_STATUS.EXPENSE);
        }
      },
    );
    builder.addCase(
      updateTransactionTypeAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          const dataRes = action.payload.data as GetListTransactionTypeRes;
          const hashList = _.mapKeys(state.listTransactionTypePaging.data, 'id');
          state.listTransactionTypePaging = {
            total: state.listTransactionTypePaging.total,
            data: state.listTransactionTypePaging.data.map((type) => {
              return type.id == dataRes.id ? dataRes : hashList[type.id as keyof typeof hashList];
            }),
          };
        }
      },
    );
  },
};
