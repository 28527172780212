import React, { forwardRef, useImperativeHandle, useState } from 'react';

import AutoComplete from 'components/shared/AutoComplete';
import SwitchLabel from 'components/shared/SwitchLabel';
import TextInput from 'components/shared/TextInput';
import { JOI } from 'constants/joi';
import { customizerSelector } from 'store/customizer';
import { useSelector } from 'store/Store';
import { VehicleTypeRes } from 'types/vehicle.type';
import { createSchema, validateSchema } from 'utils/joi';

import { Grid } from '@mui/material';
import { IconDotsCircleHorizontal, IconRectangle, IconSteeringWheel } from '@tabler/icons-react';

type VehicleType = {
  name?: string;
  vehicleTypeId?: string;
  licensePlate?: string;
  isActive?: boolean;
};
interface Props {
  data: VehicleType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
  isDisable?: boolean;
  listVehicleType?: VehicleTypeRes[];
}
const initError = { name: '', vehicleTypeId: '', licensePlate: '' };

const VehicleForm = forwardRef(
  ({ data, listVehicleType = [], onChange, onSubmit, isDisable = false }: Props, ref) => {
    const [vehicleError, setVehicleError] = useState(initError);
    const { isLanguage } = useSelector(customizerSelector);

    const schema = createSchema({
      name: JOI.REQUIRE,
      vehicleTypeId: JOI.REQUIRE,
      licensePlate: JOI.REQUIRE,
    });

    const handleChange = (key: string) => (value: string | boolean) => {
      onChange(key, value);
    };

    const handleSubmitVehicle = () => {
      const clone = { ...data };
      delete clone.isActive;
      const resError = validateSchema(schema, clone);
      setVehicleError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit && onSubmit();
      }
    };
    useImperativeHandle(ref, () => ({
      handleSubmitVehicle,
    }));

    return (
      <Grid container spacing="12px">
        <Grid item container spacing="12px" lg={12}>
          <Grid item xs={12} lg={12}>
            <TextInput
              isRequired
              title="vehicle-name"
              Icon={IconSteeringWheel}
              value={data.name}
              placeholder="placeholder.vehicle-name"
              onChange={handleChange('name')}
              errorMessage={vehicleError.name}
              isDisable={isDisable}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextInput
              isRequired
              title="licensePlate"
              Icon={IconRectangle}
              value={data.licensePlate}
              placeholder="placeholder.licensePlate"
              onChange={handleChange('licensePlate')}
              errorMessage={vehicleError.licensePlate}
              isDisable={isDisable}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <AutoComplete
              isRequired
              title={'vehicle-type'}
              options={listVehicleType.map((type) => ({
                label: String(isLanguage === 'vi' ? type.nameVi : type.nameEn),
                value: String(type.id),
              }))}
              value={data.vehicleTypeId}
              placeHolder="placeholder.choose-vehicle"
              Icon={IconDotsCircleHorizontal}
              onChange={handleChange('vehicleTypeId')}
              errorMessage={vehicleError.vehicleTypeId}
              isDisable={isDisable}
            />
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <SwitchLabel
            title="active"
            isChecked={Boolean(data.isActive)}
            onChange={handleChange('isActive')}
            isDisable={isDisable}
          />
        </Grid>
      </Grid>
    );
  },
);

export default React.memo(VehicleForm);
