import { PERMISSION } from 'constants/permission';
import TransactionReportContainer from 'containers/report/TransactionReportContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const TransactionReportPage = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SB_TAB_TRANSACTION]}>
      <TransactionReportContainer />
    </PermissionPageWrapper>
  );
};

export default TransactionReportPage;
