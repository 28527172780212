import { PERMISSION } from 'constants/permission';
import RoomTypeTabContainer from 'containers/management/tab/RoomTypeTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const RoomTypeTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_ROOM_TYPES]}>
      <RoomTypeTabContainer />
    </PermissionPageWrapper>
  );
};

export default RoomTypeTab;
