import React, { useEffect, useMemo } from 'react';

import InfoCard from 'components/card/InfoCard';
import PageContainer from 'components/container/PageContainer';
import TabWrapper from 'components/shared/TabWrapper';
import { ROUTES } from 'constants/router';
import { TRANSACTION_TAB } from 'enums';
import { propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { getOverviewTransactionInBuildingAction, transactionSelector } from 'store/transaction';
import { formatPrice, parseStringToDate, showData } from 'utils/common';

import { Box, Grid } from '@mui/material';
import {
  IconMinus,
  IconPlus,
  IconReceipt,
  IconReceiptRefund,
  IconReport,
  IconReportOff,
} from '@tabler/icons-react';
import { PERMISSION } from 'constants/permission';
import PermissionRender from 'layouts/permission/PermissionRender';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/common';

const TAB_LIST = [
  {
    value: TRANSACTION_TAB.INVOICE_TRANSACTION,
    Icon: IconReceipt,
    label: TRANSACTION_TAB.INVOICE_TRANSACTION,
    path: ROUTES.REPORT.TRANSACTION_TAB.INVOICE_TRANSACTION,
    disabled: false,
    permission: [PERMISSION.SUB_TAB_INVOICE_TRANSACTION],
  },
  {
    value: TRANSACTION_TAB.DEPOSIT_TRANSACTION,
    Icon: IconReport,
    label: TRANSACTION_TAB.DEPOSIT_TRANSACTION,
    path: ROUTES.REPORT.TRANSACTION_TAB.DEPOSIT_TRANSACTION,
    disabled: false,
    permission: [PERMISSION.SUB_TAB_DEPOSIT_TRANSACTION],
  },
  {
    value: TRANSACTION_TAB.OTHER_TRANSACTION,
    Icon: IconReceiptRefund,
    label: TRANSACTION_TAB.OTHER_TRANSACTION,
    path: ROUTES.REPORT.TRANSACTION_TAB.OTHER_TRANSACTION,
    disabled: false,
    permission: [PERMISSION.SUB_TAB_OTHER_TRANSACTION],
  },
  {
    value: TRANSACTION_TAB.DEBT_TRANSACTION,
    Icon: IconReportOff,
    label: TRANSACTION_TAB.DEBT_TRANSACTION,
    path: ROUTES.REPORT.TRANSACTION_TAB.DEBT_TRANSACTION,
    disabled: false,
    permission: [PERMISSION.SUB_TAB_DEBT_TRANSACTION],
  },
];

const TransactionReportContainer = () => {
  const dispatch = useDispatch();
  const { overviewTransactionInBuilding } = useSelector(transactionSelector);

  const { buildingId } = useSelector(propertySelector);

  useEffect(() => {
    dispatch(
      getOverviewTransactionInBuildingAction({
        buildingId,
        startDate: parseStringToDate(moment().startOf('month').format(DATE_FORMAT)) || '',
        endDate: parseStringToDate(moment().format(DATE_FORMAT)) || '',
      }),
    );
  }, [buildingId, dispatch]);

  const processedTopCard = useMemo(() => {
    return [
      {
        title: 'transaction-total',
        value: `${showData(overviewTransactionInBuilding.total)}`,
        colorSchema: 'primary',
        icon: IconReceipt,
      },
      {
        title: 'income-total',
        value: `${formatPrice(String(overviewTransactionInBuilding.totalIncome))}`,
        colorSchema: 'secondary',
        icon: IconPlus,
      },
      {
        title: 'expense-total',
        value: `${formatPrice(String(overviewTransactionInBuilding.totalExpense))}`,
        colorSchema: 'error',
        icon: IconMinus,
      },
    ];
  }, [overviewTransactionInBuilding]);

  return (
    <PageContainer title="transaction-list">
      <PermissionRender permission={[PERMISSION.GET_OVERVIEW_TRANSACTIONS]}>
        <Grid item container xs={12} spacing="12px">
          {processedTopCard.map((topCard, i) => (
            <Grid key={`card-${i}`} item xs={12} sm={12} lg={4}>
              <InfoCard
                colorSchema={topCard.colorSchema}
                title={topCard.title}
                value={topCard.value}
                Icon={topCard.icon}
              />
            </Grid>
          ))}
        </Grid>
      </PermissionRender>
      <Box width="100%" mt="24px">
        <TabWrapper tabList={TAB_LIST} />
      </Box>
    </PageContainer>
  );
};

export default TransactionReportContainer;
