import { PERMISSION } from 'constants/permission';
import CheckinReportContainer from 'containers/report/CheckinReportContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const CheckinReportPage = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SB_TAB_APPROACHING_CHECK_IN]}>
      <CheckinReportContainer />
    </PermissionPageWrapper>
  );
};

export default CheckinReportPage;
