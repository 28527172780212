import React from 'react';

import InvoiceTabContainer from 'containers/room/tab/InvoiceTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import { PERMISSION } from 'constants/permission';

const InvoiceTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SB_TAB_ROOM_LIST]}>
      <InvoiceTabContainer />
    </PermissionPageWrapper>
  );
};

export default InvoiceTab;
