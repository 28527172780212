import React, { forwardRef, useImperativeHandle, useState } from 'react';

import AutoComplete from 'components/shared/AutoComplete';
import InputNumber from 'components/shared/InputNumber';
import InputPrice from 'components/shared/InputPrice';
import TextArea from 'components/shared/TextArea';
import { JOI } from 'constants/joi';
import { GetListServiceRes } from 'types/property.type';
import { ServiceFeeForm } from 'types/service.type';
import { createSchema, validateSchema } from 'utils/joi';

import { Grid } from '@mui/material';
import { IconCoin, IconDotsCircleHorizontal, IconUsers } from '@tabler/icons-react';
import { formatNumber } from 'utils/common';

interface Props {
  data: ServiceFeeForm;
  listServiceType?: GetListServiceRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
  isDisable?: boolean;
  isDisableServiceType?: boolean;
  unitTypeId?: string;
}
const initError = { serviceFeeTypeId: '', unitPrice: '', amount: '' };

const ContractFeeForm = forwardRef(
  (
    {
      data,
      onChange,
      onSubmit,
      listServiceType = [],
      isColumn = false,
      isDisable = false,
      isDisableServiceType = false,
      unitTypeId,
    }: Props,
    ref,
  ) => {
    const [serviceFeeError, setServiceFeeError] = useState(initError);
    const schema = createSchema({
      serviceFeeTypeId: JOI.REQUIRE,
      unitPrice: JOI.REQUIRE,
      amount: JOI.REQUIRE,
    });

    const handleChange = (key: string) => (value: string | boolean) => {
      onChange(key, value);
    };

    const handleSubmitContractFee = () => {
      const clone = { ...data };
      delete clone.note;
      delete clone.name;
      delete clone.discount;
      delete clone.isMeter;
      const resError = validateSchema(schema, clone);
      setServiceFeeError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit && onSubmit();
      }
    };
    useImperativeHandle(ref, () => ({
      handleSubmitContractFee,
    }));

    return (
      <Grid container spacing="12px">
        <Grid item xs={12} lg={isColumn ? 12 : 6}>
          <AutoComplete
            isRequired
            title="service-type"
            options={listServiceType.map((serviceType) => ({
              label: serviceType.name || '',
              value: String(serviceType.id),
            }))}
            value={data.serviceFeeTypeId}
            placeHolder="placeholder.choose-service-type"
            errorMessage={serviceFeeError.serviceFeeTypeId}
            onChange={handleChange('serviceFeeTypeId')}
            Icon={IconDotsCircleHorizontal}
            isDisable={isDisableServiceType}
          />
        </Grid>
        <Grid item xs={12} lg={isColumn ? 12 : 6}>
          <InputPrice
            isRequired
            onChange={handleChange('unitPrice')}
            value={String(data.unitPrice)}
            title={'price'}
            placeholder={'30.000VNĐ...'}
            Icon={IconCoin}
            errorMessage={serviceFeeError.unitPrice}
            isDisable={isDisable}
          />
        </Grid>
        <Grid item xs={12} lg={isColumn ? 12 : 6}>
          <InputNumber
            isRequired
            Icon={IconUsers}
            title="amount"
            value={String(data.amount)}
            onChange={handleChange('amount')}
            errorMessage={serviceFeeError.amount}
            placeholder={'amount'}
            isDisable={Boolean(
              data.isMeter === '1' || unitTypeId === '1' || isDisable ? true : false,
            )}
          />
        </Grid>
        <Grid item xs={12} lg={isColumn ? 12 : 6}>
          <InputPrice
            onChange={handleChange('discount')}
            value={String(Number(data.amount) * formatNumber(String(data.unitPrice)))}
            title={'total-price'}
            placeholder={'30.000VNĐ...'}
            Icon={IconCoin}
            isDisable={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            title="note"
            value={data.note}
            placeholder="placeholder.type-here"
            onChange={handleChange('note')}
          />
        </Grid>
      </Grid>
    );
  },
);

export default React.memo(ContractFeeForm);
