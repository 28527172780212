import {
  getDebtTransactionInBuilding,
  getDepositTransactionInBuilding,
  getListTransactionByClientPaging,
  getListTransactionInBuilding,
  getListTransactionInContract,
  getOverviewTransactionInBuilding,
} from 'services/user/transaction.service';
import { CommonReq, PagingReq } from 'types/common.type';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportReq } from 'types/report.type';
import { isAllow } from 'utils/common';
import { PERMISSION } from 'constants/permission';

export const getListTransactionInContractPagingAction = createAsyncThunk(
  'transaction/getListTransactionInContractPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListTransactionInContract(payload);

    return response.data;
  },
);

export const getListTransactionInBuildingPagingAction = createAsyncThunk(
  'transaction/getListTransactionInBuildingPagingAction',
  async (payload: CommonReq & PagingReq & ReportReq) => {
    const response = await getListTransactionInBuilding(payload);

    return response.data;
  },
);

export const getOverviewTransactionInBuildingAction = createAsyncThunk(
  'transaction/getOverviewTransactionInBuildingAction',
  async (payload: CommonReq & ReportReq) => {
    if (isAllow([PERMISSION.SB_MANAGEMENT])) {
      const response = await getOverviewTransactionInBuilding(payload);

      return response?.data;
    }
  },
);

export const getListTransactionByClientPagingAction = createAsyncThunk(
  'transaction/getListTransactionByClientPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListTransactionByClientPaging(payload);

    return response.data;
  },
);

export const getListDepositTransactionInBuildingPagingAction = createAsyncThunk(
  'transaction/getListDepositTransactionInBuildingPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getDepositTransactionInBuilding(payload);

    return response.data;
  },
);

export const getListDebtTransactionInBuildingPagingAction = createAsyncThunk(
  'transaction/getListDebtTransactionInBuildingPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getDebtTransactionInBuilding(payload);

    return response.data;
  },
);
