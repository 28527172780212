import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRANSACTION_STATUS } from 'enums';
import { getListOptionTransactionType, getListTransactionType, updateTransactionType } from 'services/property';
import { CommonReq, PagingReq } from 'types/common.type';
import { UpdateTransactionType } from 'types/property.type';

export const getTransactionTypePagingAction = createAsyncThunk(
  'property/getTransactionTypePagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListTransactionType(payload);

    return response.data;
  },
);

export const updateTransactionTypeAction = createAsyncThunk(
  'property/updateTransactionTypeAction',
  async (payload: CommonReq & UpdateTransactionType) => {
    const response = await updateTransactionType(payload);

    return response.data;
  },
);

export const getListOptionTransactionTypeAction = createAsyncThunk(
  'property/getListOptionTransactionTypeAction',
  async (payload: { actionType?: TRANSACTION_STATUS }) => {
    const response = await getListOptionTransactionType(payload);

    return response.data;
  },
);

