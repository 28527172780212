import { centralGW } from 'services/axios.service';
import { CommonReq } from 'types/common.type';
import { ContractFeeReq } from 'types/contract.type';
import { ServiceFeeForm } from 'types/service.type';

export const getContractFeeList = (payload: CommonReq) => {
  const { contractId } = payload;

  return centralGW.get(`/api/v1/user/contract/${contractId}/fees`, {
    params: {
      isFull: true,
    },
    isDisableToast: true,
  });
};

export const createContractFee = (payload: CommonReq & ServiceFeeForm) => {
  const { contractId, serviceFeeTypeId, unitPrice, amount, discount, note, name } = payload;

  return centralGW.post(`/api/v1/user/contract/${contractId}/fee`, {
    serviceFeeTypeId,
    unitPrice,
    amount,
    note,
    name,
    discount,
  });
};

export const deleteContractFee = (payload: CommonReq & ContractFeeReq) => {
  const { contractId, contractFeeId } = payload;

  return centralGW.delete(`/api/v1/user/contract/${contractId}/fee/${contractFeeId}`, {});
};

export const updateContractFee = (payload: CommonReq & ServiceFeeForm & ContractFeeReq) => {
  const { contractId, discount, unitPrice, amount, contractFeeId, note } = payload;

  return centralGW.put(`/api/v1/user/contract/${contractId}/fee/${contractFeeId}`, {
    discount,
    unitPrice,
    amount,
    note,
  });
};
