import React, { useEffect, useMemo } from 'react';

import TableNormal from 'components/table/TableNormal';
import { DATE_TIME_FORMAT } from 'constants/common';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import {
  getListDebtTransactionInBuildingPagingAction,
  transactionSelector,
} from 'store/transaction';
import { formatDate, formatPrice, showData } from 'utils/common';

import { Box, Link, Stack, Typography } from '@mui/material';

const TRANSACTION_TABLE_HEADER = ['stt', 'debt-price', 'note', 'creation-date'];

const DebtTransactionContainerTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { buildingId } = useSelector(propertySelector);
  const { listDebtTransactionInBuildingPaging } = useSelector(transactionSelector);

  useEffect(() => {
    dispatch(
      getListDebtTransactionInBuildingPagingAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, buildingId, rowsPerPage, currentPage]);

  const transactionTableRows = useMemo(() => {
    return listDebtTransactionInBuildingPaging.data.map((transaction) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link href="#" underline="none">
            <Typography variant="subtitle1" color="textSecondary">
              {showData(transaction.id)}
            </Typography>
          </Link>
        </Stack>,
        formatPrice(String(transaction.price)),
        showData(transaction.note),
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
      ];
    });
  }, [listDebtTransactionInBuildingPaging.data]);

  return (
    <Box>
      <TableNormal
        header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
        data={transactionTableRows}
        total={listDebtTransactionInBuildingPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
    </Box>
  );
};
export default DebtTransactionContainerTab;
