import { CustomResponseType } from 'types/common.type';
import { RoomType } from 'types/room.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getRoomDetailAction } from './actions';

interface RoomState {
  roomDetail: RoomType;
}
const initialState: RoomState = {
  roomDetail: {},
};
export const roomSlice = createSlice({
  name: 'room',
  reducers: {
    resetRoomDetail: (state) => {
      state.roomDetail = initialState.roomDetail;
    },
  },
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<RoomState>) => {
    builder.addCase(
      getRoomDetailAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.roomDetail = action.payload.data;
        }
      },
    );
  },
});

export const { resetRoomDetail } = roomSlice.actions;
export default roomSlice.reducer;
