/* eslint-disable @typescript-eslint/no-unused-vars */
import avatar from 'assets/images/profile/user-1.jpg';
import TextInput from 'components/shared/TextInput';

import { Avatar, Button, Fab, Stack } from '@mui/material';
import { IconBrandTelegram, IconPhoto } from '@tabler/icons-react';
import QuillEditor from 'components/shared/QuillEditor';
import TextArea from 'components/shared/TextArea';
import { ChangeEvent, LegacyRef, useState } from 'react';
import { createSchema, validateSchema } from 'utils/joi';
import { JOI } from 'constants/joi';
import { useTranslation } from 'react-i18next';
import { MAX_UPLOAD_LIMIT } from 'constants/common';
import { CommentType } from 'types/comment.type';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

interface Props {
  data: CommentType;
  onChange: (key: string, value: string) => void;
  onSubmit?: () => void;
  onUpload?: (value: File[]) => void;
  isDisable?: boolean;
  isRequired?: boolean;
}

const CommentForm = ({
  data,
  onChange,
  onSubmit,
  isDisable = false,
  isRequired = false,
  onUpload,
}: Props) => {
  const { t } = useTranslation();
  const handleChange = (key: string) => (value: string) => {
    onChange(key, value);
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      if (files.length > MAX_UPLOAD_LIMIT) {
        alert(`${t('upload-maximum')} ${MAX_UPLOAD_LIMIT} ${t('image')}.`);

        return;
      }
      onUpload && onUpload(files);
    }
  };
  const initError = {
    comment: '',
  };

  const [commentError, setCommentError] = useState(initError);
  const schema = createSchema({
    comment: JOI.REQUIRE,
  });
  const handleSubmitComment = () => {
    const clone = { ...data };
    if (!isRequired) {
      delete clone.comment;
    }
    delete clone.userId;
    const resError = validateSchema(schema, clone);
    setCommentError(resError.errorMessage);
    if (!resError.isError) {
      onSubmit && onSubmit();
    }
  };

  return (
    <Stack width="100%" direction={'column'}>
      <Stack direction={'row'} gap={2} justifyItems="center">
        <Avatar alt="Remy Sharp" src={avatar} sx={{ width: '33px', height: '33px' }} />
        <TextArea
          value={data.comment}
          placeholder="placeholder.message"
          onChange={handleChange('comment')}
          isDisable={isDisable}
          errorMessage={commentError.comment}
        />
      </Stack>
      <Stack direction="row" gap={1} mt={2} alignItems="center" justifyContent="flex-end">
        <PermissionRender permission={[PERMISSION.UPLOAD_IMAGE]}>
          <Button variant="text" color="primary" component="label">
            {t('upload')}
            <input
              type="file"
              multiple
              accept="image/png, image/gif, image/jpeg, video/*"
              style={{ display: 'none' }}
              onChange={handleUploadFile}
            />
          </Button>
        </PermissionRender>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmitComment}
          disabled={!data.comment}
        >
          <IconBrandTelegram size="16" />
        </Button>
      </Stack>
    </Stack>
  );
};

export default CommentForm;
