import {
  cancelContract,
  changeRepresentativeInRoom,
  checkout,
  createContract,
  getContractDetail,
  getContractInBuildingPaging,
  getListContract,
  getListContractInRoom,
  renewalContract,
} from 'services/user/contract.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { ContractReq, RenewalContractType } from 'types/contract.type';
import { isSuccessCode } from 'utils/common';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { CheckoutReq } from 'types/checkout.type';

export const getListContractPagingAction = createAsyncThunk(
  'contract/getListContract',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListContract(payload);

    return response.data;
  },
);

export const getListContractInRoomAction = createAsyncThunk(
  'contract/getListContractInRoomAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListContractInRoom(payload);

    return response.data;
  },
);

export const getContractDetailAction = createAsyncThunk(
  'contract/getContractDetailAction',
  async (payload: CommonReq) => {
    const response = await getContractDetail(payload);

    return response.data;
  },
);

export const createContractAction = createAsyncThunk(
  'contract/createContractAction',
  async (payload: CommonReq & ContractReq) => {
    const response = await createContract(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const renewContractAction = createAsyncThunk(
  'contract/renewContractAction',
  async (payload: CommonReq & RenewalContractType) => {
    const response = await renewalContract(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const getContractInBuildingPagingAction = createAsyncThunk(
  'contract/getContractInBuildingPagingAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getContractInBuildingPaging(payload);

    return response.data;
  },
);

export const getContractAvailableAction = createAsyncThunk(
  'contract/getContractAvailableAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getContractInBuildingPaging({
      buildingId: payload.buildingId,
      isFull: true,
      isExpired: false,
    });

    return response.data;
  },
);

export const changeRepresentativeInRoomAction = createAsyncThunk(
  'contract/changeRepresentativeInRoomAction',
  async (payload: CommonReq) => {
    const response = await changeRepresentativeInRoom(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const checkoutAction = createAsyncThunk(
  'contract/checkoutAction',
  async (payload: CommonReq & CheckoutReq) => {
    const response = await checkout(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const cancelContractAction = createAsyncThunk(
  'contract/cancelContractAction',
  async (payload: CommonReq & CheckoutReq) => {
    const response = await cancelContract(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
