import React, {
  useEffect,
  useMemo,
} from 'react';

import ContractChip from 'components/shared/ContractChip';
import TableNormal from 'components/table/TableNormal';
import { ROUTES } from 'constants/router';
import { useDirection } from 'hooks';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  contractSelector,
  getListContractPagingAction,
} from 'store/contract';
import {
  useDispatch,
  useSelector,
} from 'store/Store';
import {
  formatDate,
  showData,
} from 'utils/common';

import {
  Link,
  Stack,
  Typography,
} from '@mui/material';

const CONTRACT_TABLE_HEADER = ['contract-code', 'status', 'room', 'start-date', 'end-date'];

const ContractTabContainer = () => {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const { listContractPaging } = useSelector(contractSelector);
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();

  useEffect(() => {
    if (clientId) {
      dispatch(
        getListContractPagingAction({
          page: currentPage,
          limit: rowsPerPage,
          userId: Number(clientId),
        }),
      );
    }
  }, [dispatch, clientId, currentPage, rowsPerPage]);

  const contractTableRows = useMemo(() => {
    return listContractPaging.data.map((contract) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link href="#" underline="none">
            <Typography variant="subtitle1">{showData(contract.id)}</Typography>
          </Link>
        </Stack>,
        <ContractChip key={`link-${contract.id}`} isExpired={Boolean(contract?.isExpired)} />,
        <Link
          key={uniqueId()}
          onClick={goTo(`${ROUTES.ROOM.LIST}/${contract.infoRoom?.room?.id}`)}
          underline="none"
        >
          <Typography variant="subtitle1">{showData(contract.infoRoom?.room?.name)}</Typography>
        </Link>,
        formatDate(contract.startAt),
        formatDate(contract.endAt),
      ];
    });
  }, [listContractPaging.data, goTo]);

  return (
    <TableNormal
      header={CONTRACT_TABLE_HEADER.map((cell) => t(cell))}
      data={contractTableRows}
      total={listContractPaging?.total}
      limit={rowsPerPage}
      currentPage={currentPage}
      onChangePage={handleChangeCurrentPage}
      onChangeLimit={handleChangeLimit}
    />
  );
};

export default ContractTabContainer;
