import { CustomResponseType } from 'types/common.type';
import { GetListEmployeeRes } from 'types/employee.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getEmployeeDetailAction,
  getListEmployeeInBranchAction,
  getListEmployeePagingAction,
} from './actions';

interface EmployeeState {
  listEmployeePaging: { data: GetListEmployeeRes[]; total: number };
  employeeDetail: GetListEmployeeRes;
  listEmployeeInBranch: { data: GetListEmployeeRes[]; total: number };
}
const initialState: EmployeeState = {
  listEmployeePaging: {
    data: [],
    total: 0,
  },
  listEmployeeInBranch: {
    data: [],
    total: 0,
  },
  employeeDetail: {},
};
export const employeeSlice = createSlice({
  name: 'employee',
  reducers: {},
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<EmployeeState>) => {
    builder.addCase(
      getListEmployeePagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listEmployeePaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getEmployeeDetailAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.employeeDetail = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListEmployeeInBranchAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listEmployeeInBranch = action.payload.data;
        }
      },
    );
  },
});

export const {} = employeeSlice.actions;
export default employeeSlice.reducer;
