import React, { RefObject, useRef } from 'react';

import DialogWrapper from 'components/dialog/DialogWrapper';
import RoomForm from 'components/forms/RoomForm';
import { useTranslation } from 'react-i18next';
import { ModalActionType } from 'types/common.type';
import { FloorRes, RoomTypeRes } from 'types/general.type';
import { RoomType } from 'types/room.type';

interface Props extends ModalActionType {
  data: RoomType;
  listRoomTypeOptions?: RoomTypeRes[];
  listFloorNameOptions?: FloorRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
}

const RoomModal = ({
  data,
  isOpen,
  listRoomTypeOptions,
  listFloorNameOptions,
  onClose,
  onChange,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const submitRef: RefObject<any> = useRef<{ handleSubmitRoom: () => void }>();

  const handleSubmitRoom = () => {
    submitRef.current?.handleSubmitRoom();
  };
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DialogWrapper
      title={String(t('create-room'))}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitRoom}
    >
      <RoomForm
        ref={submitRef}
        data={data}
        listRoomTypeOptions={listRoomTypeOptions}
        listFloorNameOptions={listFloorNameOptions}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </DialogWrapper>
  );
};

export default React.memo(RoomModal);
