import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';
import { GeneralState } from '../reducer';
import { getListCountryAction, getListDistrictAction, getListProvinceAction } from '../actions';

export const locationReducer = {
  reducers: {
    clearListDistrict: (state: GeneralState) => {
      state.listDistrict = [];
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<GeneralState>) => {
    builder.addCase(
      getListCountryAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listCountry = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListProvinceAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listProvince = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListDistrictAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listDistrict = action.payload.data;
        }
      },
    );
  },
};
