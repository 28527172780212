import React, { CSSProperties, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ResponsiveStyleValue } from '@mui/system';

type Props = {
  children?: ReactNode;
  title?: string;
  variant?: Variant;
  color?: ResponsiveStyleValue<string | undefined>;
  sx?: CSSProperties;
  fontWeight?: number;
};

const TypographyCustom = ({
  children,
  title = '',
  variant = 'subtitle1',
  color = 'inherit',
  sx,
  fontWeight,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', mt: '10px', alignItems: 'center', ...sx }}>
      <Typography whiteSpace="nowrap" color={color} variant={variant} fontWeight={fontWeight}>
        {t(title)}:&nbsp;&nbsp;
      </Typography>
      <Typography
        variant={variant}
        color={color}
        mt="2px"
        sx={{ justifyItems: 'center', alignItems: 'center' }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default TypographyCustom;
