import { getListVehicleType } from 'services/general/vehicleType.service';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListVehicleTypeAction = createAsyncThunk(
  'general/getListVehicleTypeAction',
  async () => {
    const response = await getListVehicleType();

    return response.data;
  },
);
