import React, { useState } from 'react';
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import { IconCircle, IconDownload } from '@tabler/icons-react';
import { getDateTime } from 'utils/common';
import { useDisclosure } from 'hooks';
import ImagePopup from './ImagePopup';

type Props = {
  avatar?: string;
  username?: string;
  date?: string | Date;
  content?: string;
  images?: string[] | string;
};

const PostComment = ({ avatar, username, date, content, images }: Props) => {
  const [isShowed, setIsShowed] = useState(false);
  const [image, setOpenImage] = useState('');

  const {
    isOpen: isOpenImage,
    onClose: onCloseImage,
    onOpen: onOpenDeleteTabConfirm,
  } = useDisclosure();

  const handleDownload = (url: string) => () => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${content}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
      });
  };

  const handleDirectToImage = (url: string) => () => {
    if (url) {
      setOpenImage(url);
      onOpenDeleteTabConfirm();
    }
  };

  return (
    <Box
      p={2}
      mb={1}
      sx={{
        borderColor: (theme: any) => theme.palette.divider,
        borderWidth: '1px',
        borderStyle: 'solid',
      }}
    >
      <Stack direction={'row'} gap={2} alignItems="flex-start">
        <Avatar src={avatar} sx={{ width: '33px', height: '33px' }} />
        <Stack direction={'column'}>
          <Stack direction={'row'} gap={2} alignItems="center">
            <Typography variant="h6" fontSize={13}>
              {username}
            </Typography>
            <Stack direction="row" alignItems={'center'} justifyItems="center" gap={1}>
              <IconCircle size="7" fill="" fillOpacity={'0.1'} strokeOpacity="0.1" />{' '}
              {getDateTime(String(date))}
            </Stack>
          </Stack>
          <Box>
            <Typography color="textSecondary">{content}</Typography>
            <Stack direction="row" gap={2}>
              {images && (
                <Box
                  style={{ position: 'relative' }}
                  onMouseEnter={() => setIsShowed(true)}
                  onMouseLeave={() => setIsShowed(false)}
                >
                  <img
                    src={String(images)}
                    alt={`File `}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100px',
                      marginRight: '10px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      opacity: isShowed ? '0.5' : '1',
                      transition: 'opacity 0.3s ease',
                    }}
                    onClick={handleDirectToImage(String(images))}
                  />
                  <IconButton
                    color="primary"
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '10px',
                      opacity: isShowed ? '1' : '0',
                      transition: 'opacity 0.3s ease',
                    }}
                    onClick={handleDownload(String(images))}
                  >
                    <IconDownload />
                  </IconButton>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <ImagePopup isOpen={isOpenImage} onClose={onCloseImage} images={[image]} />
    </Box>
  );
};

export default PostComment;
