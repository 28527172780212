import React, { useRef } from 'react';

import RoomTypeForm from 'components/forms/RoomTypeForm';
import DrawerWrapper from 'components/shared/DrawerWrapper';
import { ModalActionType } from 'types/common.type';
import { RoomCategoryType } from 'types/room.type';

interface Props extends ModalActionType {
  data: RoomCategoryType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
}

const RoomDrawer = ({ data, isOpen, onClose, onChange, onSubmit, isColumn }: Props) => {
  const submitRef = useRef<{ handleSubmitRoomType: () => void }>();

  const handleSubmitRoomType = () => {
    submitRef.current?.handleSubmitRoomType();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DrawerWrapper
      title={'room-type-information'}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitRoomType}
    >
      <RoomTypeForm
        data={data}
        onChange={onChange}
        ref={submitRef}
        isColumn={isColumn}
        onSubmit={onSubmit}
      />
    </DrawerWrapper>
  );
};

export default React.memo(RoomDrawer);
