export const ROUTES = {
  HOME: '/',
  DASHBOARDS: '/dashboards',
  ROOM: {
    LIST: '/room',
    DETAIL: '/room/:roomId',
    DETAIL_TAB: {
      INVOICE: '',
      TRANSACTION: 'transaction',
      SERVICE: 'service',
    },
    BOOKING: '/room/booking',
  },
  REPORT: {
    APPROACHING_EXPIRED_REPORT: '/approaching-expired-report',
    CHECKIN_REPORT: '/checkin-report',
    TRANSACTION_REPORT: '/transaction-report',
    TRANSACTION_TAB: {
      INVOICE_TRANSACTION: '',
      DEPOSIT_TRANSACTION: 'deposit-transaction',
      OTHER_TRANSACTION: 'other-transaction',
      DEBT_TRANSACTION: 'debt-transaction',
    },
  },
  CLIENT: {
    LIST: '/client',
    ADD: '/client/add',
    DETAIL: '/client/:clientId',
    DETAIL_TAB: {
      BOOKING: '',
      CONTRACT: 'contract',
      INVOICE: 'invoice',
      TRANSACTION: 'transaction',
      VEHICLE: 'vehicle',
    },
  },
  CONTRACT: {
    LIST: '/contract',
    FORM: '/contract/form',
    DETAIL: '/contract/:contractId',
  },
  INVOICE: {
    LIST: '/invoice',
    INVOICE_DETAIL: '/invoice/:invoiceId',
    INVOICE_ADD: '/invoice/add',
    TRANSACTION: '/transaction',
  },
  MAINTENANCE: {
    LIST: '/maintenance',
    MAINTENANCE_TAB: {
      COMMENT_MAINTENANCE: '',
      HISTORY_MAINTENANCE: 'history-maintenance',
    },
  },
  ERROR: {
    NOT_FOUND: '/error/404',
    FORBIDDEN: '/error/403',
  },
  AUTH: {
    LOGIN: '/auth',
    FORGOT_PASSWORD: '/auth/forgot-password',
    VERIFY_FORGOT: '/auth/forgot-password/verify',
    NEW_PASSWORD: '/auth/forgot-password/change-password',
  },
  EMPLOYEE: {
    LIST: '/employee',
    ADD: '/employee/add',
  },
  MANAGEMENT: {
    BRANCH_INFORMATION: '/management',
    BUILDING_DETAIL: '/management/:buildingId',
    BUILDING_DETAIL_TAB: {
      FLOOR_ROOM: '',
      ROOM_TYPE: 'room-type',
      SERVICE: 'service',
      TRANSACTION_TYPE: 'transaction-type',
      PAYMENT_METHOD: 'payment-method',
    },
  },
  MANUAL: '/manual',
};
