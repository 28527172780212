import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListService, updateService } from 'services/property';
import { CommonReq } from 'types/common.type';
import { UpdateServiceReq } from 'types/property.type';

export const getListServiceAction = createAsyncThunk(
  'property/getListServiceAction',
  async (payload: CommonReq) => {
    const response = await getListService(payload);

    return response.data;
  },
);

export const updateServiceAction = createAsyncThunk(
  'property/updateServiceAction',
  async (payload: CommonReq & UpdateServiceReq) => {
    const response = await updateService(payload);

    return response.data;
  },
);
