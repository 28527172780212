import * as React from 'react';
import { Dialog, Zoom, IconButton } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';

type Props = {
  title?: string;
  isOpen?: boolean;
  onClose?: () => void;
  images?: string[];
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

function ImagePopup({ isOpen = false, onClose, images = [] }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  React.useEffect(() => {
    if (isOpen) {
      setCurrentIndex(0);
    }
  }, [isOpen, images]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      fullWidth={true}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(4px)',
      }}
    >
      <>
        <img
          onClick={onClose}
          src={images[currentIndex]}
          alt={`File ${currentIndex + 1}`}
          loading="lazy"
          style={{
            maxHeight: '90vh',
            objectFit: 'contain',
          }}
        />
        {images.length > 1 && (
          <>
            <IconButton
              onClick={handlePrevious}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(000, 000, 000, 0.3)',
              }}
            >
              <IconArrowLeft />
            </IconButton>
            <IconButton
              onClick={handleNext}
              sx={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(000, 000, 000, 0.3)',
              }}
            >
              <IconArrowRight />
            </IconButton>
          </>
        )}
      </>
    </Dialog>
  );
}

export default React.memo(ImagePopup);
