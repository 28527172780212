import { CustomResponseType } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import {
  getListRoomAction,
  getListRoomInFloorAction,
  getListRoomTypeAction,
  getOptionRoomAction,
} from '../actions';
import { PropertyState } from '../reducer';

export const roomReducer = {
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PropertyState>) => {
    builder.addCase(
      getListRoomAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listRoom = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListRoomTypeAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listRoomTypePaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getOptionRoomAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listRoomType = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListRoomInFloorAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listRoomInFloor = action.payload.data;
        }
      },
    );
  },
};
