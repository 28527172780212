import * as React from 'react';

import CustomFormLabel from 'components/theme-elements/CustomFormLabel';
import CustomRadio from 'components/theme-elements/CustomRadio';
import { useTranslation } from 'react-i18next';
import { Theme } from '@emotion/react';

import { Box, FormControlLabel, RadioGroup as RadioGroupCore, SxProps } from '@mui/material';
import { isAllow } from 'utils/common';

type Props = {
  title?: string;
  value?: string | number;
  onChange?: (newValue: string) => void;
  options?: {
    label: string;
    value: string | number;
    permission?: string[];
  }[];
  isDisable?: boolean;
  sx?: SxProps<Theme>;
};

function RadioGroup({ title = '', value, options = [], onChange, isDisable = false, sx }: Props) {
  const { t } = useTranslation();

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChange && onChange(value);
  };

  return (
    <Box width="100%" sx={sx}>
      <CustomFormLabel sx={{ mt: '0px' }}>{t(title)}</CustomFormLabel>
      <RadioGroupCore
        value={value}
        onChange={handleChange}
        sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
      >
        {options?.map((option) => {
          if (isAllow([String(option.permission)])) {
            return (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<CustomRadio />}
                label={t(option.label)}
                sx={{ ml: '5px' }}
                disabled={isDisable}
              />
            );
          }
        })}
      </RadioGroupCore>
    </Box>
  );
}

export default React.memo(RadioGroup);
