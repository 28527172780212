import React, { RefObject, useRef } from 'react';

import DialogWrapper from 'components/dialog/DialogWrapper';
import RoomTypeForm from 'components/forms/RoomTypeForm';
import { useTranslation } from 'react-i18next';
import { ModalActionType } from 'types/common.type';
import { RoomCategoryType } from 'types/room.type';

interface Props extends ModalActionType {
  data: RoomCategoryType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
}

const RoomTypeModal = ({ data, isOpen, onClose, onChange, onSubmit }: Props) => {
  const { t } = useTranslation();
  const submitRef: RefObject<any> = useRef<{ handleSubmitRoomType: () => void }>();

  const handleSubmitRoomType = () => {
    submitRef.current?.handleSubmitRoomType();
  };
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DialogWrapper
      title={String(t('create-room-type'))}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitRoomType}
      isFullWidth={false}
    >
      <RoomTypeForm data={data} onChange={onChange} ref={submitRef} onSubmit={onSubmit} />
    </DialogWrapper>
  );
};

export default React.memo(RoomTypeModal);
