/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { JOI } from 'constants/joi';

import { useTranslation } from 'react-i18next';
import { formatNumber, showData } from 'utils/common';
import { createSchema, validateSchema } from 'utils/joi';

import { Grid } from '@mui/material';
import { PaymentMethodType } from 'types/payment-method.type';
import { CreateTransactionType } from 'types/transaction.type';
import AutoComplete from 'components/shared/AutoComplete';
import { IconCoin, IconCreditCard, IconDoor, IconUsers } from '@tabler/icons-react';
import InputPrice from 'components/shared/InputPrice';
import { GetListBookingRes } from 'types/booking.type';

interface Props {
  listPaymentMethod: PaymentMethodType[];
  listDepositInvoiceInBuilding: GetListBookingRes[];
  buildingId: number;
  isExpense?: boolean;
  onSubmit?: (payload: CreateTransactionType) => void;
}

const PaymentDepositForm = forwardRef(
  (
    {
      listPaymentMethod,
      buildingId,
      listDepositInvoiceInBuilding,
      isExpense = false,
      onSubmit,
    }: Props,
    ref,
  ) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<CreateTransactionType>({
      buildingId: String(buildingId),
      roomId: '',
      userId: '',
      invoiceId: '',
      paymentMethodId: '',
      transactionAmount: '',
    });

    const [formDataError, setFormDataError] = useState<CreateTransactionType>({
      buildingId: '',
      roomId: '',
      userId: '',
      invoiceId: '',
      paymentMethodId: '',
      transactionAmount: '',
    });

    const schema = createSchema({
      buildingId: JOI.REQUIRE,
      roomId: JOI.REQUIRE,
      userId: JOI.REQUIRE,
      invoiceId: JOI.REQUIRE,
      paymentMethodId: JOI.REQUIRE,
      transactionAmount: JOI.PRICE,
    });

    const handleChangeFormData = (key: string) => (value: string | number) => {
      setFormData((pre) => ({
        ...pre,
        [key]: value,
      }));
    };

    const handleSubmit = async () => {
      const resError = validateSchema(schema, formData);
      setFormDataError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit &&
          onSubmit({
            ...formData,
            transactionAmount: String(formatNumber(formData.transactionAmount || '')),
          });
      }
    };

    const selectedBooking = useMemo(() => {
      const invoice = listDepositInvoiceInBuilding?.find(
        (invoice) => invoice.booking.roomId == formData.roomId,
      );
      if (invoice) {
        setFormData((prev) => ({
          ...prev,
          userId: String(invoice.booking.userInfoId),
          transactionAmount: isExpense
            ? String(invoice?.totalTransactionAmount)
            : String(invoice.debt),
          invoiceId: String(invoice?.id),
        }));
      }

      return invoice;
    }, [formData.roomId, listDepositInvoiceInBuilding, isExpense]);
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    return (
      <Grid container spacing="12px" mb="12px">
        <Grid container item spacing="12px">
          <Grid item xs={12} sm={6}>
            <AutoComplete
              isRequired
              title={t('room') || ''}
              placeHolder="placeholder.choose-room"
              Icon={IconDoor}
              value={String(formData.roomId)}
              options={listDepositInvoiceInBuilding.map((invoice) => ({
                label: showData(String(invoice?.booking?.infoRoom?.room?.name)),
                value: String(invoice?.booking?.roomId),
              }))}
              onChange={handleChangeFormData('roomId')}
              errorMessage={String(formDataError.roomId)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              isRequired
              title={t('client') || ''}
              placeHolder="placeholder.choose-client"
              Icon={IconUsers}
              value={String(formData.userId)}
              options={[
                {
                  label: showData(
                    String(selectedBooking?.booking.userInfo?.accountDetail?.fullName),
                  ),
                  value: String(selectedBooking?.booking.userInfoId),
                },
              ]}
              onChange={handleChangeFormData('userId')}
              isDisable
              errorMessage={formDataError.userId}
            />
          </Grid>
        </Grid>
        <Grid container item spacing="12px">
          <Grid item xs={12} sm={6}>
            <InputPrice
              isRequired
              title={t('deposits-total') || ''}
              placeholder={'30.000VNĐ...'}
              Icon={IconCoin}
              value={String(formData.transactionAmount)}
              onChange={handleChangeFormData('transactionAmount')}
              maxValue={Number(
                isExpense ? selectedBooking?.totalTransactionAmount : selectedBooking?.debt,
              )}
              errorMessage={formDataError.transactionAmount}

              // isDisable={!isExpense}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              isRequired
              title={t('payment-method') || ''}
              placeHolder="placeholder.choose-payment-method"
              Icon={IconCreditCard}
              value={String(formData.paymentMethodId)}
              options={listPaymentMethod.map((method) => ({
                label: showData(String(method.name)),
                value: String(method.id),
              }))}
              onChange={handleChangeFormData('paymentMethodId')}
              errorMessage={formDataError.paymentMethodId}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

export default React.memo(PaymentDepositForm);
