import { PERMISSION } from 'constants/permission';
import TransactionTypeTabContainer from 'containers/management/tab/TransactionTypeTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const TransactionTypeTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_TRANSACTION_TYPE]}>
      <TransactionTypeTabContainer />
    </PermissionPageWrapper>
  );
};

export default TransactionTypeTab;
