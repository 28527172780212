import React, { useEffect, useRef, useState } from 'react';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Card, Box, Typography, IconButton, Collapse, Tooltip } from '@mui/material';
import { showData } from 'utils/common';
import { IconChevronDown } from '@tabler/icons-react';
import { Variant } from '@mui/material/styles/createTypography';

interface Props {
  sectionName?: string;
  sectionPrice?: string;
  children?: React.ReactNode;
  isActive?: boolean;
  onClickOpen?: () => void;
  headerButton?: React.ReactNode;
  bodySx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  variant?: Variant;
  fontWeight?: number;
  disableButton?: boolean;
}

function SectionCollapse({
  headerButton,
  sectionName,
  sectionPrice,
  children,
  isActive,
  onClickOpen,
  bodySx,
  titleSx,
  variant = 'h5',
  fontWeight,
  disableButton = false,
}: Props) {
  const theme = useTheme();
  const textElementRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const compareSize = () => {
    const element = textElementRef.current;
    const compare = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;

    setIsOverflow(compare);
  };

  useEffect(() => {
    compareSize();
  }, []);

  return (
    <Card
      sx={{
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        p: 0,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="10px 15px"
        borderRadius={0}
        onClick={onClickOpen}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            bgcolor: disableButton ? null : theme.palette.info.light,
          },
          ...titleSx,
        }}
      >
        <Tooltip placement="top" title={showData(sectionName)} disableHoverListener={!isOverflow}>
          <Typography
            ref={textElementRef}
            variant={variant}
            whiteSpace="nowrap"
            width="calc(100% - 80px)"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="left"
            fontWeight={fontWeight}
          >
            {showData(sectionName)}
          </Typography>
        </Tooltip>
        <Box display="flex" alignItems="center" gap="12px">
          <Typography
            ref={textElementRef}
            variant={variant}
            fontWeight={fontWeight}
            whiteSpace="nowrap"
          >
            {sectionPrice ? showData(sectionPrice) : null}
          </Typography>
          {headerButton}
          {!disableButton ? (
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{
                transform: `rotate(${isActive ? 180 : 0}deg)`,
                transition: '0.3s',
              }}
            >
              <IconChevronDown />
            </IconButton>
          ) : (
            <Box padding="15px 17px" />
          )}
        </Box>
      </Box>
      <Collapse in={isActive} sx={{ ...bodySx }}>
        {children}
      </Collapse>
    </Card>
  );
}

export default React.memo(SectionCollapse);
