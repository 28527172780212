import React, { useEffect, useRef, useState } from 'react';

import AddActionCard from 'components/card/AddActionCard';
import VehicleCard from 'components/card/VehicleCard';
import VehicleDrawer from 'components/drawers/VehicleDrawer';
import ConfirmModal from 'components/modals/ConfirmModal';
import VehicleModal from 'components/modals/VehicleModal';
import { EMPTY_CONTENT } from 'constants/common';
import { useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { createVehicle, deleteVehicle, updateVehicle } from 'services/user/vehicle.service';
import { customizerSelector } from 'store/customizer';
import { generalSelector } from 'store/general';
import { getListVehicleTypeAction } from 'store/general/actions/vehicleType.action';
import { propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { getListVehiclePagingAction, vehicleSelector } from 'store/vehicle';
import { CustomResponseType } from 'types/common.type';
import { GetListVehicleRes, VehicleTypeRes } from 'types/vehicle.type';
import { compareObject, isAllow, isSuccessCode } from 'utils/common';

import { Grid } from '@mui/material';
import { IconSteeringWheel } from '@tabler/icons-react';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

const initFormVehicleData = {
  name: '',
  vehicleTypeId: '',
  licensePlate: '',
  isActive: true,
};

const VehicleTabContainer = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { isLanguage } = useSelector(customizerSelector);
  const dispatch = useDispatch();
  const cache = useRef<VehicleTypeRes>();
  const { listVehiclePaging } = useSelector(vehicleSelector);
  const { listVehicleType } = useSelector(generalSelector);
  const { buildingId } = useSelector(propertySelector);

  const [vehicle, setVehicle] = useState(initFormVehicleData);
  const [selectedVehicleId, setSelectedVehicleId] = useState<number | undefined>();
  const [vehicleEdit, setVehicleEdit] = useState<GetListVehicleRes>(initFormVehicleData);
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
  const {
    isOpen: isOpenVehicleModal,
    onOpen: onOpenVehicleModal,
    onClose: onCloseVehicleModal,
  } = useDisclosure();
  const {
    isOpen: isOpenVehicle,
    onOpen: onOpenVehicleEdit,
    onClose: onCloseVehicle,
  } = useDisclosure();

  useEffect(() => {
    dispatch(
      getListVehiclePagingAction({
        userId: Number(clientId),
      }),
    );
    dispatch(getListVehicleTypeAction());
  }, [clientId, dispatch]);

  const handleChangeVehicle = (key: string, value: string | boolean) => {
    setVehicle((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCreateVehicle = async () => {
    const response = await createVehicle({
      buildingId,
      userId: clientId,
      name: vehicle.name,
      vehicleTypeId: vehicle.vehicleTypeId,
      licensePlate: vehicle.licensePlate,
      isActive: vehicle.isActive,
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getListVehiclePagingAction({
          userId: Number(clientId),
        }),
      );
      handleCloseModal();
    } else {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    onCloseVehicleModal();
    setVehicle(initFormVehicleData);
  };
  const handleOpenVehicleDrawer = (id: number) => () => {
    const vehicle = listVehiclePaging.data.find((type) => type.id === id);
    if (vehicle) {
      const dataInit = {
        name: vehicle.name,
        vehicleTypeId: vehicle.vehicleTypeId,
        licensePlate: vehicle.licensePlate,
        isActive: vehicle.isActive,
      };
      setVehicleEdit(dataInit);
      cache.current = dataInit;
      setSelectedVehicleId(id);
    }
    onOpenVehicleEdit();
  };
  const handleChangeVehicleEdit = (key: string, value: string | boolean) => {
    setVehicleEdit((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleUpdateVehicle = async () => {
    const vehicle = listVehiclePaging.data.find((t) => t.id === selectedVehicleId);
    let vehicleFilter = {};
    if (vehicle) {
      const { isActive, name, vehicleTypeId, licensePlate } = vehicle;
      vehicleFilter = {
        isActive: isActive,
        name: name,
        vehicleTypeId: vehicleTypeId,
        licensePlate: licensePlate,
      };
    }
    if (!compareObject(vehicleFilter, vehicleEdit)) {
      const response = await updateVehicle({
        id: selectedVehicleId,
        isActive: vehicleEdit.isActive,
        name: vehicleEdit.name,
        vehicleTypeId: vehicleEdit.vehicleTypeId,
        licensePlate: vehicleEdit.licensePlate,
      });
      const dataRes = response.data as CustomResponseType;
      if (isSuccessCode(dataRes.code)) {
        dispatch(
          getListVehiclePagingAction({
            userId: Number(clientId),
          }),
        );
        onCloseVehicle();
      }
    } else {
      onCloseVehicle();
    }
  };

  const handleOpenConfirmVehicle = (vehicleId: number | undefined) => () => {
    setSelectedVehicleId(vehicleId);
    onOpenConfirm();
  };
  const handleDeleteVehicle = async () => {
    const response = await deleteVehicle({
      vehicleId: selectedVehicleId,
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getListVehiclePagingAction({
          userId: Number(clientId),
        }),
      );
      onCloseConfirm();
    }
    onCloseConfirm();
  };

  return (
    <Grid container spacing={2}>
      {listVehiclePaging.data?.map((ele, idx) => {
        return (
          <Grid key={idx} item xs={12} md={6} lg={4}>
            <VehicleCard
              name={ele.name}
              vehicleType={
                t(
                  String(isLanguage === 'vi' ? ele.vehicleType?.nameVi : ele.vehicleType?.nameEn),
                ) || EMPTY_CONTENT
              }
              licensePlate={ele.licensePlate}
              isActive={Boolean(ele.isActive)}
              onClick={handleOpenVehicleDrawer(Number(ele.id))}
              onDelete={handleOpenConfirmVehicle(ele.id)}
              Icon={IconSteeringWheel}
            />
          </Grid>
        );
      })}

      <PermissionRender permission={[PERMISSION.CREATE_VEHICLE]}>
        <Grid item xs={12} md={6} lg={4}>
          <AddActionCard label={t('add-vehicle') || ''} onClick={onOpenVehicleModal} />
        </Grid>
      </PermissionRender>
      <VehicleDrawer
        data={vehicleEdit}
        onChange={handleChangeVehicleEdit}
        onClose={onCloseVehicle}
        isOpen={isOpenVehicle}
        onSubmit={handleUpdateVehicle}
        listVehicleType={listVehicleType}
        isDisable={!isAllow([PERMISSION.UPDATE_VEHICLE])}
      />
      <ConfirmModal
        onSubmit={handleDeleteVehicle}
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
      />
      <VehicleModal
        data={vehicle}
        onChange={handleChangeVehicle}
        onSubmit={handleCreateVehicle}
        onClose={handleCloseModal}
        isOpen={isOpenVehicleModal}
        listVehicleType={listVehicleType}
      />
    </Grid>
  );
};

export default VehicleTabContainer;
