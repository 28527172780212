import React, { useRef } from 'react';

import VehicleForm from 'components/forms/VehicleForm';
import DrawerWrapper from 'components/shared/DrawerWrapper';
import { ModalActionType } from 'types/common.type';
import { VehicleTypeRes } from 'types/vehicle.type';

type VehicleType = {
  name?: string;
  vehicleTypeId?: string;
  licensePlate?: string;
  isActive?: boolean;
};

interface Props extends ModalActionType {
  data: VehicleType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  listVehicleType?: VehicleTypeRes[];
  isDisable?: boolean;
}

const VehicleDrawer = ({
  data,
  isOpen,
  listVehicleType = [],
  onClose,
  onChange,
  onSubmit,
  isDisable,
}: Props) => {
  const submitRef = useRef<{ handleSubmitVehicle: () => void }>();

  const handleSubmitVehicle = () => {
    submitRef.current?.handleSubmitVehicle();
  };

  const onCloseVehicle = () => {
    onClose && onClose();
  };

  return (
    <DrawerWrapper
      onSubmit={handleSubmitVehicle}
      title={'vehicle-information'}
      isOpen={isOpen}
      onClose={onCloseVehicle}
      isDisable={isDisable}
    >
      <VehicleForm
        isColumn
        data={data}
        onChange={onChange}
        ref={submitRef}
        onSubmit={onSubmit}
        listVehicleType={listVehicleType}
        isDisable={isDisable}
      />
    </DrawerWrapper>
  );
};

export default React.memo(VehicleDrawer);
