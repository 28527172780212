import React, { useRef } from 'react';
import DialogWrapper from 'components/dialog/DialogWrapper';
import { useTranslation } from 'react-i18next';
import { ModalActionType } from 'types/common.type';
import { FloorNameType } from 'types/floor.type';
import FloorForm from 'components/forms/FloorForm';

interface Props extends ModalActionType {
  data: FloorNameType;
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
}

const FloorModal = ({ data, isOpen, onClose, onChange, onSubmit }: Props) => {
  const { t } = useTranslation();
  const submitRef = useRef<{ handleSubmitFloor: () => void }>();

  const handleSubmitFloor = () => {
    submitRef.current?.handleSubmitFloor();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DialogWrapper
      title={String(t('create-floor'))}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitFloor}
      isFullWidth={false}
    >
      <FloorForm ref={submitRef} data={data} onChange={onChange} onSubmit={onSubmit} />
    </DialogWrapper>
  );
};

export default React.memo(FloorModal);
