import { CustomResponseType } from 'types/common.type';
import {
  GetImageList,
  GetKanbanList,
  GetKanbanTab,
  GetKanbanTask,
  KanbanTask,
  LabelTaskType,
  TaskComment,
  TaskHistory,
} from 'types/maintenance.type';
import { isAllow, isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getKanbanListAction,
  getKanbanTabsAction,
  getKanbanTaskCommentAction,
  getKanbanTaskDetailAction,
  getKanbanTaskHistoryAction,
  getKanbanTasksAction,
  getLabelTaskListAction,
  uploadFileCommentAction,
} from './actions';
import { PERMISSION } from 'constants/permission';

interface KanbanState {
  maintenanceKanban: GetKanbanList;
  kanbanTabs: GetKanbanTab[];
  kanbanTasks: GetKanbanTask[];
  taskDetail: KanbanTask;
  taskHistory: TaskHistory[];
  taskComment: TaskComment[];
  taskId: number;
  images: GetImageList[];
  labelTaskList: LabelTaskType[];
}
const initialState: KanbanState = {
  maintenanceKanban: {},
  kanbanTabs: [],
  kanbanTasks: [],
  taskDetail: {},
  taskHistory: [],
  taskComment: [],
  taskId: 0,
  images: [],
  labelTaskList: [],
};
export const kanbanSlice = createSlice({
  name: 'kanban',
  reducers: {
    setTask: (state, actions) => {
      state.taskId = actions.payload;
    },
    clearImage: (state, actions) => {
      if (actions.payload !== undefined) {
        state.images.splice(actions.payload, 1);
      } else {
        state.images = initialState.images;
      }
    },
  },
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<KanbanState>) => {
    builder.addCase(
      getKanbanListAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.maintenanceKanban = action.payload.data;
        }
      },
    );
    builder.addCase(
      getKanbanTabsAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isAllow([PERMISSION.SB_MAINTENANCE])) {
          if (isSuccessCode(action.payload.code)) {
            state.kanbanTabs = action.payload.data;
          }
        }
      },
    );
    builder.addCase(
      getKanbanTasksAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isAllow([PERMISSION.SB_MAINTENANCE])) {
          if (isSuccessCode(action.payload.code)) {
            state.kanbanTasks = action.payload.data;
          }
        }
      },
    );
    builder.addCase(
      getKanbanTaskDetailAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.taskDetail = action.payload.data;
        }
      },
    );
    builder.addCase(
      getKanbanTaskHistoryAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.taskHistory = action.payload.data;
        }
      },
    );
    builder.addCase(
      getKanbanTaskCommentAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.taskComment = action.payload.data;
        }
      },
    );
    builder.addCase(
      uploadFileCommentAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        state.images = state.images.concat(action.payload.data);
      },
    );
    builder.addCase(
      getLabelTaskListAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isAllow([PERMISSION.SB_MAINTENANCE]) && isAllow([PERMISSION.SB_MAINTENANCE])) {
          if (isSuccessCode(action.payload.code)) {
            state.labelTaskList = action.payload.data;
          }
        }
      },
    );
  },
});

export const { setTask, clearImage } = kanbanSlice.actions;
export default kanbanSlice.reducer;
