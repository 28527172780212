import React, { useRef } from 'react';

import RoomForm from 'components/forms/RoomForm';
import DrawerWrapper from 'components/shared/DrawerWrapper';
import { ModalActionType } from 'types/common.type';
import { FloorRes, RoomTypeRes } from 'types/general.type';
import { RoomType } from 'types/room.type';

interface Props extends ModalActionType {
  data: RoomType;
  listRoomTypeOptions?: RoomTypeRes[];
  listFloorNameOptions?: FloorRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
  isEdit?: boolean;
  isDisable?: boolean;
}

const RoomDrawer = ({
  data,
  isOpen,
  listRoomTypeOptions = [],
  listFloorNameOptions = [],
  onClose,
  onChange,
  onSubmit,
  isColumn,
  isEdit = false,
  isDisable = false,
}: Props) => {
  const submitRef = useRef<{ handleSubmitRoom: () => void }>();

  const handleSubmitRoom = () => {
    submitRef.current?.handleSubmitRoom();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DrawerWrapper
      title={'room-information'}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitRoom}
      isDisable={isDisable}
    >
      <RoomForm
        data={data}
        listRoomTypeOptions={listRoomTypeOptions}
        listFloorNameOptions={listFloorNameOptions}
        onChange={onChange}
        ref={submitRef}
        isColumn={isColumn}
        onSubmit={onSubmit}
        isHideFloorField
        isEdit={isEdit}
        isDisable={isDisable}
      />
    </DrawerWrapper>
  );
};

export default React.memo(RoomDrawer);
