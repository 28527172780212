import React from 'react';

import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomSwitch = styled((props: SwitchProps) => <Switch {...props} />)(({}) => ({
  '&.MuiSwitch-root': {
    width: '68px',
    height: '49px',
    margin: '-12px',
  },
  '&  .MuiButtonBase-root': {
    top: '6px',
    left: '6px',
  },
  '&  .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb': {
    backgroundColor: 'primary.main',
  },
  '& .MuiSwitch-thumb': {
    width: '18px',
    height: '18px',
    borderRadius: '6px',
  },

  '& .MuiSwitch-track': {
    backgroundColor: 'var(--gray-03)',
    opacity: 1,
    borderRadius: '5px',
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: 'primary',
        opacity: 0.18,
      },
    },
  },
}));

export default React.memo(CustomSwitch);
