import { createAsyncThunk } from '@reduxjs/toolkit';
import { PERMISSION } from 'constants/permission';
import { getBranchDetail, getBranchOverview } from 'services/property';
import { isAllow } from 'utils/common';

export const getBranchDetailAction = createAsyncThunk(
  'property/getBranchDetailAction',
  async () => {
    const response = await getBranchDetail();

    return response.data;
  },
);

export const getBranchOverviewAction = createAsyncThunk(
  'property/getBranchOverviewAction',
  async () => {
    if (isAllow([PERMISSION.SB_MANAGEMENT])) {
      const response = await getBranchOverview();

      return response.data;
    }
  },
);
