import React, { useEffect, useRef, useState } from 'react';

import ClipboardJS from 'clipboard';
import { useTranslation } from 'react-i18next';

import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { IconClipboardCheck, IconClipboard } from '@tabler/icons-react';

interface CopyOnHoverProps {
  text: string | number;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));

const CopyOnHover: React.FC<CopyOnHoverProps> = ({ text }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [isTypeValid, setIsTypeValid] = useState(false);

  const handleOffCopied = () => {
    setIsCopied(false);
  };

  useEffect(() => {
    if (textRef.current) {
      const isTextOrNumber = typeof text === 'string' || typeof text === 'number';

      if (isTextOrNumber) {
        setIsTypeValid(true);
        const clipboard = new ClipboardJS(textRef.current);

        clipboard.on('success', (e) => {
          setIsCopied(true);
          e.clearSelection();
        });

        return () => {
          clipboard.destroy();
        };
      }
    }
  }, [isCopied, text]);

  return isCopied ? (
    <LightTooltip
      title={
        isTypeValid && (
          <Box display="flex" alignItems="center" gap="3px">
            <IconClipboardCheck width="18px" />
            <Typography variant="caption">{t('copied')}</Typography>
          </Box>
        )
      }
      placement="top"
      onMouseLeave={handleOffCopied}
    >
      <Typography
        ref={textRef as React.RefObject<HTMLDivElement>}
        data-clipboard-text={String(text)}
        style={{ cursor: 'pointer' }}
      >
        {text}
      </Typography>
    </LightTooltip>
  ) : (
    <Tooltip
      title={
        isTypeValid && (
          <Box display="flex" alignItems="center" gap="3px">
            <IconClipboard width="18px" />
            <Typography variant="caption">{t('copy')}</Typography>
          </Box>
        )
      }
      placement="top"
    >
      <Typography
        ref={textRef as React.RefObject<HTMLDivElement>}
        data-clipboard-text={String(text)}
        style={{ cursor: 'pointer' }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default React.memo(CopyOnHover);
