/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef } from 'react';

import ConfirmModal from 'components/modals/ConfirmModal';
import DepositTransactionChip from 'components/shared/DepositTransactionChip';
import TransactionChip from 'components/shared/TransactionChip';
import TableNormal from 'components/table/TableNormal';
import { DATE_TIME_FORMAT } from 'constants/common';
import { PERMISSION } from 'constants/permission';
import { ROUTES } from 'constants/router';
import { TRANSACTION_STATUS } from 'enums';
import { useDirection, useDisclosure } from 'hooks';
import usePagination from 'hooks/usePagination';
import PermissionRender from 'layouts/permission/PermissionRender';
import { isEmpty, uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { deleteDepositTransaction, deleteTransaction } from 'services/user/transaction.service';
import { bookingSelector, getCurrentBookingInRoomAction, resetSelectedId } from 'store/booking';
import { getDepositTransactionInBookingAction, invoiceSelector } from 'store/invoice';
import { useDispatch, useSelector } from 'store/Store';
import {
  getListTransactionInContractPagingAction,
  resetListTransactionInContractPaging,
  transactionSelector,
} from 'store/transaction';
import { formatDate, formatPrice, isAllow, isSuccessCode, showData } from 'utils/common';

import { Box, Button, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router';

const TRANSACTION_TABLE_HEADER = [
  'transaction-code',
  'status',
  'invoice-code',
  'transaction-type',
  'collect',
  'expenses',
  'payment-method',
  'creation-date',
  'executor',
  'payer',
  isAllow([PERMISSION.DELETE_TRANSACTION]) ? 'action' : '',
];

const DEPOSIT_TRANSACTION_TABLE_HEADER = [
  'transaction-code',
  'invoice-code',
  'status',
  'collect',
  'expenses',
  'payment-method',
  'creation-date',
  'executor',
  'payer',
  isAllow([PERMISSION.DELETE_DEPOSIT_TRANSACTION]) ? 'action' : '',
];

const TransactionTabContainer = () => {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const dispatch = useDispatch();
  const { roomId } = useParams();
  const { listTransactionInContractPaging } = useSelector(transactionSelector);
  const { depositTransactionInBooking } = useSelector(invoiceSelector);
  const { currentBookingInRoom, selectedBookingId } = useSelector(bookingSelector);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const transactionIdRef = useRef<string | number>('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenDeleteDeposit,
    onClose: onCloseDeleteDeposit,
    onOpen: onOpenDeleteDeposit,
  } = useDisclosure();

  // NOTE: fetch api init
  // TODO: change to call api by booking
  useEffect(() => {
    if (currentBookingInRoom.currentBooking?.id || selectedBookingId) {
      dispatch(
        getListTransactionInContractPagingAction({
          bookingId:
            selectedBookingId !== 0 ? selectedBookingId : currentBookingInRoom.currentBooking?.id,
          limit: rowsPerPage,
          page: currentPage,
        }),
      );
      dispatch(
        getDepositTransactionInBookingAction({
          bookingId:
            selectedBookingId !== 0 ? selectedBookingId : currentBookingInRoom.currentBooking?.id,
        }),
      );
    } else {
      dispatch(resetListTransactionInContractPaging());
      dispatch(resetSelectedId());
    }
  }, [
    dispatch,
    currentBookingInRoom.currentBooking?.id,
    selectedBookingId,
    rowsPerPage,
    currentPage,
  ]);
  useEffect(() => {
    if (currentBookingInRoom.nextBooking?.id) {
      dispatch(
        getDepositTransactionInBookingAction({
          bookingId: currentBookingInRoom.nextBooking?.id,
        }),
      );
    }
  }, [currentBookingInRoom.nextBooking?.id, dispatch]);

  const handleOpenConfirm = (transactionId: string | number) => () => {
    transactionIdRef.current = transactionId;
    onOpen();
  };
  const handleDeleteTransaction = async () => {
    const response = await deleteTransaction({
      transactionId: transactionIdRef.current,
    });
    const { code } = response.data;
    if (isSuccessCode(code)) {
      dispatch(
        getListTransactionInContractPagingAction({
          bookingId: currentBookingInRoom.currentBooking?.id,
          limit: rowsPerPage,
          page: currentPage,
        }),
      );
      onClose();
    }
  };

  const transactionTableRows = useMemo(() => {
    return listTransactionInContractPaging.data.map((transaction) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link underline="none" onClick={goTo(`${ROUTES.INVOICE.LIST}/${transaction.invoiceId}`)}>
            <Typography variant="subtitle1">{showData(transaction?.name)}</Typography>
          </Link>
        </Stack>,
        <TransactionChip key={uniqueId()} status={transaction.transactionType?.type} />,
        <Link
          key={uniqueId()}
          underline="none"
          onClick={goTo(`${ROUTES.INVOICE.LIST}/${transaction.invoiceId}`)}
        >
          <Typography variant="subtitle1">{showData(transaction?.invoice?.name)}</Typography>
        </Link>,
        <Typography key={uniqueId()} color="textSecondary" variant="subtitle1" fontWeight="400">
          {showData(transaction.transactionType?.nameVi)}
        </Typography>,
        showData(
          transaction.transactionType?.type === TRANSACTION_STATUS.INCOME
            ? formatPrice(String(transaction.transactionAmount))
            : null,
        ),
        showData(
          transaction.transactionType?.type === TRANSACTION_STATUS.EXPENSE
            ? formatPrice(String(transaction.transactionAmount))
            : null,
        ),
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {showData(transaction.paymentMethod?.name)}
        </Typography>,
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.createdBy?.fullName)}</Typography>
        </Link>,
        <Link
          key={uniqueId()}
          href="#"
          underline="none"
          onClick={goTo(`${ROUTES.CLIENT.LIST}/${transaction?.paidUserId}`)}
        >
          <Typography variant="subtitle1">{showData(transaction.paidUser?.fullName)}</Typography>
        </Link>,
        <PermissionRender key={transaction.id} permission={[PERMISSION.DELETE_TRANSACTION]}>
          <Box display="flex" justifyContent="center" gap="10px">
            <Tooltip placement="top" title={t('cancel')}>
              <Button
                variant="contained"
                color="error"
                onClick={handleOpenConfirm(String(transaction.id))}
                disabled={selectedBookingId !== currentBookingInRoom.currentBooking?.id}
              >
                {t('delete')}
              </Button>
            </Tooltip>
          </Box>
        </PermissionRender>,
      ];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBookingInRoom.currentBooking?.id,
    listTransactionInContractPaging.data,
    selectedBookingId,
    t,
  ]);
  const handleOpenConfirmDeleteDeposit = (transactionId: string | number) => () => {
    transactionIdRef.current = transactionId;
    onOpenDeleteDeposit();
  };
  const handleDeleteDepositTransaction = async () => {
    const response = await deleteDepositTransaction({
      depositTransactionId: transactionIdRef.current,
    });
    const { code } = response.data;
    if (isSuccessCode(code)) {
      dispatch(
        getDepositTransactionInBookingAction({
          bookingId:
            selectedBookingId !== 0
              ? selectedBookingId
              : currentBookingInRoom.currentBooking?.id ?? currentBookingInRoom.nextBooking?.id,
        }),
      );
      dispatch(
        getCurrentBookingInRoomAction({
          roomId,
        }),
      );
      onCloseDeleteDeposit();
    }
  };

  const depositTransactionTableRows = useMemo(() => {
    return depositTransactionInBooking.data.map((transaction) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link href="#" underline="none">
            <Typography color="textSecondary" variant="subtitle1">
              {showData(transaction.id)}
            </Typography>
          </Link>
        </Stack>,
        showData(transaction.depositInvoiceId),
        <DepositTransactionChip key={uniqueId()} status={transaction.status} />,
        showData(transaction.isDeposit ? formatPrice(String(transaction.transactionAmount)) : null),
        showData(
          !transaction.isDeposit ? `-${formatPrice(String(transaction.transactionAmount))}` : null,
        ),
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {showData(transaction.paymentMethod?.name)}
        </Typography>,
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.createdBy?.fullName)}</Typography>
        </Link>,
        <Link
          key={uniqueId()}
          onClick={goTo(`${ROUTES.CLIENT.LIST}/${transaction.paidUserId}`)}
          underline="none"
        >
          <Typography variant="subtitle1">{showData(transaction.paidUser?.fullName)}</Typography>
        </Link>,
        <PermissionRender key={transaction.id} permission={[PERMISSION.DELETE_DEPOSIT_TRANSACTION]}>
          <Box display="flex" justifyContent="center" gap="10px">
            <Tooltip placement="top" title={t('cancel')}>
              <Button
                variant="contained"
                color="error"
                onClick={handleOpenConfirmDeleteDeposit(String(transaction.id))}
                disabled={
                  selectedBookingId !== 0 &&
                  selectedBookingId !== currentBookingInRoom.currentBooking?.id
                }
              >
                {t('delete')}
              </Button>
            </Tooltip>
          </Box>
        </PermissionRender>,
      ];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBookingInRoom.currentBooking?.id,
    depositTransactionInBooking.data,
    selectedBookingId,
    goTo,
    t,
  ]);

  return (
    <>
      <Box>
        <Typography variant="h5" py={1}>
          {t('invoice-transaction')}
        </Typography>
        <TableNormal
          header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
          data={transactionTableRows}
          total={listTransactionInContractPaging.total}
          limit={rowsPerPage}
          currentPage={currentPage}
          onChangePage={handleChangeCurrentPage}
          onChangeLimit={handleChangeLimit}
          isAction={isAllow([PERMISSION.DELETE_TRANSACTION])}
        />
      </Box>
      <Box mt={2}>
        <Typography variant="h5" py={1}>
          {t('deposit-transaction')}
        </Typography>
        <TableNormal
          header={DEPOSIT_TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
          data={depositTransactionTableRows}
          isAction={isAllow([PERMISSION.DELETE_DEPOSIT_TRANSACTION])}
        />
      </Box>
      <ConfirmModal isOpen={isOpen} onClose={onClose} onSubmit={handleDeleteTransaction} />
      <ConfirmModal
        isOpen={isOpenDeleteDeposit}
        onClose={onCloseDeleteDeposit}
        onSubmit={handleDeleteDepositTransaction}
      />
    </>
  );
};

export default TransactionTabContainer;
