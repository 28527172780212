import React, { lazy } from 'react';

import { ROUTES } from 'constants/router';
import Loadable from 'layouts/full/shared/loadable/Loadable';
import { Navigate } from 'react-router-dom';
import BookingTab from 'views/client/tab/BookingTab';
import ContractTab from 'views/client/tab/ContractTab';
import InvoiceTab from 'views/client/tab/InvoiceTab';
import TransactionTab from 'views/client/tab/TransactionTab';
import VehicleTab from 'views/client/tab/VehicleTab';
import FloorAndRoomTab from 'views/management/tab/FloorAndRoomTab';
import RoomTypeTab from 'views/management/tab/RoomTypeTab';
import ServiceTab from 'views/management/tab/ServiceTab';
import TransactionTypeTab from 'views/management/tab/TransactionTypeTab';
import InvoiceInRoomTab from 'views/room/tab/InvoiceTab';
import ServiceInRoomTab from 'views/room/tab/ServiceTab';
import TransactionInRoomTab from 'views/room/tab/TransactionTab';
import PaymentMethodTab from 'views/management/tab/PaymentMethodTab';
import CommentTab from 'views/maintenance/tab/CommentTab';
import HistoryTab from 'views/maintenance/tab/HistoryTab';
import ApproachingExpiredPage from 'views/report/ApproachingExpiredPage';
import CheckinReportPage from 'views/report/CheckinReportPage';
import TransactionReportPage from 'views/report/transaction-report/TransactionReportPage';
import InvoiceTransactionTab from 'views/report/transaction-report/tab/InvoiceTransactionTab';
import DepositTransactionTab from 'views/report/transaction-report/tab/DepositTransactionTab';
import OtherTransactionTab from 'views/report/transaction-report/tab/OtherTransactionTab';
import DebtTransactionTab from 'views/report/transaction-report/tab/DebtTransactionTab';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('layouts/blank/BlankLayout')));

/* ****Pages***** */
const DashboardPage = Loadable(lazy(() => import('views/home/DashboardPage')));
const ListClientPage = Loadable(lazy(() => import('views/client/ListClientPage')));
const AddNewClientPage = Loadable(lazy(() => import('views/client/AddNewClientPage')));
const ListEmployeePage = Loadable(lazy(() => import('views/employee/ListEmployeePage')));
const AddNewEmployeePage = Loadable(lazy(() => import('views/employee/AddNewEmployeePage')));
const LoginPage = Loadable(lazy(() => import('views/auth/LoginPage')));
const NewPasswordPage = Loadable(lazy(() => import('views/auth/NewPasswordPage')));
const ForgotPasswordPage = Loadable(lazy(() => import('views/auth/ForgotPasswordPage')));
const VerifyForgotPage = Loadable(lazy(() => import('views/auth/VerifyForgotPage')));
const RoomListPage = Loadable(lazy(() => import('views/room/RoomListPage')));
const RoomDetailPage = Loadable(lazy(() => import('views/room/RoomDetailPage')));
const ClientDetailPage = Loadable(lazy(() => import('views/client/ClientDetailPage')));
const BranchInformationPage = Loadable(
  lazy(() => import('views/management/BranchInformationPage')),
);
const BuildingDetailPage = Loadable(lazy(() => import('views/management/BuildingDetailPage')));
const RoomBookingPage = Loadable(lazy(() => import('views/room/RoomBookingPage')));
const ListContractPage = Loadable(lazy(() => import('views/contract/ListContractPage')));
const ContractFormPage = Loadable(lazy(() => import('views/contract/ContractFormPage')));
const ContractDetailPage = Loadable(lazy(() => import('views/contract/ContractDetailPage')));
const InvoicePage = Loadable(lazy(() => import('views/payment/invoice/InvoicePage')));
const InvoiceDetailPage = Loadable(lazy(() => import('views/payment/invoice/InvoiceDetailPage')));
const MaintenancePage = Loadable(lazy(() => import('views/maintenance/MaintenancePage')));
const AddMultipleInvoicePage = Loadable(
  lazy(() => import('views/payment/invoice/AddMultipleInvoicePage')),
);
const ManualPage = Loadable(lazy(() => import('views/manual/ManualPage')));

/* ****Error Pages***** */
const Error404Page = Loadable(lazy(() => import('views/error/Error404Page')));
const Error403Page = Loadable(lazy(() => import('views/error/Error403Page')));

const Router = [
  {
    path: ROUTES.HOME,
    element: <FullLayout />,
    children: [
      { path: ROUTES.HOME, element: <Navigate to={ROUTES.DASHBOARDS} /> },
      { path: ROUTES.DASHBOARDS, exact: true, element: <DashboardPage /> },

      // NOTE: room route
      { path: ROUTES.ROOM.LIST, exact: true, element: <RoomListPage /> },
      {
        path: ROUTES.ROOM.DETAIL,
        exact: true,
        element: <RoomDetailPage />,
        children: [
          {
            path: ROUTES.ROOM.DETAIL_TAB.INVOICE,
            element: <InvoiceInRoomTab />,
          },
          {
            path: ROUTES.ROOM.DETAIL_TAB.TRANSACTION,
            element: <TransactionInRoomTab />,
          },
          {
            path: ROUTES.ROOM.DETAIL_TAB.SERVICE,
            element: <ServiceInRoomTab />,
          },
        ],
      },
      { path: ROUTES.ROOM.BOOKING, exact: true, element: <RoomBookingPage /> },

      // NOTE: maintenance route
      {
        path: ROUTES.MAINTENANCE.LIST,
        exact: true,
        element: <MaintenancePage />,
        children: [
          {
            path: ROUTES.MAINTENANCE.MAINTENANCE_TAB.COMMENT_MAINTENANCE,
            element: <CommentTab />,
          },
          {
            path: ROUTES.MAINTENANCE.MAINTENANCE_TAB.HISTORY_MAINTENANCE,
            element: <HistoryTab />,
          },
        ],
      },

      // NOTE: report
      {
        path: ROUTES.REPORT.APPROACHING_EXPIRED_REPORT,
        exact: true,
        element: <ApproachingExpiredPage />,
      },
      {
        path: ROUTES.REPORT.CHECKIN_REPORT,
        exact: true,
        element: <CheckinReportPage />,
      },
      {
        path: ROUTES.REPORT.TRANSACTION_REPORT,
        exact: true,
        element: <TransactionReportPage />,
        children: [
          {
            path: ROUTES.REPORT.TRANSACTION_TAB.INVOICE_TRANSACTION,
            element: <InvoiceTransactionTab />,
          },
          {
            path: ROUTES.REPORT.TRANSACTION_TAB.DEPOSIT_TRANSACTION,
            element: <DepositTransactionTab />,
          },
          {
            path: ROUTES.REPORT.TRANSACTION_TAB.OTHER_TRANSACTION,
            element: <OtherTransactionTab />,
          },
          {
            path: ROUTES.REPORT.TRANSACTION_TAB.DEBT_TRANSACTION,
            element: <DebtTransactionTab />,
          },
        ],
      },

      // NOTE: client route
      { path: ROUTES.CLIENT.LIST, exact: true, element: <ListClientPage /> },
      { path: ROUTES.CLIENT.ADD, exact: true, element: <AddNewClientPage /> },
      {
        path: ROUTES.CLIENT.DETAIL,
        exact: true,
        element: <ClientDetailPage />,
        children: [
          {
            path: ROUTES.CLIENT.DETAIL_TAB.BOOKING,
            element: <BookingTab />,
          },
          {
            path: ROUTES.CLIENT.DETAIL_TAB.CONTRACT,
            element: <ContractTab />,
          },
          {
            path: ROUTES.CLIENT.DETAIL_TAB.INVOICE,
            element: <InvoiceTab />,
          },
          {
            path: ROUTES.CLIENT.DETAIL_TAB.TRANSACTION,
            element: <TransactionTab />,
          },
          {
            path: ROUTES.CLIENT.DETAIL_TAB.VEHICLE,
            element: <VehicleTab />,
          },
        ],
      },

      // NOTE: employee route
      { path: ROUTES.EMPLOYEE.LIST, exact: true, element: <ListEmployeePage /> },
      { path: ROUTES.EMPLOYEE.ADD, exact: true, element: <AddNewEmployeePage /> },

      // NOTE: contract route
      { path: ROUTES.CONTRACT.LIST, exact: true, element: <ListContractPage /> },
      { path: ROUTES.CONTRACT.FORM, exact: true, element: <ContractFormPage /> },
      { path: ROUTES.CONTRACT.DETAIL, exact: true, element: <ContractDetailPage /> },

      // NOTE: payment route
      { path: ROUTES.INVOICE.LIST, exact: true, element: <InvoicePage /> },
      { path: ROUTES.INVOICE.INVOICE_ADD, exact: true, element: <AddMultipleInvoicePage /> },
      { path: ROUTES.INVOICE.INVOICE_DETAIL, element: <InvoiceDetailPage /> },

      // NOTE: management route
      {
        path: ROUTES.MANAGEMENT.BRANCH_INFORMATION,
        element: <BranchInformationPage />,
      },
      {
        path: ROUTES.MANAGEMENT.BUILDING_DETAIL,
        element: <BuildingDetailPage />,
        children: [
          {
            path: ROUTES.MANAGEMENT.BUILDING_DETAIL_TAB.FLOOR_ROOM,
            element: <FloorAndRoomTab />,
          },
          {
            path: ROUTES.MANAGEMENT.BUILDING_DETAIL_TAB.ROOM_TYPE,
            element: <RoomTypeTab />,
          },
          {
            path: ROUTES.MANAGEMENT.BUILDING_DETAIL_TAB.SERVICE,
            element: <ServiceTab />,
          },
          {
            path: ROUTES.MANAGEMENT.BUILDING_DETAIL_TAB.TRANSACTION_TYPE,
            element: <TransactionTypeTab />,
          },
          {
            path: ROUTES.MANAGEMENT.BUILDING_DETAIL_TAB.PAYMENT_METHOD,
            element: <PaymentMethodTab />,
          },
        ],
      },

      //NOTE: manual
      {
        path: ROUTES.MANUAL,
        element: <ManualPage />,
      },

      { path: '*', element: <Navigate to={ROUTES.ERROR.NOT_FOUND} /> },
    ],
  },
  {
    path: ROUTES.HOME,
    element: <BlankLayout />,
    children: [
      // NOTE: auth route
      { path: ROUTES.AUTH.LOGIN, element: <LoginPage /> },
      { path: ROUTES.AUTH.FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
      { path: ROUTES.AUTH.VERIFY_FORGOT, element: <VerifyForgotPage /> },
      { path: ROUTES.AUTH.NEW_PASSWORD, element: <NewPasswordPage /> },

      // NOTE: error route
      { path: ROUTES.ERROR.NOT_FOUND, element: <Error404Page /> },
      { path: ROUTES.ERROR.FORBIDDEN, element: <Error403Page /> },
      { path: '*', element: <Navigate to={ROUTES.ERROR.NOT_FOUND} /> },
    ],
  },
];

export default Router;
