import {
  cancelBooking,
  getCurrentBookingInRoom,
  getListBooking,
  getListBookingInBuilding,
  getListBookingInRoom,
  getOptionBookingInBuilding,
} from 'services/user/booking.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { isSuccessCode } from 'utils/common';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListBookingPagingAction = createAsyncThunk(
  'booking/getListBooking',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListBooking(payload);

    return response.data;
  },
);

export const getListBookingInBuildingPagingAction = createAsyncThunk(
  'booking/getListBookingInBuilding',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListBookingInBuilding(payload);

    return response.data;
  },
);

export const getListBookingInBuildingOptionAction = createAsyncThunk(
  'booking/getListBookingInBuildingOptionAction',
  async (payload: CommonReq) => {
    const response = await getOptionBookingInBuilding(payload);

    return response.data;
  },
);

export const getListBookingInRoomPagingAction = createAsyncThunk(
  'booking/getListBookingInRoom',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListBookingInRoom(payload);

    return response.data;
  },
);

export const getCurrentBookingInRoomAction = createAsyncThunk(
  'booking/getCurrentBookingInRoom',
  async (payload: CommonReq & PagingReq) => {
    const response = await getCurrentBookingInRoom(payload);

    return response.data;
  },
);

export const cancelBookingAction = createAsyncThunk(
  'booking/cancelBooking',
  async (payload: CommonReq) => {
    const response = await cancelBooking(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
