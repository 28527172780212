import React, { useState } from 'react';
import { useEffect, useMemo } from 'react';

import TableNormal from 'components/table/TableNormal';
import { useDirection } from 'hooks';
import usePagination from 'hooks/usePagination';
import { propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { formatDate, isAllow, parseStringToDate, showData } from 'utils/common';
import { Box, Grid, Link, Typography } from '@mui/material';
import { getListWaitingCheckInReportAction, reportSelector } from 'store/report';
import { t } from 'i18next';

import moment from 'moment';
import { IconDoorEnter, IconDoorExit } from '@tabler/icons-react';
import { DATE_FORMAT } from 'constants/common';
import BookingChip from 'components/shared/BookingChip';
import DateInput from 'components/shared/DateInput';
import { ROUTES } from 'constants/router';
import { PERMISSION } from 'constants/permission';

const dataInitial = {
  startDate: moment().startOf('month').format(DATE_FORMAT),
  endDate: moment().endOf('month').format(DATE_FORMAT),
};
const CheckinReportContainer = () => {
  const dispatch = useDispatch();
  const { buildingId } = useSelector(propertySelector);
  const { listWaitingCheckInReport } = useSelector(reportSelector);

  const { goTo } = useDirection();
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const [formData, setFormData] = useState(dataInitial);
  const handleChange = (key: string) => (value: string) => {
    if (key === 'startDate') {
      const formattedStartDate = moment(parseStringToDate(value)).format('YYYY-MM-DD');
      const formattedEndDate = moment(parseStringToDate(formData.endDate)).format('YYYY-MM-DD');
      setFormData((prev) => ({
        ...prev,
        [key]: value,
        endDate: moment(formattedStartDate).isAfter(moment(formattedEndDate))
          ? value
          : formData.endDate,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  useEffect(() => {
    //**NOTE: Init call API */
    dispatch(
      getListWaitingCheckInReportAction({
        buildingId: buildingId,
        page: currentPage,
        limit: rowsPerPage,
        startDate: moment(parseStringToDate(formData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(formData.endDate)).format('YYYY-MM-DD'),
      }),
    );
  }, [buildingId, dispatch, currentPage, rowsPerPage, formData.startDate, formData.endDate]);

  const dataTable = useMemo(() => {
    return listWaitingCheckInReport.data?.map((booking) => {
      return [
        <Link key={`link-${booking.id}`} underline="none">
          {showData(booking.id)}
        </Link>,
        <BookingChip key={`chip-${booking.id}`} status={booking?.status} />,
        showData(booking.infoRoom?.floor?.name),
        <Link
          key={`room-${booking.id}`}
          underline="none"
          onClick={goTo(
            `${ROUTES.ROOM.LIST}/${
              isAllow([PERMISSION.GET_ROOM_DETAIL]) ? booking?.infoRoom?.roomId : null
            }`,
          )}
        >
          {showData(booking.infoRoom?.room?.name)}
        </Link>,
        showData(booking.infoRoom?.roomType?.name),
        <Link
          key={`client-${booking.id}`}
          underline="none"
          onClick={goTo(
            `${ROUTES.CLIENT.LIST}/${
              isAllow([PERMISSION.GET_USER_DETAIL]) ? booking.userInfo?.userId : null
            }`,
          )}
        >
          {showData(booking.userInfo.accountDetail?.fullName)}
        </Link>,
        showData(formatDate(booking.checkInAt)),
      ];
    });
  }, [listWaitingCheckInReport.data, goTo]);

  return (
    <Box>
      <Grid container spacing={'12px'}>
        <Grid
          container
          spacing={'12px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          <Grid item xs={12} lg={4} display={'flex'} alignItems={'center'} gap={2}>
            <Grid item xs={12}>
              <DateInput
                onChange={handleChange('startDate')}
                value={String(formData.startDate)}
                Icon={IconDoorEnter}
                disablePast
              />
            </Grid>
            <Grid item xs={0}>
              <Typography>{t('to')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <DateInput
                onChange={handleChange('endDate')}
                value={String(formData.endDate)}
                Icon={IconDoorExit}
                disablePast
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableNormal
              header={[
                'booking-code',
                'status',
                'floor',
                'room',
                'room-type',
                'client',
                'check-in-date',
              ]}
              data={dataTable}
              total={listWaitingCheckInReport?.total}
              limit={rowsPerPage}
              currentPage={currentPage}
              onChangePage={handleChangeCurrentPage}
              onChangeLimit={handleChangeLimit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckinReportContainer;
