import { centralGW } from 'services/axios.service';
import { ListDistrictReq, ListProvinceReq } from 'types/general.type';

export const getListCountry = async () => {
  return centralGW.get('/api/v1/general/country', {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getListProvinceInCountry = async (payload: ListProvinceReq) => {
  const { countryId } = payload;

  return centralGW.get('/api/v1/general/province', {
    params: {
      countryId,
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const getDistrictInProvince = async (payload: ListDistrictReq) => {
  const { provinceId } = payload;

  return centralGW.get(`/api/v1/general/province/${provinceId}/district`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};
