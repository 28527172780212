import { PERMISSION } from 'constants/permission';
import ApproachingExpiredContainer from 'containers/report/ApproachingExpiredContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const ApproachingExpiredPage = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SB_TAB_APPROACHING_EXPIRED]}>
      <ApproachingExpiredContainer />
    </PermissionPageWrapper>
  );
};

export default ApproachingExpiredPage;
