import React, { useRef } from 'react';

import ContractFeeForm from 'components/forms/ContractFeeForm';
import DrawerWrapper from 'components/shared/DrawerWrapper';
import { ModalActionType } from 'types/common.type';
import { GetListServiceRes } from 'types/property.type';
import { ServiceFeeForm } from 'types/service.type';

interface Props extends ModalActionType {
  data: ServiceFeeForm;
  listServiceType?: GetListServiceRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isDisableServiceType?: boolean;
  unitTypeId?: string;
  isDisable?: boolean;
}

const ContractFeeDrawer = ({
  data,
  isOpen,
  listServiceType = [],
  onClose,
  onChange,
  onSubmit,
  isDisableServiceType,
  unitTypeId,
  isDisable = false,
}: Props) => {
  const submitRef = useRef<{ handleSubmitContractFee: () => void }>();

  const handleSubmitContractFee = () => {
    submitRef.current?.handleSubmitContractFee();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DrawerWrapper
      title={'contract-fee-information'}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitContractFee}
      isDisable={isDisable}
    >
      <ContractFeeForm
        data={data}
        listServiceType={listServiceType}
        onChange={onChange}
        ref={submitRef}
        onSubmit={onSubmit}
        isDisableServiceType={isDisableServiceType}
        unitTypeId={unitTypeId}
        isDisable={isDisable}
      />
    </DrawerWrapper>
  );
};

export default React.memo(ContractFeeDrawer);
