import React, { RefObject, useRef } from 'react';

import DialogWrapper from 'components/dialog/DialogWrapper';
import ServiceForm from 'components/forms/ServiceForm';
import { useTranslation } from 'react-i18next';
import { ModalActionType } from 'types/common.type';
import { UnitTypeRes } from 'types/general.type';
import { ServiceType } from 'types/service.type';

interface Props extends ModalActionType {
  data: ServiceType;
  listUnitTypeOptions?: UnitTypeRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
}

const ServiceModal = ({
  data,
  isOpen,
  onClose,
  onChange,
  onSubmit,
  listUnitTypeOptions = [],
}: Props) => {
  const { t } = useTranslation();

  const submitRef: RefObject<any> = useRef<{ handleSubmitService: () => void }>();

  const handleSubmitService = () => {
    submitRef.current?.handleSubmitService();
  };
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DialogWrapper
      title={String(t('create-service'))}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitService}
    >
      <ServiceForm
        data={data}
        listUnitTypeOptions={listUnitTypeOptions}
        onChange={onChange}
        ref={submitRef}
        onSubmit={onSubmit}
      />
    </DialogWrapper>
  );
};

export default React.memo(ServiceModal);
