import React, { ComponentType } from 'react';

import Logo from 'assets/images/products/vehicle.jpg';
import TextOverflow from 'components/shared/TextOverflow';
import { useTranslation } from 'react-i18next';
import { isMobile, showData } from 'utils/common';

import { Box, Button, Card, Tooltip, Typography, useTheme } from '@mui/material';
import { IconRectangle, IconTrash, TablerIconsProps } from '@tabler/icons-react';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

type Props = {
  name?: string;
  vehicleType?: string;
  licensePlate?: string;
  isActive?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  Icon: ComponentType<TablerIconsProps>;
};

function VehicleCard({
  name,
  vehicleType,
  licensePlate,
  isActive,
  Icon,
  onClick,
  onDelete,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleDeleteRoom = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <Card
      sx={{
        p: '12px',
        cursor: 'pointer',
        maxWidth: '100%',
        height: '180px',
        boxShadow: theme.shadows[5],
        '&:hover': {
          boxShadow: theme.shadows[0],
        },
        position: 'relative',
        opacity: isActive ? 1 : 0.6,
        bgcolor: isActive ? 'white' : 'var(--gray-04)',
      }}
      onClick={onClick}
    >
      <TextOverflow name={String(name)} variant="h5" sx={{ marginBottom: '10px' }} />
      <Box display="flex" alignItems="flex-start" gap="12px">
        <Box
          width="150px"
          maxHeight="120px"
          height="100%"
          borderRadius="6px"
          overflow="hidden"
          boxShadow={theme.shadows[5]}
        >
          <img alt="" width="100%" height="120px" src={Logo} style={{ objectFit: 'cover' }} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="6px"
          width={`calc(100% - ${isMobile ? 20 : 162}px)`}
        >
          <Box display="flex" alignItems="flex-start">
            <Icon width="24px" color="var(--gray-01)" />
            <Typography
              textAlign="left"
              variant="subtitle1"
              ml="6px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              width="calc(100% - 30px)"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {showData(vehicleType)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <IconRectangle width="24px" color="var(--gray-01)" />
            <Typography
              textAlign="left"
              variant="subtitle1"
              ml="6px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              width="calc(100% - 30px)"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {showData(licensePlate)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <PermissionRender permission={[PERMISSION.DELETE_VEHICLE]}>
        <Tooltip
          placement="top"
          title={t('delete-vehicle')}
          sx={{
            position: 'absolute',
            bottom: '12px',
            right: '12px',
          }}
        >
          <Button variant="outlined" color="error" onClick={handleDeleteRoom}>
            <IconTrash />
          </Button>
        </Tooltip>
      </PermissionRender>
    </Card>
  );
}

export default React.memo(VehicleCard);
