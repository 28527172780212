import { getListVehicle } from 'services/user/vehicle.service';
import { CommonReq, PagingReq } from 'types/common.type';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListVehiclePagingAction = createAsyncThunk(
  'vehicle/getListVehiclePaging',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListVehicle(payload);

    return response.data;
  },
);
