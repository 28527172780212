import { centralGW } from './axios.service';

export const registerNotification = (fcmToken: string) => {
  if (fcmToken)
    return centralGW.post(
      `/api/v1/notification/fcm/register`,
      {
        fcmToken,
      },
      {
        isDisableLoading: true,
        isDisableToast: true,
      },
    );
};

export const unRegisterNotification = (fcmToken: string) => {
  return centralGW.post(
    `/api/v1/notification/fcm/unregister`,
    {
      fcmToken,
    },
    { isDisableLoading: true, isDisableToast: true },
  );
};
