import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getListApproachingRoomExpiredDate,
  getListTransactionReport,
  getListWaitingCheckInReport,
} from 'services/report.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { ReportReq } from 'types/report.type';

export const getListApproachingRoomExpiredDateAction = createAsyncThunk(
  'report/getListApproachingRoomExpiredDateAction',
  async (payload: CommonReq & PagingReq & ReportReq) => {
    const response = await getListApproachingRoomExpiredDate(payload);

    return response.data;
  },
);

export const getListWaitingCheckInReportAction = createAsyncThunk(
  'report/getListWaitingCheckInReportAction',
  async (payload: CommonReq & PagingReq & ReportReq) => {
    const response = await getListWaitingCheckInReport(payload);

    return response.data;
  },
);

export const getListTransactionReportAction = createAsyncThunk(
  'report/getListTransactionReportAction',
  async (payload: CommonReq & PagingReq & ReportReq) => {
    const response = await getListTransactionReport(payload);

    return response.data;
  },
);
