import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { CreateBuildingReq, FloorReq, UpdateBuildingReq } from 'types/property.type';
import { getBranchId } from 'utils/common';

export const getBuildingInBranch = async () => {
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building`, {
    isDisableToast: true,
  });
};
export const getBuildingActiveInBranch = async (payload: CommonReq) => {
  const { isActive } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building`, {
    params: { isActive },
    isDisableToast: true,
  });
};

export const getBuildingDetail = async (payload: CommonReq) => {
  const { buildingId } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building/${buildingId}/detail`, {
    isDisableToast: true,
  });
};

export const updateBuildingInformation = async (payload: UpdateBuildingReq & CommonReq) => {
  const { buildingId, name, address, districtId, isActive, provinceId, description } = payload;
  const branchId = getBranchId();

  return centralGW.put(`/api/v1/property/branch/${branchId}/building/${buildingId}`, {
    name,
    address,
    districtId,
    isActive,
    provinceId,
    description,
  });
};

export const createBuildingInBranch = async (payload: CreateBuildingReq & CommonReq) => {
  const { name, address, districtId, isActive, provinceId, description, shortName } = payload;
  const branchId = getBranchId();

  return centralGW.post(`/api/v1/property/branch/${branchId}/building`, {
    name,
    address,
    districtId,
    isActive,
    provinceId,
    description,
    shortName,
  });
};

export const getFloorInBuilding = async (payload: CommonReq) => {
  const { buildingId } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building/${buildingId}/floor`, {
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const createFloorInBuilding = async (payload: FloorReq & CommonReq) => {
  const { name, buildingId } = payload;
  const branchId = getBranchId();

  return centralGW.post(`/api/v1/property/branch/${branchId}/building/${buildingId}/floor`, {
    name,
  });
};

export const deleteFloorInBuilding = async (payload: FloorReq & CommonReq) => {
  const { buildingId, floorId } = payload;
  const branchId = getBranchId();

  return centralGW.delete(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/floor/${floorId}`,
  );
};

export const updateFloorInBuilding = async (payload: FloorReq & CommonReq) => {
  const { buildingId, floorId } = payload;
  const branchId = getBranchId();

  return centralGW.put(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/floor/${floorId}`,
    payload,
  );
};

export const getListPaymentMethodPaging = async (payload: CommonReq & PagingReq) => {
  const { buildingId, limit, page } = payload;
  const branchId = getBranchId();

  return centralGW.get(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/payment-methods`,
    {
      params: {
        page,
        limit,
      },
      isDisableToast: true,
    },
  );
};

export const getListOptionPaymentMethod = async (payload: CommonReq & PagingReq) => {
  const { buildingId } = payload;
  const branchId = getBranchId();

  return centralGW.get(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/payment-methods`,
    {
      params: {
        isFull: true,
        isActive: true,
      },
      isDisableToast: true,
      isDisableLoading: true,
    },
  );
};

export const updatePaymentMethod = async (payload: CommonReq) => {
  const { buildingId, paymentMethodId, isActive } = payload;
  const branchId = getBranchId();

  return centralGW.put(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/payment-method/${paymentMethodId}`,
    { isActive },
  );
};
