import {
  assignEmployee,
  getEmployeeDetail,
  getListEmployee,
  getListEmployeeInBranch,
  unassignEmployee,
  updateEmployee,
} from 'services/employee.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { EmployeeReq, EmployeeType } from 'types/employee.type';
import { isSuccessCode } from 'utils/common';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListEmployeePagingAction = createAsyncThunk(
  'employee/getListEmployeeAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListEmployee(payload);

    return response.data;
  },
);

export const getListEmployeeInBranchAction = createAsyncThunk(
  'employee/getListEmployeeInBranchAction',
  async (payload: CommonReq) => {
    const response = await getListEmployeeInBranch(payload);

    return response.data;
  },
);

export const updateEmployeeAction = createAsyncThunk(
  'employee/updateEmployeeAction',
  async (payload: CommonReq & EmployeeType) => {
    const response = await updateEmployee(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const getEmployeeDetailAction = createAsyncThunk(
  'employee/getEmployeeDetailAction',
  async (payload: EmployeeReq) => {
    const response = await getEmployeeDetail(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback(response.data.data);
    }

    return response.data;
  },
);

export const unassignEmployeeAction = createAsyncThunk(
  'employee/unassignEmployeeAction',
  async (payload: CommonReq) => {
    const response = await unassignEmployee(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
export const assignEmployeeAction = createAsyncThunk(
  'employee/assignEmployeeAction',
  async (payload: CommonReq) => {
    const response = await assignEmployee(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
