import React, { ComponentType } from 'react';

import CustomFormLabel from 'components/theme-elements/CustomFormLabel';
import CustomOutlinedInput from 'components/theme-elements/CustomOutlinedInput';
import { useTranslation } from 'react-i18next';

import { Box, InputAdornment, SxProps } from '@mui/material';
import { TablerIconsProps } from '@tabler/icons-react';

type Props = {
  isRequired?: boolean;
  isDisable?: boolean;
  Icon?: ComponentType<TablerIconsProps>;
  onChange?: (newValue: string) => void;
  errorMessage?: string;
  value?: string;
  title?: string;
  placeholder?: string;
  sx?: SxProps;
};

const InputNumber = ({
  onChange,
  errorMessage = '',
  value = '',
  title = '',
  placeholder = '',
  isRequired = false,
  isDisable = false,
  Icon,
  sx = {},
}: Props) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <Box width="100%">
      {title && (
        <CustomFormLabel className={`${isRequired ? 'required' : ''}`} sx={{ mt: '0px' }}>
          {t(title)}
        </CustomFormLabel>
      )}
      <CustomOutlinedInput
        type="number"
        value={value}
        onChange={handleChange}
        size="medium"
        sx={{
          ...sx,
          padding: '5px',
        }}
        startAdornment={
          Icon && (
            <InputAdornment position="start" sx={{ paddingLeft: '10px', marginRight: '10px' }}>
              {Icon && <Icon size="20px" />}
            </InputAdornment>
          )
        }
        placeholder={t(placeholder)}
        fullWidth
        inputProps={{
          minLength: 1,
          maxLength: 3,
        }}
        error={Boolean(errorMessage)}
        errorMessage={t(`validate-message.${errorMessage}`)}
        isDisable={isDisable}
      />
    </Box>
  );
};

export default React.memo(InputNumber);
