import React, { useEffect, useMemo, useRef, useState } from 'react';

import AddActionCard from 'components/card/AddActionCard';
import RoomCardConfig from 'components/card/RoomCardConfig';
import FloorDrawer from 'components/drawers/FloorDrawer';
import RoomDrawer from 'components/drawers/RoomDrawer';
import ConfirmModal from 'components/modals/ConfirmModal';
import FloorModal from 'components/modals/FloorModal';
import RoomModal from 'components/modals/RoomModal';
import SectionCollapse from 'components/shared/SectionCollapse';
import { useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { createRoom, deleteRoom, updateFloorInBuilding, updateRoom } from 'services/property';
import {
  createFloorInBuildingAction,
  deleteFloorInBuildingAction,
  getFloorInBuildingAction,
  getListRoomAction,
  getOptionRoomAction,
  propertySelector,
} from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { CustomResponseType } from 'types/common.type';
import { FloorNameType } from 'types/floor.type';
import { RoomType } from 'types/room.type';
import { compareObject, formatNumber, getRoomStatus, isAllow, isSuccessCode } from 'utils/common';

import { Box, Button, Grid, Tooltip } from '@mui/material';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

const initFloorData = {
  name: '',
};

const initFormRoomData = {
  name: '',
  roomTypeId: '',
  floorId: '',
  maxUser: '',
  area: '',
  price: '',
  isMaintenance: false,
  roomStatus: true,
  isPaidStartOfMonth: true,
};

const FloorAndRoomTabContainer = () => {
  const { t } = useTranslation();
  const [closeIds, setCloseIds] = useState<number[]>([]);
  const dispatch = useDispatch();
  const { buildingId } = useParams();
  const cache = useRef<RoomType>();
  const { listRoom, listRoomType, floorsInBuilding } = useSelector(propertySelector);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenRoom, onOpen: onOpenRoom, onClose: onCloseRoom } = useDisclosure();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
  const {
    isOpen: isOpenConfirmRoom,
    onOpen: onOpenConfirmRoom,
    onClose: onCloseConfirmRoom,
  } = useDisclosure();
  const {
    isOpen: isOpenFloorDrawer,
    onOpen: onOpenFloorDrawer,
    onClose: onCloseFloorDrawer,
  } = useDisclosure();
  const [formRoomData, setFormRoomData] = useState(initFormRoomData);
  const [formFloorData, setFormFloorData] = useState<FloorNameType>(initFloorData);
  const [formEditFloorData, setFormEditFloorData] = useState<FloorNameType>(initFloorData);
  const [formEditRoomData, setFormEditRoomData] = useState<RoomType>(initFormRoomData);
  const [selectedFloorId, setSelectedFloorId] = useState<string | number>('');
  const [selectedRoomId, setSelectedRoomId] = useState<string | number>('');

  const {
    isOpen: isOpenRoomDrawer,
    onOpen: onOpenRoomDrawer,
    onClose: onCloseRoomDrawer,
  } = useDisclosure();

  const handleAddRoom = async () => {
    const response = await createRoom({
      buildingId,
      floorId: formRoomData.floorId,
      area: Number(formRoomData.area),
      name: formRoomData.name,
      roomTypeId: Number(formRoomData.roomTypeId),
      maxUser: Number(formRoomData.maxUser),
      price: formatNumber(formRoomData.price),
      isActive: formRoomData.roomStatus,
      isPaidStartOfMonth: formRoomData.isPaidStartOfMonth,
      isMaintain: Boolean(formRoomData.isMaintenance),
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getListRoomAction({
          buildingId: buildingId,
        }),
      );
      onCloseRoomModal();
    } else {
      onCloseRoomModal();
    }
  };

  const handleChangeFormData = (key: string, value: string | boolean) => {
    setFormFloorData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeFormRoomData = (key: string, value: string | boolean) => {
    if (key === 'roomTypeId') {
      const type = listRoomType.find((type) => String(type.id) == String(value));
      type &&
        setFormRoomData((prev) => ({
          ...prev,
          [key]: String(value),
          maxUser: String(type.maxUser),
          price: String(type.referencePrice),
        }));
    } else {
      setFormRoomData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleChangeFormEditRoomData = (key: string, value: string | boolean) => {
    setFormEditRoomData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeFormEditFloorData = (key: string, value: string | boolean) => {
    setFormEditFloorData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCloseFloor = (id: number) => () => {
    if (closeIds.includes(id)) {
      const newList = closeIds.filter((ele) => ele !== id);
      setCloseIds(newList);
    } else {
      setCloseIds(closeIds.concat([id]));
    }
  };

  const handleAddFloor = () => {
    dispatch(
      createFloorInBuildingAction({
        buildingId,
        name: formFloorData.name,
        callback: () => {
          dispatch(getFloorInBuildingAction({ buildingId: buildingId }));
          onCloseFloorModal();
        },
      }),
    );
  };

  const handleUpdateFloor = async () => {
    const selectedFloor = listRoom?.floors?.find((floor) => floor.id === selectedFloorId);
    if (selectedFloor?.name != formEditFloorData.name) {
      const response = await updateFloorInBuilding({
        floorId: selectedFloorId,
        name: formEditFloorData.name,
        buildingId,
      });
      const dataRes = response.data as CustomResponseType;
      if (isSuccessCode(dataRes.code)) {
        dispatch(
          getListRoomAction({
            buildingId: buildingId,
          }),
        );
        onCloseFloorEditDrawer();
      }
    } else {
      onCloseFloorEditDrawer();
    }
  };

  const handleUpdateRoom = async () => {
    if (!compareObject(formEditRoomData, cache.current)) {
      const response = await updateRoom({
        roomId: selectedRoomId,
        floorId: selectedFloorId,
        buildingId,
        area: Number(formEditRoomData.area),
        isActive: Boolean(formEditRoomData.roomStatus),
        isMaintain: Boolean(formEditRoomData.isMaintenance),
        maxUser: Number(formEditRoomData.maxUser),
        name: String(formEditRoomData.name),
        price: formatNumber(String(formEditRoomData.price)),
        roomTypeId: Number(formEditRoomData.roomTypeId),
        isPaidStartOfMonth: Number(formEditRoomData.isPaidStartOfMonth),
      });
      const dataRes = response.data as CustomResponseType;
      if (isSuccessCode(dataRes.code)) {
        dispatch(
          getListRoomAction({
            buildingId: buildingId,
          }),
        );
        onCloseRoomEditDrawer();
      } else {
        onCloseRoomEditDrawer();
      }
    } else {
      onCloseRoomEditDrawer();
    }
  };

  const onCloseFloorModal = () => {
    onClose();
    setFormFloorData(initFloorData);
  };

  const onCloseFloorEditDrawer = () => {
    onCloseFloorDrawer();
    setFormEditFloorData(initFloorData);
  };

  const onCloseRoomEditDrawer = () => {
    onCloseRoomDrawer();
    setFormEditRoomData(initFormRoomData);
  };

  const onCloseRoomModal = () => {
    onCloseRoom();
    setFormRoomData(initFormRoomData);
  };

  const handleDeleteFloor = () => {
    dispatch(
      deleteFloorInBuildingAction({
        floorId: selectedFloorId,
        buildingId,
        callback: () => dispatch(getFloorInBuildingAction({ buildingId: buildingId })),
      }),
    );
    onCloseConfirm();
  };

  const handleDeleteRoom = async () => {
    const response = await deleteRoom({
      floorId: selectedFloorId,
      roomId: selectedRoomId,
      buildingId,
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getListRoomAction({
          buildingId: buildingId,
        }),
      );
      onCloseConfirmRoom();
    }
  };

  const handleOpenConfirmModal =
    (floorId: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setSelectedFloorId(floorId);
      onOpenConfirm();
    };

  const handleOpenRoomConfirmModal = (roomId: number, floorId: number) => () => {
    setSelectedRoomId(roomId);
    setSelectedFloorId(floorId);
    onOpenConfirmRoom();
  };

  const handleOpenEditFloorDrawer =
    (floorId: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      const floorDetail = listRoom?.floors?.find((floor) => floor.id === floorId);
      setFormEditFloorData({ name: floorDetail?.name || '' });
      setSelectedFloorId(floorId);
      onOpenFloorDrawer();
    };

  const handleOpenRoomModal = (floorId: string) => () => {
    setFormRoomData((prev) => ({
      ...prev,
      floorId: floorId,
    }));
    onOpenRoom();
  };

  const handleOpenRoomDrawer = (roomId: number, floorId: number) => () => {
    const floorDetail = listRoom?.floors?.find((floor) => floor.id === floorId);
    if (floorDetail) {
      const roomDetail = floorDetail.rooms.find((room) => room.id === roomId);
      if (roomDetail) {
        const initData = {
          area: String(roomDetail.area),
          maxUser: String(roomDetail.maxUser),
          floorId: String(roomDetail.floorId),
          name: String(roomDetail.name),
          price: String(roomDetail.price),
          roomTypeId: String(roomDetail.roomTypeId),
          roomStatus: roomDetail.isActive,
          isMaintenance: Boolean(roomDetail?.isMaintain),
          isPaidStartOfMonth: Boolean(roomDetail.isPaidStartOfMonth),
        };
        cache.current = initData;
        setFormEditRoomData(initData);
        onOpenRoomDrawer();
        setSelectedRoomId(roomId);
        setSelectedFloorId(floorId);
      }
    }
  };

  const processedRoomList = useMemo(() => {
    return listRoom?.floors?.map((floor) => {
      return {
        id: floor?.id,
        floorName: floor.name,
        rooms: floor?.rooms?.map((room) => {
          return {
            id: room.id,
            name: room?.name,
            capacity: room?.maxUser,
            area: room?.area,
            price: Number(room?.price),
            status: getRoomStatus(room?.isMaintain, room?.isBooking),
            typeName: room?.roomType?.name,
            isActive: Boolean(room?.isActive),
            isBooking: Boolean(room?.isBooking),
            isPaid: Boolean(room?.isPaid),
            isStaying: Boolean(room?.isStaying),
            isMaintain: Boolean(room?.isMaintain),
          };
        }),
      };
    });
  }, [listRoom]);

  useEffect(() => {
    dispatch(
      getListRoomAction({
        buildingId: buildingId,
      }),
    );
    dispatch(getOptionRoomAction({ buildingId: buildingId }));
    dispatch(getFloorInBuildingAction({ buildingId: buildingId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box textAlign="right">
      <PermissionRender permission={[PERMISSION.CREATE_FLOOR]}>
        <Button
          color="primary"
          variant="contained"
          endIcon={<IconPlus width={18} />}
          sx={{
            marginBottom: '10px',
          }}
          onClick={onOpen}
        >
          {t('create-floor')}
        </Button>
      </PermissionRender>
      <Box display="flex" flexDirection="column" gap="12px">
        {processedRoomList?.map((floor) => {
          return (
            <SectionCollapse
              key={floor.id}
              sectionName={floor.floorName}
              isActive={!closeIds.includes(floor.id)}
              onClickOpen={handleCloseFloor(floor.id)}
              headerButton={
                <Box display="flex" gap="12px">
                  <PermissionRender permission={[PERMISSION.UPDATE_FLOOR]}>
                    <Tooltip placement="top" title={t('edit-floor')}>
                      <Button
                        variant="outlined"
                        color="info"
                        onClick={handleOpenEditFloorDrawer(floor.id)}
                      >
                        <IconEdit />
                      </Button>
                    </Tooltip>
                  </PermissionRender>
                  <PermissionRender permission={[PERMISSION.DELETE_FLOOR]}>
                    <Tooltip placement="top" title={t('delete-floor')}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleOpenConfirmModal(floor.id)}
                      >
                        <IconTrash />
                      </Button>
                    </Tooltip>
                  </PermissionRender>
                </Box>
              }
            >
              <Grid container spacing={2} sx={{ padding: '15px' }}>
                {floor.rooms.map((ele, idx) => {
                  return (
                    <Grid key={idx} item xs={12} md={6} lg={4}>
                      <RoomCardConfig
                        name={ele.name}
                        area={ele.area}
                        capacity={ele.capacity}
                        isActive={ele.isActive}
                        price={ele.price}
                        status={ele.status}
                        isBooking={ele.isBooking}
                        isPaid={ele.isPaid}
                        isStaying={ele.isStaying}
                        isMaintenance={ele.isMaintain}
                        typeName={ele.typeName}
                        onClick={handleOpenRoomDrawer(ele.id, floor.id)}
                        onDelete={handleOpenRoomConfirmModal(ele.id, floor.id)}
                      />
                    </Grid>
                  );
                })}
                <PermissionRender
                  permission={[PERMISSION.CREATE_ROOM, PERMISSION.SUB_TAB_ROOM_TYPES]}
                >
                  <Grid item xs={12} md={6} lg={4}>
                    <AddActionCard
                      height="180px"
                      label={t('create-room') || ''}
                      onClick={handleOpenRoomModal(String(floor.id))}
                    />
                  </Grid>
                </PermissionRender>
              </Grid>
            </SectionCollapse>
          );
        })}
      </Box>
      <RoomDrawer
        data={formEditRoomData}
        onChange={handleChangeFormEditRoomData}
        onClose={onCloseRoomEditDrawer}
        isOpen={isOpenRoomDrawer}
        onSubmit={handleUpdateRoom}
        listRoomTypeOptions={listRoomType}
        listFloorNameOptions={floorsInBuilding}
        isColumn={true}
        isEdit={true}
        isDisable={!isAllow([PERMISSION.UPDATE_ROOM])}
      />
      <RoomModal
        data={formRoomData}
        onChange={handleChangeFormRoomData}
        onClose={onCloseRoomModal}
        onSubmit={handleAddRoom}
        listRoomTypeOptions={listRoomType}
        listFloorNameOptions={floorsInBuilding}
        isOpen={isOpenRoom}
      />
      <FloorModal
        data={formFloorData}
        onChange={handleChangeFormData}
        onClose={onCloseFloorModal}
        isOpen={isOpen}
        onSubmit={handleAddFloor}
      />
      <FloorDrawer
        data={formEditFloorData}
        onChange={handleChangeFormEditFloorData}
        onClose={onCloseFloorEditDrawer}
        isOpen={isOpenFloorDrawer}
        onSubmit={handleUpdateFloor}
      />
      <ConfirmModal isOpen={isOpenConfirm} onClose={onCloseConfirm} onSubmit={handleDeleteFloor} />
      <ConfirmModal
        isOpen={isOpenConfirmRoom}
        onClose={onCloseConfirmRoom}
        onSubmit={handleDeleteRoom}
      />
    </Box>
  );
};
export default FloorAndRoomTabContainer;
