import React from 'react';

import ServiceTabContainer from 'containers/room/tab/ServiceTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import { PERMISSION } from 'constants/permission';

const ServiceTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SB_TAB_ROOM_LIST]}>
      <ServiceTabContainer />
    </PermissionPageWrapper>
  );
};

export default ServiceTab;
