import { centralGW } from 'services/axios.service';
import { CommonReq } from 'types/common.type';
import { ServiceType, UpdateServiceReq } from 'types/property.type';
import { getBranchId } from 'utils/common';

export const getListService = async (payload: CommonReq) => {
  const { buildingId, isActive } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building/${buildingId}/service-fee`, {
    params: {
      isActive,
    },
    isDisableToast: true,
  });
};

export const createService = async (payload: CommonReq & ServiceType) => {
  const {
    name,
    isActive,
    referencePrice,
    unitId,
    isMeter,
    isDefault,
    buildingId,
    isPaidStartOfMonth,
  } = payload;
  const branchId = getBranchId();

  return await centralGW.post(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/service-fee`,
    {
      name,
      isActive,
      referencePrice,
      unitId,
      isMeter,
      isDefault,
      isPaidStartOfMonth,
    },
  );
};

export const deleteService = async (payload: CommonReq) => {
  const { buildingId, serviceId: serviceFeeId } = payload;
  const branchId = getBranchId();

  return await centralGW.delete(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/service-fee/${serviceFeeId}`,
  );
};

export const updateService = async (payload: CommonReq & UpdateServiceReq) => {
  const {
    buildingId,
    serviceId: serviceFeeId,
    isActive,
    isDefault,
    isMeter,
    name,
    referencePrice,
    unitId,
    isPaidStartOfMonth,
  } = payload;
  const branchId = getBranchId();

  return centralGW.put(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/service-fee/${serviceFeeId}`,
    {
      isActive,
      isDefault,
      isMeter,
      name,
      referencePrice,
      unitId,
      isPaidStartOfMonth,
    },
  );
};
