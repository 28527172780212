import React from 'react';

import VehicleTabContainer from 'containers/client/tab/VehicleTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import { PERMISSION } from 'constants/permission';

const VehicleTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_VEHICLES_WITH_USER]}>
      <VehicleTabContainer />
    </PermissionPageWrapper>
  );
};

export default VehicleTab;
