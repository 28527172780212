import * as React from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SxProps,
  Tooltip,
  Zoom,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Theme } from '@emotion/react';
import { IconTrash } from '@tabler/icons-react';

type Props = {
  title?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  onDelete?: () => void;
  children?: React.ReactNode;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  isFullWidth?: boolean;
  isAction?: boolean;
  isDisable?: boolean;
  titleSx?: SxProps<Theme>;
  isDeleteAction?: boolean;
  isTransition?: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

function DialogWrapper({
  title = '',
  isOpen = false,
  primaryButtonLabel = '',
  secondaryButtonLabel = '',
  onClose,
  onSubmit,
  children,
  isFullWidth = true,
  isAction = true,
  titleSx,
  isDisable = false,
  isDeleteAction = false,
  onDelete,
  isTransition = true,
}: Props) {
  const { t } = useTranslation();
  const handleDeleteTask = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={isTransition ? Transition : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth={isFullWidth}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ ...titleSx, display: 'flex', justifyContent: 'space-between' }}
      >
        {t(title)}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: '12px',
        }}
      >
        {children}
      </DialogContent>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Box px={1}>
          {isDeleteAction && (
            <Tooltip title={String(t('delete-task'))} placement="top-start">
              <Button color="error" onClick={handleDeleteTask}>
                <IconTrash />
              </Button>
            </Tooltip>
          )}
        </Box>
        {isAction && (
          <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="error" onClick={onClose}>
              {t(secondaryButtonLabel || 'cancel')}
            </Button>
            <Button variant="contained" onClick={onSubmit} disabled={isDisable} autoFocus>
              {t(primaryButtonLabel || 'agree')}
            </Button>
          </DialogActions>
        )}
      </Stack>
    </Dialog>
  );
}

export default React.memo(DialogWrapper);
