import React, { ComponentType } from 'react';

import { Box, CardContent, PaletteColor, Typography, useTheme } from '@mui/material';

import { showData } from 'utils/common';
import { useTranslation } from 'react-i18next';
import { TablerIconsProps } from '@tabler/icons-react';

type Props = {
  title: string;
  value: string;
  colorSchema: string;
  Icon: ComponentType<TablerIconsProps>;
};

function InfoCard({ title, value, colorSchema, Icon }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="10px"
      p="12px"
      width="100%"
      bgcolor={`${colorSchema}.light`}
      sx={{ boxShadow: theme.shadows[1] }}
    >
      <Icon
        color={(theme.palette[colorSchema as keyof typeof theme.palette] as PaletteColor).main}
        width="40px"
        height="40px"
      />
      <CardContent
        sx={{ p: '5px', width: 'calc(100% - 50px)', overflow: 'hidden' }}
        style={{ paddingBottom: '5px' }}
      >
        <Typography
          color={`${colorSchema}.main`}
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          display="block"
          width="100%"
          sx={{
            fontSize: {
              xs: '10px',
              sm: '12px',
            },
          }}
        >
          {t(title)}
        </Typography>
        <Typography color={`${colorSchema}.main`} textAlign="left" variant="h4" fontWeight="bold">
          {showData(value)}
        </Typography>
      </CardContent>
    </Box>
  );
}

export default React.memo(InfoCard);
