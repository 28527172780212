import * as React from 'react';

import CustomFormLabel from 'components/theme-elements/CustomFormLabel';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { Box, FormHelperText, TextareaAutosize } from '@mui/material';

type Props = {
  isRequired?: boolean;
  isDisable?: boolean;
  value?: string;
  title?: string;
  onChange?: (newValue: string) => void;
  errorMessage?: string;
  placeholder?: string;
};
const CustomTextarea = styled(TextareaAutosize)({
  width: '100%',
  border: '1px solid var(--gray-secondary)',
  borderRadius: '6px',
  outline: 'none',
  padding: '10px',
  color: 'var(--black-02)',
  '&:hover': {
    border: '1px solid var(--primary)',
  },
  '&:disabled': {
    border: '1px solid var(--gray-secondary)',
  },
});
function TextArea({
  title = '',
  value,
  errorMessage = '',
  placeholder = '',
  isRequired = false,
  isDisable = false,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event?.target.value);
  };

  return (
    <Box width="100%">
      <CustomFormLabel className={`${isRequired ? 'required' : ''}`} sx={{ mt: '0px' }}>
        {t(title)}
      </CustomFormLabel>
      <CustomTextarea
        className={`${errorMessage ? 'area-error' : ''}`}
        maxRows={10}
        minRows={5}
        value={value}
        onChange={handleChange}
        maxLength={500}
        placeholder={String(t(placeholder))}
        disabled={isDisable}
        style={{ fontFamily: 'inherit' }}
      />
      {errorMessage && (
        <FormHelperText error>{t(`validate-message.${errorMessage}`)}</FormHelperText>
      )}
    </Box>
  );
}

export default React.memo(TextArea);
