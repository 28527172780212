import {
  createContractFee,
  deleteContractFee,
  getContractFeeList,
  updateContractFee,
} from 'services/user/contractFee.service';
import { CommonReq } from 'types/common.type';
import { ContractFeeReq } from 'types/contract.type';
import { ServiceFeeForm } from 'types/service.type';
import { isSuccessCode } from 'utils/common';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getContractFeeListAction = createAsyncThunk(
  'contractFee/getContractFeeListInRoomAction',
  async (payload: CommonReq) => {
    const response = await getContractFeeList(payload);

    return response.data;
  },
);

export const createContractFeeAction = createAsyncThunk(
  'contractFee/createContractFeeAction',
  async (payload: CommonReq & ServiceFeeForm) => {
    const response = await createContractFee(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const deleteContractFeeAction = createAsyncThunk(
  'contractFee/deleteContractFeeAction',
  async (payload: CommonReq & ContractFeeReq) => {
    const response = await deleteContractFee(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);

export const updateContractFeeAction = createAsyncThunk(
  'contractFee/updateContractFeeAction',
  async (payload: CommonReq & ServiceFeeForm & ContractFeeReq) => {
    const response = await updateContractFee(payload);
    if (response.data.code && isSuccessCode(response.data.code)) {
      payload.callback && payload.callback();
    }

    return response.data;
  },
);
