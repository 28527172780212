import React, { ChangeEvent, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { JOI } from 'constants/joi';

import { useTranslation } from 'react-i18next';
import { createSchema, validateSchema } from 'utils/joi';

import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import { CreateOtherTransactionType } from 'types/transaction.type';
import AutoComplete from 'components/shared/AutoComplete';
import { IconCoin, IconCreditCard, IconX } from '@tabler/icons-react';
import InputPrice from 'components/shared/InputPrice';
import TextArea from 'components/shared/TextArea';
import { PaymentMethodType } from 'types/payment-method.type';
import { formatNumber, showData } from 'utils/common';
import { GetImageList } from 'types/maintenance.type';
import { isEmpty, isUndefined } from 'lodash';
import { IMAGE_URL, MAX_FILE_SIZE, MAX_UPLOAD_LIMIT } from 'constants/common';

interface Props {
  listPaymentMethod: PaymentMethodType[];
  buildingId: number;
  onSubmit?: (payload: CreateOtherTransactionType) => void;
  onUpload?: (value: File[]) => void;
  images?: GetImageList[];
  handleDirectToImage?: (image: string) => void;
  handleDelete?: (index: number) => void;
}

const PaymentOtherForm = forwardRef(
  (
    {
      listPaymentMethod,
      buildingId,
      onSubmit,
      onUpload,
      images,
      handleDirectToImage,
      handleDelete,
    }: Props,
    ref,
  ) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<CreateOtherTransactionType>({
      buildingId: String(buildingId),
      paymentMethodId: '',
      transactionAmount: '',
      note: '',
    });

    const [formDataError, setFormDataError] = useState<CreateOtherTransactionType>({
      buildingId: '',
      paymentMethodId: '',
      transactionAmount: '',
      note: '',
    });
    const schema = createSchema({
      buildingId: JOI.REQUIRE,
      paymentMethodId: JOI.REQUIRE,
      transactionAmount: JOI.PRICE,
      note: JOI.REQUIRE,
      imageUrls: JOI.ALLOW,
    });

    const handleChangeFormData = (key: string) => (value: string | number) => {
      setFormData((pre) => ({
        ...pre,
        [key]: value,
      }));
    };

    const handleSubmit = async () => {
      const resError = validateSchema(schema, formData);
      setFormDataError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit &&
          onSubmit({
            ...formData,
            transactionAmount: String(formatNumber(String(formData.transactionAmount))),
          });
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    const handleDirect = (image: string) => () => {
      handleDirectToImage && handleDirectToImage(String(image));
    };
    const onDelete = (index: number) => () => {
      handleDelete && handleDelete(Number(index));
    };
    const handleUploadFile = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
          const files = Array.from(event.target.files);
          if (files.length > MAX_UPLOAD_LIMIT) {
            alert(`${t('upload-maximum')} ${MAX_UPLOAD_LIMIT} ${t('image')}.`);

            return;
          }
          const largeFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
          if (largeFiles.length > 0) {
            largeFiles.forEach((file) => {
              alert(`${file.name} ${t('image-size-too-large')}`);
            });

            return;
          }
          onUpload && onUpload(files);
        }
      },
      [onUpload, t],
    );

    return (
      <Grid container spacing="12px" mb="12px">
        <Grid container item spacing="12px">
          <Grid item xs={12} sm={6}>
            <InputPrice
              isRequired
              title={t('total-payment') || ''}
              placeholder={'30.000VNĐ...'}
              Icon={IconCoin}
              value={String(formData.transactionAmount)}
              onChange={handleChangeFormData('transactionAmount')}
              errorMessage={formDataError.transactionAmount}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              isRequired
              title={t('payment-method') || ''}
              placeHolder="placeholder.choose-payment-method"
              Icon={IconCreditCard}
              value={String(formData.paymentMethodId)}
              options={listPaymentMethod.map((method) => ({
                label: showData(String(method.name)),
                value: String(method.id),
              }))}
              onChange={handleChangeFormData('paymentMethodId')}
              errorMessage={formDataError.paymentMethodId}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            gap={2}
            my={1}
            bgcolor={!isEmpty(images) ? 'whitesmoke' : 'white'}
            p={!isEmpty(images) ? 2 : 0}
            borderRadius={1}
          >
            {!isEmpty(images) &&
              images?.map(
                (img, idx) =>
                  !isUndefined(img?.filePath) && (
                    <Box style={{ position: 'relative' }} key={idx}>
                      <img
                        src={`${IMAGE_URL}?id=${img?.filePath}` || ''}
                        alt={`File`}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '150px',
                          minHeight: '100px',
                          marginRight: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={handleDirect(String(`${IMAGE_URL}?id=${img?.filePath}`))}
                      />
                      <IconButton
                        style={{
                          backgroundColor: 'white',
                          position: 'absolute',
                          top: '-10px',
                          right: '-10px',
                        }}
                        onClick={onDelete(idx)}
                        aria-label="delete"
                      >
                        <IconX size={15} />
                      </IconButton>
                    </Box>
                  ),
              )}
          </Stack>
        </Grid>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
          <Button variant="text" color="primary" component="label">
            {t('upload')}
            <input
              type="file"
              multiple
              accept="image/png, image/gif, image/jpeg, video/*"
              style={{ display: 'none' }}
              onChange={handleUploadFile}
            />
          </Button>
        </Grid>
        <Grid container item spacing="12px">
          <Grid item xs={12} sm={12}>
            <TextArea
              isRequired
              title="note"
              value={String(formData.note)}
              onChange={handleChangeFormData('note')}
              placeholder="placeholder.type-here"
              errorMessage={formDataError.note}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

export default React.memo(PaymentOtherForm);
