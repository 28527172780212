import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { getBranchId } from 'utils/common';

export const getListOtherTransaction = async (payload: CommonReq & PagingReq) => {
  const { page, limit, buildingId } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building/${buildingId}/transactions`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};
