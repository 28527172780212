import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { EmployeeReq, EmployeeType } from 'types/employee.type';
import { getBranchId } from 'utils/common';

export const getListEmployee = (payload: CommonReq & PagingReq) => {
  const { buildingId, page, limit, query, isActive } = payload;

  return centralGW.get(`/api/v1/employee/building/${buildingId}`, {
    params: {
      page,
      limit,
      ...(query ? { q: query } : {}),
      isActive,
    },
    isDisableToast: true,
  });
};

export const getListEmployeeInBranch = (payload: CommonReq) => {
  const { query } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/employee/branch/${branchId}`, {
    params: {
      ...(query ? { q: query } : {}),
    },
    isDisableToast: true,
  });
};

export const updateEmployee = (payload: EmployeeReq & EmployeeType) => {
  const { employeeId, isActive, phoneNumber, roleId, email } = payload;

  return centralGW.put(`/api/v1/employee/${employeeId}`, {
    isActive,
    phoneNumber,
    roleId,
    ...(email ? { email } : { email: null }),
  });
};

export const assignEmployee = (payload: CommonReq) => {
  const { employeeId, buildingId } = payload;

  return centralGW.post(`/api/v1/employee/${employeeId}/building/${buildingId}/assign`);
};

export const unassignEmployee = (payload: CommonReq) => {
  const { employeeId, buildingId } = payload;

  return centralGW.delete(`/api/v1/employee/${employeeId}/building/${buildingId}/unassign`);
};

export const createEmployee = async (payload: CommonReq & EmployeeType) => {
  const {
    buildingIds,
    firstName,
    lastName,
    gender,
    placeOfOrigin,
    placeOfResidence,
    phoneNumber,
    email,
    nationality,
    birthday,
    identityNumber,
    roleId,
    idCardImages,
    issueDate,
    issueLocation,
  } = payload;
  const branchId = getBranchId();

  return centralGW.post(`/api/v1/employee/branch/${branchId}/register`, {
    buildingIds,
    firstName,
    lastName,
    gender,
    placeOfOrigin,
    placeOfResidence,
    phoneNumber,
    ...(email ? { email } : {}),
    nationality,
    birthday,
    identityNumber,
    idCardImages,
    roleId,
    issueDate,
    issueLocation,
  });
};

export const getEmployeeDetail = (payload: EmployeeReq) => {
  const { employeeId } = payload;

  return centralGW.get(`/api/v1/employee/${employeeId}/detail`, {
    isDisableToast: true,
  });
};
