import React from 'react';

import { EMPTY_CONTENT } from 'constants/common';
import { DEPOSIT_TRANSACTION_STATUS, DEPOSIT_TRANSACTION_TYPE } from 'enums';
import { useTranslation } from 'react-i18next';

import { Chip } from '@mui/material';
import { ColorType } from 'types/color.type';

type Props = {
  status?: string;
};
const DepositTransactionChip = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <Chip
      key="chip"
      size="small"
      label={t(
        DEPOSIT_TRANSACTION_TYPE[status as keyof typeof DEPOSIT_TRANSACTION_TYPE] ?? EMPTY_CONTENT,
      )}
      color={
        (status === DEPOSIT_TRANSACTION_STATUS.DEPOSIT
          ? 'info'
          : status === DEPOSIT_TRANSACTION_STATUS.REFUND
          ? 'error'
          : status === DEPOSIT_TRANSACTION_STATUS.CONVERT_TO_INVOICE_REVENUE
          ? 'cyan_light'
          : status === DEPOSIT_TRANSACTION_STATUS.CONVERT_TO_OTHER_REVENUE
          ? 'field_light'
          : undefined) as ColorType
      }
    />
  );
};

export default React.memo(DepositTransactionChip);
