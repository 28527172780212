import React, { useEffect, useMemo, useRef, useState } from 'react';

import RoomTypeDrawer from 'components/drawers/RoomTypeDrawer';
import ConfirmModal from 'components/modals/ConfirmModal';
import RoomTypeModal from 'components/modals/RoomTypeModal';
import TableNormal from 'components/table/TableNormal';
import { useDisclosure } from 'hooks';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { createRoomType, deleteRoomType, updateRoomType } from 'services/property';
import { getListRoomTypeAction, propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { CustomResponseType } from 'types/common.type';
import { RoomCategoryType } from 'types/room.type';
import { compareObject, formatNumber, formatPrice, isSuccessCode } from 'utils/common';

import { Box, Button, Tooltip } from '@mui/material';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

const initFormData = { name: '', maxUser: '', referencePrice: '' };

const RoomTypeTabContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenRoomType,
    onOpen: onOpenRoomType,
    onClose: onCloseRoomType,
  } = useDisclosure();
  const {
    isOpen: isOpenRoomTypeEdit,
    onOpen: onOpenRoomTypeEdit,
    onClose: onCloseRoomTypeEdit,
  } = useDisclosure();
  const { buildingId } = useParams();
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { listRoomTypePaging } = useSelector(propertySelector);
  const [roomType, setRoomType] = useState(initFormData);
  const [roomTypeEdit, setRoomTypeEdit] = useState(initFormData);
  const [selectedRoomTypeId, setSelectedRoomTypeId] = useState<number | null>(null);
  const cache = useRef<RoomCategoryType>();

  const handleAddRoomType = async () => {
    const response = await createRoomType({
      maxUser: Number(roomType.maxUser),
      name: roomType.name,
      referencePrice: formatNumber(roomType.referencePrice),
      buildingId,
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getListRoomTypeAction({
          buildingId: buildingId,
          page: currentPage,
          limit: rowsPerPage,
        }),
      );
      handleCloseModal();
    }
  };

  const handleUpdateRoomType = async () => {
    if (!compareObject(roomTypeEdit, cache.current)) {
      const response = await updateRoomType({
        roomTypeId: Number(selectedRoomTypeId),
        maxUser: Number(roomTypeEdit.maxUser),
        name: roomTypeEdit.name,
        referencePrice: formatNumber(roomTypeEdit.referencePrice),
        buildingId,
      });
      const dataRes = response.data as CustomResponseType;
      if (isSuccessCode(dataRes.code)) {
        dispatch(
          getListRoomTypeAction({
            buildingId: buildingId,
            page: currentPage,
            limit: rowsPerPage,
          }),
        );
        handleCloseDrawer();
      }
    } else {
      handleCloseDrawer();
    }
  };

  const handleCloseModal = () => {
    onCloseRoomType();
    setRoomType(initFormData);
  };

  const handleCloseDrawer = () => {
    onCloseRoomTypeEdit();
    setRoomTypeEdit(initFormData);
  };

  const handleChangeFormRoomTypeData = (key: string, value: string | boolean) => {
    setRoomType((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeFormEditRoomTypeData = (key: string, value: string | boolean) => {
    setRoomTypeEdit((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleOpenConfirmDelete = (id: number) => () => {
    setSelectedRoomTypeId(id);
    onOpen();
  };

  const handleDeleteRoomType = async () => {
    const response = await deleteRoomType({
      roomTypeId: Number(selectedRoomTypeId),
      buildingId,
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getListRoomTypeAction({
          buildingId: buildingId,
          page: currentPage,
          limit: rowsPerPage,
        }),
      );
    }
    onClose();
  };

  const handleOpenRoomTypeDrawer = (id: number) => () => {
    const roomType = listRoomTypePaging.data.find((type) => type.id === id);
    if (roomType) {
      const dataInit = {
        maxUser: String(roomType.maxUser),
        referencePrice: String(roomType.referencePrice),
        name: String(roomType.name),
      };
      setRoomTypeEdit(dataInit);
      cache.current = dataInit;
      setSelectedRoomTypeId(id);
    }
    onOpenRoomTypeEdit();
  };

  const processedDataTable = useMemo(() => {
    return listRoomTypePaging?.data?.map((type) => {
      return {
        id: type?.id,
        name: type?.name,
        maxUser: type?.maxUser,
        referencePrice: Number(type?.referencePrice),
      };
    });
  }, [listRoomTypePaging]);

  useEffect(() => {
    dispatch(
      getListRoomTypeAction({
        buildingId: buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [buildingId, dispatch, currentPage, rowsPerPage]);

  const dataRow = useMemo(() => {
    return processedDataTable?.map((item) => {
      return [
        item.name,
        `${item.maxUser} ${t('people')}`,
        formatPrice(item.referencePrice),
        <Box display="flex" justifyContent="flex-end" gap="12px" key={item.id}>
          <PermissionRender permission={[PERMISSION.UPDATE_ROOM_TYPE]}>
            <Tooltip placement="top" title={t('edit-room-type')}>
              <Button
                variant="outlined"
                color="info"
                onClick={handleOpenRoomTypeDrawer(Number(item.id))}
              >
                <IconEdit />
              </Button>
            </Tooltip>
          </PermissionRender>
          <PermissionRender permission={[PERMISSION.DELETE_ROOM_TYPE]}>
            <Tooltip placement="top" title={t('delete-room-type')}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleOpenConfirmDelete(Number(item.id))}
              >
                <IconTrash />
              </Button>
            </Tooltip>
          </PermissionRender>
        </Box>,
      ];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedDataTable, t, onOpenRoomTypeEdit]);

  return (
    <Box textAlign="right">
      <PermissionRender permission={[PERMISSION.CREATE_ROOM_TYPE]}>
        <Button
          color="primary"
          variant="contained"
          endIcon={<IconPlus width={18} />}
          sx={{
            marginBottom: '10px',
          }}
          onClick={onOpenRoomType}
        >
          {t('create-room-type')}
        </Button>
      </PermissionRender>
      <TableNormal
        header={[t('room-type'), t('capacity'), t('reference-price'), '']}
        data={dataRow}
        total={listRoomTypePaging?.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
      <ConfirmModal isOpen={isOpen} onClose={onClose} onSubmit={handleDeleteRoomType} />
      <RoomTypeModal
        data={roomType}
        onChange={handleChangeFormRoomTypeData}
        onSubmit={handleAddRoomType}
        onClose={handleCloseModal}
        isOpen={isOpenRoomType}
      />
      <RoomTypeDrawer
        data={roomTypeEdit}
        onChange={handleChangeFormEditRoomTypeData}
        onClose={onCloseRoomTypeEdit}
        isOpen={isOpenRoomTypeEdit}
        onSubmit={handleUpdateRoomType}
      />
    </Box>
  );
};

export default RoomTypeTabContainer;
