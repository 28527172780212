import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { showData } from 'utils/common';

import { Tooltip, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

type FontSize = {
  xs: string | number;
  sm: string | number;
  md: string | number;
  lg: string | number;
};

type Props = {
  color?: string;
  name: string;
  sx?: CSSProperties;
  variant?: Variant;
  fontSize?: FontSize;
};

const TextOverflow = ({ name, sx, variant = 'subtitle1', color }: Props) => {
  const textElementRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const compareSize = () => {
    const element = textElementRef.current;
    const compare = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;

    setIsOverflow(compare);
  };

  useEffect(() => {
    compareSize();
  }, []);

  return (
    <Tooltip placement="top" title={showData(name)} disableHoverListener={!isOverflow}>
      <Typography
        ref={textElementRef}
        color={color}
        variant={variant}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        textAlign="left"
        sx={sx}
        width="calc(100% - 80px)"
      >
        {name}
      </Typography>
    </Tooltip>
  );
};

export default TextOverflow;
