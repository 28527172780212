import React, {
  useEffect,
  useMemo,
} from 'react';

import TableNormal from 'components/table/TableNormal';
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from 'enums';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { customizerSelector } from 'store/customizer';
import {
  dispatch,
  useSelector,
} from 'store/Store';
import {
  getListTransactionByClientPagingAction,
  transactionSelector,
} from 'store/transaction';
import {
  formatDate,
  formatPrice,
  showData,
} from 'utils/common';

import {
  Chip,
  Link,
  Typography,
} from '@mui/material';

const TRANSACTION_TABLE_HEADER = [
  'status',
  'transaction-code',
  'invoice-code',
  'transaction-type',
  'price',
  'payment-method',
  'creation-date',
  'creator',
];

const TransactionTabContainer = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { listTransactionByClientPaging } = useSelector(transactionSelector);
  const { isLanguage } = useSelector(customizerSelector);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();

  useEffect(() => {
    dispatch(
      getListTransactionByClientPagingAction({
        userId: Number(clientId),
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [clientId, currentPage, rowsPerPage]);

  const transactionTableRows = useMemo(() => {
    return listTransactionByClientPaging.data.map((transaction) => {
      return [
        <Chip
          key="chip"
          size="small"
          label={t(
            transaction.transactionType?.type === TRANSACTION_STATUS.INCOME
              ? TRANSACTION_TYPE.INCOME
              : TRANSACTION_TYPE.EXPENSE,
          )}
          color={transaction.transactionType?.type === TRANSACTION_STATUS.INCOME ? 'info' : 'error'}
        />,
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.id)}</Typography>
        </Link>,
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.invoiceId)}</Typography>
        </Link>,
        <Typography key={uniqueId()} color="textSecondary" variant="subtitle1" fontWeight="400">
          {showData(
            isLanguage === 'vi'
              ? transaction.transactionType?.nameVi
              : transaction.transactionType?.nameEn,
          )}
        </Typography>,
        <Typography key={uniqueId()} color="textSecondary" variant="subtitle1" fontWeight="400">
          {transaction.transactionType?.type === TRANSACTION_STATUS.INCOME ? ' + ' : ' - '}
          {showData(
            transaction.transactionAmount ? formatPrice(transaction.transactionAmount) : null,
          )}
        </Typography>,
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {showData(transaction.paymentMethod?.name)}
        </Typography>,
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {formatDate(transaction.createdAt)}
        </Typography>,
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.createdBy?.fullName)}</Typography>
        </Link>,
      ];
    });
  }, [listTransactionByClientPaging.data, isLanguage, t]);

  return (
    <TableNormal
      header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
      data={transactionTableRows}
      total={listTransactionByClientPaging?.total}
      limit={rowsPerPage}
      currentPage={currentPage}
      onChangePage={handleChangeCurrentPage}
      onChangeLimit={handleChangeLimit}
    />
  );
};

export default TransactionTabContainer;
