import { centralGW } from 'services/axios.service';
import { CommonReq, PagingReq } from 'types/common.type';
import { RoomReq, RoomTypeReq } from 'types/room.type';
import { getBranchId } from 'utils/common';

export const getListRoomInBuilding = async (payload: CommonReq) => {
  const { buildingId, isActive } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building/${buildingId}/room`, {
    params: {
      isActive,
    },
    isDisableToast: true,
  });
};

export const getListRoomType = async (payload: CommonReq & PagingReq) => {
  const { buildingId, page, limit } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building/${buildingId}/room-type`, {
    params: {
      page,
      limit,
    },
    isDisableToast: true,
  });
};

export const getOptionRoomType = async (payload: CommonReq) => {
  const { buildingId } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building/${buildingId}/room-type`, {
    params: {
      isFull: true,
    },
    isDisableLoading: true,
    isDisableToast: true,
  });
};

export const createRoomType = async (payload: CommonReq & RoomTypeReq) => {
  const { buildingId, referencePrice, name, maxUser } = payload;
  const branchId = getBranchId();

  return centralGW.post(`/api/v1/property/branch/${branchId}/building/${buildingId}/room-type`, {
    name,
    referencePrice,
    maxUser,
  });
};

export const updateRoomType = async (payload: CommonReq & RoomTypeReq) => {
  const { buildingId, referencePrice, name, maxUser, roomTypeId } = payload;
  const branchId = getBranchId();

  return centralGW.put(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/room-type/${roomTypeId}`,
    {
      name,
      referencePrice,
      maxUser,
    },
  );
};

export const deleteRoomType = async (payload: CommonReq) => {
  const { buildingId, roomTypeId } = payload;
  const branchId = getBranchId();

  return centralGW.delete(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/room-type/${roomTypeId}`,
  );
};

export const createRoom = async (payload: CommonReq & RoomReq) => {
  const {
    buildingId,
    floorId,
    area,
    isActive,
    isMaintain,
    name,
    roomTypeId,
    maxUser,
    price,
    isPaidStartOfMonth,
  } = payload;
  const branchId = getBranchId();

  return centralGW.post(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/floor/${floorId}/room`,
    {
      area,
      isActive,
      isMaintain,
      name,
      roomTypeId,
      maxUser,
      price,
      isPaidStartOfMonth,
    },
  );
};

export const deleteRoom = async (payload: CommonReq) => {
  const { buildingId, floorId, roomId } = payload;
  const branchId = getBranchId();

  return centralGW.delete(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/floor/${floorId}/room/${roomId}`,
  );
};

export const updateRoom = async (payload: CommonReq & RoomReq) => {
  const {
    buildingId,
    floorId,
    roomId,
    name,
    roomTypeId,
    isActive,
    isMaintain,
    area,
    maxUser,
    price,
    isPaidStartOfMonth,
  } = payload;
  const branchId = getBranchId();

  return centralGW.put(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/floor/${floorId}/room/${roomId}`,
    { name, roomTypeId, isActive, isMaintain, area, maxUser, price, isPaidStartOfMonth },
  );
};

export const getListRoomInFloor = async (payload: CommonReq) => {
  const { buildingId, floorId, query } = payload;
  const branchId = getBranchId();

  return centralGW.get(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/floor/${floorId}/room`,
    {
      params: {
        q: query,
        isActive: true,
      },
      isDisableLoading: true,
      isDisableToast: true,
    },
  );
};

export const getRoomDetail = async (payload: CommonReq) => {
  const { buildingId, roomId } = payload;
  const branchId = getBranchId();

  return centralGW.get(
    `/api/v1/property/branch/${branchId}/building/${buildingId}/room/${roomId}/detail`,
    {
      isDisableToast: true,
    },
  );
};
