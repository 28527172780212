import { CommonReq, PagingReq } from 'types/common.type';
import { centralGW } from './axios.service';
import { ReportReq } from 'types/report.type';

export const getListApproachingRoomExpiredDate = (payload: CommonReq & PagingReq & ReportReq) => {
  const { buildingId, limit, page, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/report/building/${buildingId}/contracts/almost-expired`, {
    params: {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(endDate ? { endDate } : {}),
      ...(startDate ? { startDate } : {}),
    },
    isDisableToast: true,
  });
};

export const getListWaitingCheckInReport = (payload: CommonReq & PagingReq & ReportReq) => {
  const { buildingId, limit, page, startDate, endDate } = payload;

  return centralGW.get(`/api/v1/report/building/${buildingId}/booking/waiting-for-checkin`, {
    params: {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(endDate ? { endDate } : {}),
      ...(startDate ? { startDate } : {}),
    },
    isDisableToast: true,
  });
};

export const getListTransactionReport = (payload: CommonReq & PagingReq & ReportReq) => {
  const { buildingId, limit, page, startDate, endDate, paymentMethodId, transactionTypeId } =
    payload;

  return centralGW.get(`/api/v1/report/building/${buildingId}/transactions`, {
    params: {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(endDate ? { endDate } : {}),
      ...(startDate ? { startDate } : {}),
      ...(paymentMethodId ? { paymentMethodId } : {}),
      ...(transactionTypeId ? { transactionTypeId } : {}),
    },
    isDisableToast: true,
  });
};
