import { CustomResponseType } from 'types/common.type';
import { ContractFeesType } from 'types/contract.type';
import { InvoicesType, UnpaidInvoiceType } from 'types/invoice.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getDepositInvoiceInBuildingAction,
  getDepositTransactionInBookingAction,
  getInvoiceDetailAction,
  getInvoiceTemporaryInContractAction,
  getListInvoiceByClientPagingAction,
  getListInvoiceInBuildingPagingAction,
  getListInvoiceInContractAction,
  getListInvoiceInContractPagingAction,
  getOverviewInvoiceInBuildingAction,
  getUnpaidInvoiceListAction,
} from './actions';
import { GetListBookingRes } from 'types/booking.type';
import { DepositTransactionType } from 'types/transaction.type';

interface InvoiceState {
  listInvoiceInContractPaging: {
    data: InvoicesType[];
    total: number;
    debitTransactionAmount?: number;
    totalRefunds?: number;
    totalTransactionAmount?: number;
    totalPriceInvoices?: number;
  };
  listInvoiceInContract: InvoicesType[];
  listInvoiceInBuildingPaging: {
    data: InvoicesType[];
    total: number;
  };
  invoiceTemporaryInContract: ContractFeesType;
  invoiceDetail: InvoicesType;
  overviewInvoiceInBuilding: InvoicesType;
  depositTransactionInBooking: {
    data: DepositTransactionType[];
    totalTransactionAmount?: number;
    total?: number;
  };
  listInvoiceByClientPaging: {
    data: InvoicesType[];
    total: number;
  };
  listDepositInvoiceInBuilding: GetListBookingRes[];
  unpaidInvoiceList: UnpaidInvoiceType[];
}
const initialState: InvoiceState = {
  listInvoiceInContractPaging: {
    data: [],
    total: 0,
    debitTransactionAmount: 0,
    totalRefunds: 0,
    totalTransactionAmount: 0,
    totalPriceInvoices: 0,
  },
  listInvoiceInContract: [],
  listInvoiceInBuildingPaging: {
    data: [],
    total: 0,
  },
  invoiceTemporaryInContract: {},
  invoiceDetail: {},
  overviewInvoiceInBuilding: {},
  depositTransactionInBooking: { data: [], totalTransactionAmount: 0, total: 0 },
  listInvoiceByClientPaging: {
    data: [],
    total: 0,
  },
  listDepositInvoiceInBuilding: [],
  unpaidInvoiceList: [],
};
export const invoiceSlice = createSlice({
  name: 'invoice',
  reducers: {
    resetListInvoiceInContractPaging: (state) => {
      state.listInvoiceInContractPaging = initialState.listInvoiceInContractPaging;
      state.depositTransactionInBooking = initialState.depositTransactionInBooking;
    },
  },
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<InvoiceState>) => {
    builder.addCase(
      getListInvoiceInContractPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listInvoiceInContractPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListInvoiceInContractAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listInvoiceInContract = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListInvoiceInBuildingPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listInvoiceInBuildingPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getInvoiceTemporaryInContractAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.invoiceTemporaryInContract = action.payload.data;
        }
      },
    );
    builder.addCase(
      getInvoiceDetailAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.invoiceDetail = action.payload.data;
        }
      },
    );
    builder.addCase(
      getOverviewInvoiceInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.overviewInvoiceInBuilding = action.payload.data;
        }
      },
    );
    builder.addCase(
      getDepositTransactionInBookingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.depositTransactionInBooking = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListInvoiceByClientPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listInvoiceByClientPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getDepositInvoiceInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listDepositInvoiceInBuilding = action.payload.data;
        }
      },
    );
    builder.addCase(
      getUnpaidInvoiceListAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.unpaidInvoiceList = action.payload.data;
        }
      },
    );
  },
});

export const { resetListInvoiceInContractPaging } = invoiceSlice.actions;
export default invoiceSlice.reducer;
