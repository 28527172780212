import React from 'react';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { IconPlus, TablerIconsProps } from '@tabler/icons-react';

interface Props {
  label?: string;
  height?: string | number;
  onClick?: () => void;
  sx?: Record<string, any>;
  isDisable?: boolean;
  Icon?: React.ComponentType<TablerIconsProps>;
}

function AddActionCard({ label, height = 180, onClick, sx, isDisable = false, Icon }: Props) {
  const theme = useTheme();
  const handleClick = () => {
    !isDisable && onClick && onClick();
  };

  return (
    <Card
      sx={{
        p: '12px',
        cursor: 'pointer',
        maxWidth: '100%',
        height: height,
        boxShadow: theme.shadows[5],
        border: '2px dashed var(--gray-01)',
        '&:hover': {
          boxShadow: theme.shadows[0],
        },
        display: isDisable ? 'none' : '',
      }}
      onClick={handleClick}
    >
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        {Icon ? (
          <Icon width="32px" height="32px" color="var(--gray-01)" />
        ) : (
          <IconPlus width="32px" height="32px" color="var(--gray-01)" />
        )}
        <Typography
          variant="h3"
          color="var(--gray-01)"
          fontWeight="medium"
          marginLeft="12px"
          sx={sx}
        >
          {label}
        </Typography>
      </Box>
    </Card>
  );
}

export default React.memo(AddActionCard);
