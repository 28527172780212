import { PERMISSION } from 'constants/permission';
import HistoryTabContainer from 'containers/maintenance/tab/HistoryTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const HistoryTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.GET_HISTORY_TAB]}>
      <HistoryTabContainer />
    </PermissionPageWrapper>
  );
};

export default HistoryTab;
