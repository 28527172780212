/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import avatar from 'assets/images/profile/user-1.jpg';

import CommentForm from 'components/forms/CommentForm';
import PostComment from 'components/shared/PostComment';
import {
  clearImage,
  createTaskCommentAction,
  getKanbanTaskCommentAction,
  kanbanSelector,
  uploadFileCommentAction,
} from 'store/kanban';
import { useDispatch, useSelector } from 'store/Store';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import _, { isEmpty, isUndefined } from 'lodash';
import { IMAGE_URL } from 'constants/common';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';
import { useDisclosure } from 'hooks';
import ImagePopup from 'components/shared/ImagePopup';

const initialData = {
  comment: '',
  userId: '',
};
const CommentTabContainer = () => {
  const dispatch = useDispatch();
  const { taskId, taskComment, images } = useSelector(kanbanSelector);
  const [comment, setComment] = useState(initialData);

  const {
    isOpen: isOpenImage,
    onClose: onCloseImage,
    onOpen: onOpenDeleteTabConfirm,
  } = useDisclosure();

  useEffect(() => {
    if (taskId) {
      dispatch(
        getKanbanTaskCommentAction({
          taskId: taskId,
        }),
      );
    }

    return () => {
      dispatch(clearImage(undefined));
    };
  }, [dispatch, taskId]);

  const handleOnChange = (key: string, value: string | boolean) => {
    setComment((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleCloseModal = () => {
    setComment(initialData);
    dispatch(clearImage(undefined));
  };
  const handleSubmitComment = () => {
    dispatch(
      createTaskCommentAction({
        taskId: Number(taskId),
        comment: comment.comment,
        imageUrl: !isEmpty(images)
          ? String(`${IMAGE_URL}?id=${images?.[0].filePath}`)
          : // ? images.map((img) => String(`${IMAGE_URL}?id=${img?.filePath}`))
            '',
        callback() {
          dispatch(
            getKanbanTaskCommentAction({
              taskId: taskId,
            }),
          );
          handleCloseModal();
        },
      }),
    );
  };

  const handleUploadFile = (files: File[]) => {
    files.map((file) => {
      dispatch(
        uploadFileCommentAction({
          image: file,
        }),
      );
    });
  };
  const handleDelete = (index: number) => () => {
    dispatch(clearImage(index));
  };

  const handleDirectToImage = () => {
    onOpenDeleteTabConfirm();
  };

  return (
    <>
      <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
        {taskComment.map((comment) => (
          <PostComment
            key={comment.id}
            username={comment.assignedEmployee?.accountDetail?.fullName}
            avatar={comment.avatarUrl ?? avatar}
            content={comment.comment}
            date={comment.createdAt}
            images={comment?.imageUrl}
          />
        ))}
      </Box>
      <Stack
        direction="row"
        gap={2}
        my={1}
        bgcolor={!isEmpty(images) ? 'whitesmoke' : 'white'}
        p={!isEmpty(images) ? 2 : 0}
        borderRadius={1}
      >
        {!isEmpty(images) &&
          images?.map(
            (img, idx) =>
              !isUndefined(img?.filePath) && (
                <Box style={{ position: 'relative' }} key={idx}>
                  <img
                    src={`${IMAGE_URL}?id=${img?.filePath}` || ''}
                    alt={`File`}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '150px',
                      minHeight: '100px',
                      marginRight: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={handleDirectToImage}
                  />
                  <IconButton
                    style={{
                      backgroundColor: 'white',
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                    onClick={handleDelete(idx)}
                    aria-label="delete"
                  >
                    <IconX size={15} />
                  </IconButton>
                </Box>
              ),
          )}
      </Stack>
      <PermissionRender permission={[PERMISSION.CREATE_COMMENT]}>
        <Divider sx={{ marginY: '10px' }} />
        <CommentForm
          data={comment}
          onChange={handleOnChange}
          onSubmit={handleSubmitComment}
          onUpload={handleUploadFile}
          isRequired={Boolean(images)}
        />
      </PermissionRender>
      <ImagePopup
        isOpen={isOpenImage}
        onClose={onCloseImage}
        images={images.map((img) => String(`${IMAGE_URL}?id=${img.filePath}`))}
      />
    </>
  );
};

export default CommentTabContainer;
