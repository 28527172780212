/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import TransactionDrawer from 'components/drawers/TransactionDrawer';
import ConfirmModal from 'components/modals/ConfirmModal';
import TransactionModal from 'components/modals/TransactionModal';
import TableNormal from 'components/table/TableNormal';
import CustomSwitch from 'components/theme-elements/CustomSwitch';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from 'enums';
import { useDisclosure } from 'hooks';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import {
  createTransactionType,
  deleteTransactionType,
  updateTransactionType,
} from 'services/property';
import {
  getTransactionTypePagingAction,
  propertySelector,
  updateTransactionTypeAction,
} from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { CustomResponseType } from 'types/common.type';
import { compareObject, isAllow, isSuccessCode, showData } from 'utils/common';

import { Box, Button, Chip, Tooltip } from '@mui/material';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

const initFormTransactionData = {
  nameVi: '',
  type: '',
  isActive: true,
};

const TransactionTypeTabContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { listTransactionTypePaging } = useSelector(propertySelector);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
  const {
    isOpen: isOpenTransactionModal,
    onOpen: onOpenTransactionModal,
    onClose: onCloseTransactionModal,
  } = useDisclosure();
  const {
    isOpen: isOpenTransaction,
    onOpen: onOpenTransaction,
    onClose: onCloseTransaction,
  } = useDisclosure();
  const [formTransactionData, setFormTransactionData] = useState(initFormTransactionData);
  const [selectedTransactionTypeId, setSelectedTransactionTypeId] = useState<number | undefined>();
  const [transactionById, setTransactionById] = useState(initFormTransactionData);

  const handleChangeFormData = (key: string, value: string | boolean) => {
    setFormTransactionData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleChangeTransactionEdit = (key: string, value: string | boolean) => {
    setTransactionById((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCreateTransaction = async () => {
    const response = await createTransactionType({
      isActive: formTransactionData.isActive,
      nameVi: formTransactionData.nameVi,
      nameEn: formTransactionData.nameVi,
      actionType: formTransactionData.type as TRANSACTION_TYPE,
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getTransactionTypePagingAction({
          page: currentPage,
          limit: rowsPerPage,
        }),
      );
      setFormTransactionData(initFormTransactionData);
      onCloseTransactionModal();
    }
  };

  const handleChangeActiveType =
    (transactionTypeId: number) =>
    (_event: React.ChangeEvent<HTMLInputElement>, isActive: boolean) => {
      dispatch(
        updateTransactionTypeAction({
          transactionTypeId,
          isActive,
        }),
      );
    };
  const handleOpenConfirmTransactionModal = (transactionTypeId: number | undefined) => () => {
    setSelectedTransactionTypeId(transactionTypeId);
    onOpenConfirm();
  };

  const handleDeleteTransactionType = async () => {
    const response = await deleteTransactionType({
      transactionTypeId: selectedTransactionTypeId,
    });
    const dataRes = response.data as CustomResponseType;
    if (isSuccessCode(dataRes.code)) {
      dispatch(
        getTransactionTypePagingAction({
          page: currentPage,
          limit: rowsPerPage,
        }),
      );
      onCloseConfirm();
    }
  };
  const handleOpenTransactionTypeDrawer = (transactionTypeId: number | undefined) => () => {
    const transactionTypeDetail = listTransactionTypePaging.data.find(
      (t) => t.id === transactionTypeId,
    );
    setSelectedTransactionTypeId(transactionTypeId);
    if (transactionTypeDetail) {
      setTransactionById({
        isActive: transactionTypeDetail.isActive as boolean,
        type: transactionTypeDetail.type as TRANSACTION_STATUS,
        nameVi: transactionTypeDetail.nameVi as string,
      });
      onOpenTransaction();
    }
  };
  const handleUpdateTransactionType = async () => {
    const transactionType = listTransactionTypePaging.data.find(
      (t) => t.id === selectedTransactionTypeId,
    );
    let transactionTypeFilter = {};
    if (transactionType) {
      const { isActive, type, nameVi } = transactionType;
      transactionTypeFilter = {
        isActive,
        type,
        nameVi,
      };
    }
    if (!compareObject(transactionTypeFilter, transactionById)) {
      const response = await updateTransactionType({
        transactionTypeId: transactionType?.id,
        isActive: transactionById.isActive,
        actionType: transactionById.type as TRANSACTION_TYPE,
        nameVi: transactionById.nameVi,
      });
      const dataRes = response.data as CustomResponseType;
      if (isSuccessCode(dataRes.code)) {
        dispatch(
          getTransactionTypePagingAction({
            page: currentPage,
            limit: rowsPerPage,
          }),
        );
        onCloseTransaction();
      }
    }
  };

  const dataRow = useMemo(() => {
    return listTransactionTypePaging?.data.map((item) => {
      return [
        showData(item.nameVi),
        <Chip
          key={`chip-${item.id}`}
          label={t(showData(String(item.type)).toLocaleLowerCase())}
          color={item.type === TRANSACTION_STATUS.INCOME ? 'info' : 'error'}
          size="small"
        />,
        <CustomSwitch
          key={`switch-${item.id}`}
          checked={Boolean(item.isActive)}
          onChange={handleChangeActiveType(item.id || -1)}
          disabled={!isAllow([PERMISSION.UPDATE_TRANSACTION_TYPE]) || true}
        />,

        // <Box display="flex" justifyContent="flex-end" gap="12px" key={`button-${item.id}`}>
        //   <PermissionRender permission={[PERMISSION.UPDATE_TRANSACTION_TYPE]}>
        //     <Tooltip placement="top" title={t('edit-transaction-type')}>
        //       <Button
        //         variant="outlined"
        //         color="info"
        //         onClick={handleOpenTransactionTypeDrawer(item.id)}
        //       >
        //         <IconEdit />
        //       </Button>
        //     </Tooltip>
        //   </PermissionRender>
        //   <PermissionRender permission={[PERMISSION.DELETE_TRANSACTION_TYPE]}>
        //     <Tooltip placement="top" title={t('delete-transaction-type')}>
        //       <Button
        //         variant="outlined"
        //         color="error"
        //         onClick={handleOpenConfirmTransactionModal(item.id)}
        //       >
        //         <IconTrash />
        //       </Button>
        //     </Tooltip>
        //   </PermissionRender>
        // </Box>,
      ];
    });
  }, [listTransactionTypePaging, t]);

  useEffect(() => {
    dispatch(
      getTransactionTypePagingAction({
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [currentPage, dispatch, rowsPerPage]);

  return (
    <Box textAlign="right">
      {/* <PermissionRender permission={[PERMISSION.CREATE_TRANSACTION_TYPE]}>
        <Button
          color="primary"
          variant="contained"
          endIcon={<IconPlus width={18} />}
          sx={{
            marginBottom: '10px',
          }}
          onClick={onOpenTransactionModal}
        >
          {t('create-transaction-type')}
        </Button>
      </PermissionRender> */}
      <TableNormal
        header={[t('name'), t('category'), t('active')]}
        data={dataRow}
        total={listTransactionTypePaging?.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
      <TransactionModal
        data={formTransactionData}
        onChange={handleChangeFormData}
        onClose={onCloseTransactionModal}
        isOpen={isOpenTransactionModal}
        onSubmit={handleCreateTransaction}
      />

      <TransactionDrawer
        data={transactionById}
        onChange={handleChangeTransactionEdit}
        onClose={onCloseTransaction}
        isOpen={isOpenTransaction}
        onSubmit={handleUpdateTransactionType}
      />
      <ConfirmModal
        onSubmit={handleDeleteTransactionType}
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
      />
    </Box>
  );
};

export default TransactionTypeTabContainer;
