import React from 'react';

import TransactionTabContainer from 'containers/room/tab/TransactionTabContainer';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import { PERMISSION } from 'constants/permission';

const TransactionTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SB_TAB_ROOM_LIST]}>
      <TransactionTabContainer />;
    </PermissionPageWrapper>
  );
};

export default TransactionTab;
