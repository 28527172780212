import React, { forwardRef, useImperativeHandle, useState } from 'react';

import AutoComplete from 'components/shared/AutoComplete';
import InputPrice from 'components/shared/InputPrice';
import RadioGroup from 'components/shared/RadioGroup';
import SwitchLabel from 'components/shared/SwitchLabel';
import TextInput from 'components/shared/TextInput';
import { JOI } from 'constants/joi';
import { UnitTypeRes } from 'types/general.type';
import { ServiceType } from 'types/service.type';
import { createSchema, validateSchema } from 'utils/joi';

import { Grid } from '@mui/material';
import { IconCoin, IconCube, IconDotsCircleHorizontal } from '@tabler/icons-react';
import { ROOM_PAID_MONTH } from 'enums';
import { PERMISSION } from 'constants/permission';

interface Props {
  data: ServiceType;
  listUnitTypeOptions?: UnitTypeRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  isColumn?: boolean;
  isDisable?: boolean;
}
const initError = { name: '', isMeter: '', unitId: '', referencePrice: '' };

const ServiceForm = forwardRef(
  (
    {
      data,
      onChange,
      onSubmit,
      listUnitTypeOptions = [],
      isColumn = false,
      isDisable = false,
    }: Props,
    ref,
  ) => {
    const [serviceError, setServiceError] = useState(initError);
    const schema = createSchema({
      name: JOI.REQUIRE,
      isMeter: JOI.REQUIRE,
      unitId: JOI.REQUIRE,
      referencePrice: JOI.PRICE,
    });

    const handleChange = (key: string) => (value: string | boolean) => {
      onChange(key, value);
    };

    const handleSubmitService = () => {
      const clone = { ...data };
      delete clone.isActive;
      delete clone.isDefault;
      delete clone.isPaidStartOfMonth;
      const resError = validateSchema(schema, clone);
      setServiceError(resError.errorMessage);
      if (!resError.isError) {
        onSubmit && onSubmit();
      }
    };
    useImperativeHandle(ref, () => ({
      handleSubmitService,
    }));

    return (
      <Grid container spacing="12px">
        <Grid item xs={12} container spacing={'12px'}>
          <Grid item lg={6} xs={12}>
            <TextInput
              Icon={IconCube}
              title={'service-name'}
              value={data.name}
              onChange={handleChange('name')}
              placeholder={'placeholder.service'}
              errorMessage={serviceError.name}
              isDisable={isDisable}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RadioGroup
              title="time-to-payment"
              value={Number(data.isPaidStartOfMonth)}
              onChange={handleChange('isPaidStartOfMonth')}
              isDisable={isDisable}
              options={[
                {
                  value: ROOM_PAID_MONTH.PREV,
                  label: 'start-month',
                  permission: [PERMISSION.SUB_TAB_SERVICE_FEE_TYPES],
                },
                {
                  value: ROOM_PAID_MONTH.LAST,
                  label: 'end-month',
                  permission: [PERMISSION.SUB_TAB_SERVICE_FEE_TYPES],
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={isColumn ? 12 : 6}>
          <RadioGroup
            title="method"
            value={data.isMeter}
            onChange={handleChange('isMeter')}
            options={[
              {
                value: 'unit',
                label: 'calculated-per-unit',
                permission: [PERMISSION.SUB_TAB_SERVICE_FEE_TYPES],
              },
              {
                value: 'metered',
                label: 'calculated-based-on-usage-meter',
                permission: [PERMISSION.SUB_TAB_SERVICE_FEE_TYPES],
              },
            ]}
            isDisable={isDisable}
          />
        </Grid>
        <Grid item xs={12} lg={isColumn ? 12 : 6}>
          <AutoComplete
            isRequired
            title="unit-of-measurement"
            options={listUnitTypeOptions.map((unitType) => ({
              label: unitType.nameVi || '',
              value: String(unitType.id),
            }))}
            value={data.unitId}
            placeHolder="placeholder.choose-unit"
            errorMessage={serviceError.unitId}
            onChange={handleChange('unitId')}
            Icon={IconDotsCircleHorizontal}
            isDisable={isDisable}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={isColumn ? 12 : 6}
          spacing="12px"
          order={{ xs: 3, lg: isColumn ? 3 : 2 }}
        >
          <Grid item xs={12}>
            <SwitchLabel
              title="default-in-contract"
              isChecked={Boolean(data.isDefault)}
              onChange={handleChange('isDefault')}
              isDisable={isDisable}
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchLabel
              title="active"
              isChecked={Boolean(data.isActive)}
              onChange={handleChange('isActive')}
              isDisable={isDisable}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={isColumn ? 12 : 6} order={{ xs: 2, lg: isColumn ? 2 : 3 }}>
          <InputPrice
            onChange={handleChange('referencePrice')}
            value={data.referencePrice}
            title={'price'}
            placeholder={'30.000VNĐ...'}
            Icon={IconCoin}
            errorMessage={serviceError.referencePrice}
            isDisable={isDisable}
          />
        </Grid>
      </Grid>
    );
  },
);

export default React.memo(ServiceForm);
