import { PERMISSION } from 'constants/permission';
import DepositTransactionContainerTab from 'containers/report/tab/DepositTransactionContainerTab';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const DepositTransactionTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_DEPOSIT_TRANSACTION]}>
      <DepositTransactionContainerTab />
    </PermissionPageWrapper>
  );
};

export default DepositTransactionTab;
