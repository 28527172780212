import React from 'react';

import { EMPTY_CONTENT } from 'constants/common';
import { INVOICE_STATUS, INVOICE_TYPE } from 'enums';
import { useTranslation } from 'react-i18next';

import { Chip } from '@mui/material';

type Props = {
  status?: string;
};

const InvoiceChip = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <Chip
      size="small"
      label={t(INVOICE_TYPE[status as keyof typeof INVOICE_TYPE] ?? EMPTY_CONTENT)}
      color={
        status === INVOICE_STATUS.WAITING_FOR_PAYMENT
          ? 'info'
          : status === INVOICE_STATUS.PAID
          ? 'success'
          : status === INVOICE_STATUS.PARTIAL
          ? 'warning'
          : status === INVOICE_STATUS.WAITING_FOR_CONFIRM || status === INVOICE_STATUS.UNPAID
          ? 'default'
          : status === INVOICE_STATUS.CANCELED
          ? 'error'
          : status === INVOICE_STATUS.EXPIRED
          ? 'error'
          : undefined
      }
    />
  );
};

export default React.memo(InvoiceChip);
