import { CountryRes, DistrictRes, ProvinceRes, RoleRes, UnitTypeRes } from 'types/general.type';
import { VehicleTypeRes } from 'types/vehicle.type';

import { createSlice } from '@reduxjs/toolkit';

import { locationReducer, roleReducer, serviceFeeUnitReducer } from './reducers';
import { vehicleTypeReducer } from './reducers/vehicleType.reducer';

export interface GeneralState {
  listCountry: CountryRes[];
  listProvince: ProvinceRes[];
  listDistrict: DistrictRes[];
  listServiceFeeUnit: UnitTypeRes[];
  listRole: RoleRes[];
  listVehicleType: VehicleTypeRes[];
}

const initialState: GeneralState = {
  listCountry: [],
  listProvince: [],
  listDistrict: [],
  listServiceFeeUnit: [],
  listRole: [],
  listVehicleType: [],
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    clearListDistrict: (state: GeneralState) => {
      state.listDistrict = [];
    },
  },
  extraReducers: (builder) => {
    locationReducer.extraReducers(builder);
    serviceFeeUnitReducer.extraReducers(builder);
    roleReducer.extraReducers(builder);
    vehicleTypeReducer.extraReducers(builder);
  },
});

export const { clearListDistrict } = generalSlice.actions;
export default generalSlice.reducer;
