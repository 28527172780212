import { CustomResponseType } from 'types/common.type';
import { GetContractFees } from 'types/contract.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getContractFeeListAction } from './actions';

interface ContractFeeState {
  listContractFee: GetContractFees[];
}
const initialState: ContractFeeState = {
  listContractFee: [],
};
export const contractFeeSlice = createSlice({
  name: 'contract',
  reducers: {
    resetListContractFee: (state) => {
      state.listContractFee = initialState.listContractFee;
    },
  },
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<ContractFeeState>) => {
    builder.addCase(
      getContractFeeListAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listContractFee = action.payload.data;
        }
      },
    );
  },
});

export const { resetListContractFee } = contractFeeSlice.actions;
export default contractFeeSlice.reducer;
