import { PaymentMethodType } from 'types/payment-method.type';
import {
  FloorType,
  GetBranchDetailRes,
  GetBranchOverviewRes,
  GetBuildingInBranchRes,
  GetListRoomRes,
  GetListRoomTypeRes,
  GetListServiceRes,
  GetListTransactionTypeRes,
  OtherTransaction,
} from 'types/property.type';
import { RoomInFloor } from 'types/room.type';

import { createSlice } from '@reduxjs/toolkit';

import {
  branchReducer,
  buildingReducer,
  roomReducer,
  serviceFeeReducer,
  transactionTypeReducer,
} from './reducers';
import { otherTransactionReducer } from './reducers/otherTransaction.reducer';
import { LocalStorage } from 'utils/localStorage';
import { STORAGE_KEY } from 'enums/localStorage';

const Storage = new LocalStorage()
export interface PropertyState {
  buildingId: number;
  listRoom: GetListRoomRes;
  listRoomTypePaging: { total: number; data: GetListRoomTypeRes[] };
  listRoomType: GetListRoomTypeRes[];
  listTransactionTypePaging: { total: number; data: GetListTransactionTypeRes[] };
  listTransactionTypeIncome: GetListTransactionTypeRes[];
  listTransactionTypeExpense: GetListTransactionTypeRes[];
  listService: GetListServiceRes[];
  branchDetail: GetBranchDetailRes;
  branchOverview: GetBranchOverviewRes;
  buildingsInBranch: GetBuildingInBranchRes[];
  buildingsActiveInBranch: GetBuildingInBranchRes[];
  buildingDetail: GetBuildingInBranchRes;
  floorsInBuilding: FloorType[];
  listRoomInFloor: RoomInFloor[];
  listPaymentMethodPaging: { total: number; data: PaymentMethodType[] };
  listPaymentMethod: PaymentMethodType[];
  listOtherTransactionPaging: { total: number; data: OtherTransaction[] };
}



const buildingIdInit = Storage.getStorageItem(STORAGE_KEY.BUILDING_ID);

const initialState: PropertyState = {
  buildingId: buildingIdInit,
  listRoomTypePaging: {
    total: 0,
    data: [],
  },
  listRoomType: [],
  listTransactionTypePaging: {
    total: 0,
    data: [],
  },
  listTransactionTypeIncome: [],
  listTransactionTypeExpense: [],
  listService: [],
  listRoom: {},
  branchDetail: {},
  branchOverview: {},
  buildingsInBranch: [],
  buildingsActiveInBranch: [],
  buildingDetail: {},
  floorsInBuilding: [],
  listRoomInFloor: [],
  listPaymentMethodPaging: {
    total: 0,
    data: [],
  },
  listPaymentMethod: [],
  listOtherTransactionPaging: {
    total: 0,
    data: [],
  },
};

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setBuilding(state, action) {
      state.buildingId = action.payload;
      Storage.setStorageItem(STORAGE_KEY.BUILDING_ID, action.payload);
    },
    ...branchReducer.reducers,
    ...buildingReducer.reducers,
    ...roomReducer.reducers,
    ...serviceFeeReducer.reducers,
    ...transactionTypeReducer.reducers,
    ...otherTransactionReducer.reducers,
  },
  extraReducers: (builder) => {
    branchReducer.extraReducers(builder);
    buildingReducer.extraReducers(builder);
    roomReducer.extraReducers(builder);
    serviceFeeReducer.extraReducers(builder);
    transactionTypeReducer.extraReducers(builder);
    otherTransactionReducer.extraReducers(builder);
  },
});

export const { setBuilding } = propertySlice.actions;
export default propertySlice.reducer;
