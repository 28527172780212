import { PERMISSION } from 'constants/permission';
import InvoiceTransactionContainerTab from 'containers/report/tab/InvoiceTransactionContainerTab';
import PermissionPageWrapper from 'layouts/permission/PermissionPageWrapper';
import React from 'react';

const InvoiceTransactionTab = () => {
  return (
    <PermissionPageWrapper permission={[PERMISSION.SUB_TAB_INVOICE_TRANSACTION]}>
      <InvoiceTransactionContainerTab />;
    </PermissionPageWrapper>
  );
};

export default InvoiceTransactionTab;
