import React, { RefObject, useRef } from 'react';

import DialogWrapper from 'components/dialog/DialogWrapper';
import ContractFeeForm from 'components/forms/ContractFeeForm';
import { useTranslation } from 'react-i18next';
import { ModalActionType } from 'types/common.type';
import { GetListServiceRes } from 'types/property.type';
import { ServiceFeeForm } from 'types/service.type';

interface Props extends ModalActionType {
  data: ServiceFeeForm;
  listServiceType?: GetListServiceRes[];
  onChange: (key: string, value: string | boolean) => void;
  onSubmit?: () => void;
  unitTypeId?: string;
}

const ContractFeeModal = ({
  data,
  isOpen,
  onClose,
  onChange,
  onSubmit,
  listServiceType = [],
  unitTypeId,
}: Props) => {
  const { t } = useTranslation();

  const submitRef: RefObject<any> = useRef<{ handleSubmitContractFee: () => void }>();

  const handleSubmitContractFee = () => {
    submitRef.current?.handleSubmitContractFee();
  };
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <DialogWrapper
      title={String(t('create-service'))}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmitContractFee}
    >
      <ContractFeeForm
        data={data}
        listServiceType={listServiceType}
        onChange={onChange}
        ref={submitRef}
        onSubmit={onSubmit}
        unitTypeId={unitTypeId}
      />
    </DialogWrapper>
  );
};

export default React.memo(ContractFeeModal);
