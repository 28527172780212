import React from 'react';

import { EMPTY_CONTENT } from 'constants/common';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from 'enums';
import { useTranslation } from 'react-i18next';

import { Chip } from '@mui/material';

type Props = {
  status?: string;
};

const TransactionChip = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <Chip
      key="chip"
      size="small"
      label={t(TRANSACTION_TYPE[status as keyof typeof TRANSACTION_TYPE] ?? EMPTY_CONTENT)}
      color={
        status === TRANSACTION_STATUS.INCOME
          ? 'info'
          : status === TRANSACTION_STATUS.EXPENSE
          ? 'error'
          : undefined
      }
    />
  );
};

export default React.memo(TransactionChip);
