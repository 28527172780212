import { CommonReq } from 'types/common.type';

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getRevenueCashFlow,
  getRevenueCurrentMonth,
  getRevenueDashboard,
  getRoomPercentageDashboard,
} from 'services/dashboard.service';
import { DashboardType } from 'types/dashboard.type';

export const getRoomPercentageDashboardAction = createAsyncThunk(
  'dashboard/getRoomPercentageDashboardAction',
  async (payload: CommonReq) => {
    const response = await getRoomPercentageDashboard(payload);

    return response.data;
  },
);

export const getRevenueDashboardAction = createAsyncThunk(
  'dashboard/getRevenueDashboardAction',
  async (payload: CommonReq & DashboardType) => {
    const response = await getRevenueDashboard(payload);

    return response.data;
  },
);

export const getRevenueCurrentMonthAction = createAsyncThunk(
  'dashboard/getRevenueCurrentMonthAction',
  async (payload: CommonReq & DashboardType) => {
    const response = await getRevenueCurrentMonth(payload);

    return response.data;
  },
);

export const getRevenueCashFlowAction = createAsyncThunk(
  'dashboard/getRevenueCashFlowAction',
  async (payload: CommonReq & DashboardType) => {
    const response = await getRevenueCashFlow(payload);

    return response.data;
  },
);
