import React, { useEffect, useRef, useState } from 'react';

import Logo from 'assets/images/products/service-fee.jpg';
import { useTranslation } from 'react-i18next';
import { formatPrice, isMobile, showData } from 'utils/common';

import { Box, Button, Card, Tooltip, Typography, useTheme } from '@mui/material';
import { IconAntennaBars3, IconCoin, IconTrash } from '@tabler/icons-react';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

type Props = {
  name?: string;
  price?: number;
  unit?: string;
  amount?: string;
  isMeter?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  isDisableDelete?: boolean;
};

function ContractFeeCard({
  name,
  price,
  unit,
  amount,
  isMeter = false,
  onClick,
  onDelete,
  isDisableDelete = false,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const textElementRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const compareSize = () => {
    const element = textElementRef.current;
    const compare = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;

    setIsOverflow(compare);
  };

  useEffect(() => {
    compareSize();
  }, []);

  const handleOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    onClick && onClick();
  };

  const handleOnDelete = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    onDelete && onDelete();
  };

  return (
    <Card
      sx={{
        p: '12px',
        position: 'relative',
        cursor: 'pointer',
        maxWidth: '100%',
        height: '165px',
        boxShadow: theme.shadows[5],
        '&:hover': {
          boxShadow: theme.shadows[0],
        },
        opacity: 1,
        bgcolor: 'white',
      }}
      onClick={handleOnClick}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="12px">
        <Tooltip placement="top" title={showData(name)} disableHoverListener={!isOverflow}>
          <Typography
            ref={textElementRef}
            variant="h5"
            ml="6px"
            whiteSpace="nowrap"
            width="calc(100% - 80px)"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {showData(name)}
          </Typography>
        </Tooltip>
      </Box>
      <Box display="flex" gap="12px">
        <Box
          width="150px"
          maxHeight="100px"
          height="100%"
          borderRadius="6px"
          overflow="hidden"
          boxShadow={theme.shadows[5]}
        >
          <img
            alt=""
            width="100%"
            height="100px"
            src={Logo}
            style={{ objectFit: 'contain', height: '100%' }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="6px"
          width={`calc(100% - ${isMobile ? 20 : 162}px)`}
        >
          <Box display="flex" alignItems="flex-start">
            <IconCoin width="24px" color="var(--gray-01)" />
            <Typography
              variant="subtitle1"
              ml="6px"
              width="calc(100% - 30px)"
              textOverflow="ellipsis"
              overflow="hidden"
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
              }}
            >
              {showData(price ? formatPrice(price) : null)}/ {showData(unit)}
            </Typography>
          </Box>
          {!isMeter && (
            <Box display="flex" alignItems="flex-start">
              <IconAntennaBars3 width="24px" color="var(--gray-01)" />
              <Typography
                variant="subtitle1"
                ml="6px"
                width="calc(100% - 30px)"
                textOverflow="ellipsis"
                overflow="hidden"
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                  },
                }}
              >
                {/* NOTE: amount */}
                {showData(amount)} {showData(unit)}
              </Typography>
            </Box>
          )}
          <PermissionRender permission={[PERMISSION.DELETE_CONTRACT_FEE]}>
            <Box display="flex" alignItems="center">
              <Tooltip
                placement="top"
                title={t('delete-service')}
                sx={{ position: 'absolute', bottom: '12px', right: '12px' }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleOnDelete}
                  disabled={isDisableDelete}
                >
                  <IconTrash />
                </Button>
              </Tooltip>
            </Box>
          </PermissionRender>
        </Box>
      </Box>
    </Card>
  );
}

export default React.memo(ContractFeeCard);
