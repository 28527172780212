import React, { useEffect, useMemo, useState } from 'react';

import AddActionCard from 'components/card/AddActionCard';
import ContractFeeCard from 'components/card/ContractFeeCard';
import ContractFeeDrawer from 'components/drawers/ContractFeeDrawer';
import ConfirmModal from 'components/modals/ConfirmModal';
import ContractFeeModal from 'components/modals/ContractFeeModal';
import { useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { bookingSelector } from 'store/booking';
import {
  contractFeeSelector,
  createContractFeeAction,
  deleteContractFeeAction,
  getContractFeeListAction,
  resetListContractFee,
  updateContractFeeAction,
} from 'store/contractFee';
import { customizerSelector } from 'store/customizer';
import { getListServiceAction, propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import { compareObject, formatNumber, isAllow } from 'utils/common';

import { Grid } from '@mui/material';
import PermissionRender from 'layouts/permission/PermissionRender';
import { PERMISSION } from 'constants/permission';

const initFormContractFee = {
  serviceFeeTypeId: '',
  unitPrice: '',
  amount: '',
  discount: '',
  note: '',
  isMeter: '',
};
const initFormContractFeeEdit = {
  serviceFeeTypeId: '',
  unitPrice: '',
  amount: '',
  discount: '',
  note: '',
  isMeter: '',
};

const ServiceTabContainer = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenContractFeeEdit,
    onOpen: onOpenContractFeeEdit,
    onClose: onCloseContractFeeEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenServiceConfirm,
    onOpen: onOpenServiceConfirm,
    onClose: onCloseServiceConfirm,
  } = useDisclosure();
  const dispatch = useDispatch();
  const { currentBookingInRoom, selectedContractId } = useSelector(bookingSelector);
  const { listService, buildingId } = useSelector(propertySelector);
  const { isLanguage } = useSelector(customizerSelector);
  const { listContractFee } = useSelector(contractFeeSelector);

  const [contractFee, setContractFee] = useState(initFormContractFee);
  const [contractFeeEdit, setContractFeeEdit] = useState(initFormContractFeeEdit);
  const [selectedId, setSelectedId] = useState<number>();
  const [selectServiceTypeName, setSelectServiceTypeName] = useState('');
  const [selectedUnitId, setSelectedUnitId] = useState('');

  const handleChangeContractFee = (key: string, value: string | boolean) => {
    if (key === 'serviceFeeTypeId') {
      setSelectedUnitId('');
      const serviceType = listService.find(
        (serviceType) => String(serviceType.id) == String(value),
      );
      setSelectServiceTypeName(String(serviceType?.name));
      serviceType &&
        setContractFee((prev) => ({
          ...prev,
          [key]: String(value),
          unitPrice: String(serviceType.referencePrice),
          amount: String(Boolean(serviceType.isMeter) ? '1' : ''),
          isMeter: String(serviceType?.isMeter),
        }));
      if (serviceType?.unitId === '1') {
        setSelectedUnitId(serviceType?.unitId);
        setContractFee((prev) => ({
          ...prev,
          [key]: String(value),
          amount: String(currentBookingInRoom.currentContract?.numberUsersInRoom),
        }));
      }
    } else {
      setContractFee((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };
  const handleCloseContractFeeModal = () => {
    onClose();
    setContractFee(initFormContractFee);
  };
  const handleCreateContractFee = () => {
    dispatch(
      createContractFeeAction({
        serviceFeeTypeId: contractFee.serviceFeeTypeId,
        unitPrice: formatNumber(contractFee.unitPrice),
        amount: contractFee.amount,
        discount: Number(contractFee.discount),
        note: contractFee.note ?? '',
        name: selectServiceTypeName,
        contractId: Number(currentBookingInRoom.currentContract?.id),
        callback: () => {
          dispatch(
            getContractFeeListAction({
              contractId: currentBookingInRoom.currentContract?.id,
            }),
          );
          handleCloseContractFeeModal();
        },
      }),
    );
  };
  const handleChangeContractFeeEdit = (key: string, value: string | boolean) => {
    setSelectedUnitId('');
    if (key === 'serviceFeeTypeId') {
      const serviceType = listService.find(
        (serviceType) => String(serviceType.id) == String(value),
      );
      setSelectServiceTypeName(String(serviceType?.name));
      serviceType &&
        setContractFeeEdit((prev) => ({
          ...prev,
          [key]: String(value),
          unitPrice: String(serviceType.referencePrice),
          amount: String(Boolean(serviceType.isMeter) && '1'),
          isMeter: String(serviceType?.isMeter),
        }));
    } else {
      setContractFeeEdit((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };
  const handleCloseContractFeeEditModal = () => {
    onCloseContractFeeEdit();
    setContractFeeEdit(initFormContractFeeEdit);
  };
  const handleOpenContractFeeEdit = (serviceId: number | undefined) => () => {
    const contractFee = listContractFee?.find((service) => service.id === serviceId);
    setSelectedId(serviceId);
    if (contractFee?.serviceFeeType?.unitId === '1') {
      setSelectedUnitId(contractFee?.serviceFeeType?.unitId);
    }
    onOpenContractFeeEdit();
    setContractFeeEdit({
      unitPrice: String(contractFee?.unitPrice),
      isMeter: String(contractFee?.serviceFeeType?.isMeter),
      serviceFeeTypeId: String(contractFee?.serviceFeeTypeId),
      amount: String(contractFee?.amount),
      discount: String(contractFee?.discount),
      note: String(contractFee?.note) ?? '',
    });
  };

  const handleUpdateContractFee = () => {
    const selectedContractFee = listContractFee.find((service) => service.id === selectedId);
    let contractFeeFilter = {};
    if (selectedContractFee) {
      const { serviceFeeTypeId, unitPrice, amount, discount, note } = selectedContractFee;
      contractFeeFilter = {
        serviceFeeTypeId,
        unitPrice,
        amount,
        discount,
        note,
        isMeter: String(selectedContractFee?.serviceFeeType?.isMeter),
      };
    }
    if (!compareObject(contractFeeFilter, contractFeeEdit)) {
      dispatch(
        updateContractFeeAction({
          contractId: currentBookingInRoom.currentContract?.id,
          discount: formatNumber(contractFeeEdit.discount),
          note: String(contractFeeEdit.note),
          unitPrice: formatNumber(contractFeeEdit.unitPrice),
          amount: formatNumber(contractFeeEdit.amount),
          contractFeeId: Number(selectedId),
          callback: () => {
            dispatch(
              getContractFeeListAction({
                contractId: currentBookingInRoom.currentContract?.id,
              }),
            );
            handleCloseContractFeeEditModal();
          },
        }),
      );
    }
  };

  const handleOpenConfirmServiceModal = (serviceId: number) => () => {
    setSelectedId(serviceId);
    onOpenServiceConfirm();
  };
  const handleDeleteService = () => {
    dispatch(
      deleteContractFeeAction({
        contractId: currentBookingInRoom.currentContract?.id,
        contractFeeId: selectedId,
        callback: () => {
          onCloseServiceConfirm();
          dispatch(
            getContractFeeListAction({
              contractId:
                selectedContractId !== 0
                  ? selectedContractId
                  : currentBookingInRoom.currentContract?.id,
            }),
          );
        },
      }),
    );
  };

  const processedServiceList = useMemo(() => {
    return listContractFee?.map((serviceType) => {
      return {
        id: serviceType.id ?? -1,
        name: serviceType?.name,
        price: Number(serviceType?.unitPrice),
        isActive: true,
        unit:
          isLanguage === 'vi'
            ? serviceType.serviceFeeType?.unit?.nameVi
            : serviceType.serviceFeeType?.unit?.nameEn,
        amount: serviceType.amount,
        isMeter: serviceType.serviceFeeType?.isMeter,
      };
    });
  }, [listContractFee, isLanguage]);

  // NOTE: service type list in building is active
  useEffect(() => {
    dispatch(
      getListServiceAction({
        isActive: true,
        buildingId: buildingId,
      }),
    );
  }, [buildingId, dispatch]);

  useEffect(() => {
    // NOTE: api service fee by contract
    // TODO: change to call api by booking
    if (currentBookingInRoom.currentContract?.id || selectedContractId) {
      dispatch(
        getContractFeeListAction({
          contractId:
            Number(selectedContractId) !== 0
              ? selectedContractId
              : currentBookingInRoom.currentContract?.id,
        }),
      );
    } else {
      dispatch(resetListContractFee());
    }
  }, [currentBookingInRoom.currentContract, dispatch, buildingId, selectedContractId]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {processedServiceList?.map((ele, idx) => {
          return (
            <Grid key={idx} item xs={12} md={6} lg={4}>
              <ContractFeeCard
                name={ele.name}
                price={ele.price}
                onClick={handleOpenContractFeeEdit(ele.id)}
                onDelete={handleOpenConfirmServiceModal(ele.id)}
                unit={ele.unit}
                amount={ele.amount}
                isMeter={Boolean(ele.isMeter)}
                isDisableDelete={currentBookingInRoom.currentContract?.id !== selectedContractId}
              />
            </Grid>
          );
        })}
        <PermissionRender permission={[PERMISSION.CREATE_CONTRACT_FEE]}>
          <Grid item xs={12} md={6} lg={4}>
            {currentBookingInRoom.currentContract?.id &&
              currentBookingInRoom.currentContract?.id === selectedContractId && (
                <AddActionCard
                  height="165px"
                  label={t('create-service') || ''}
                  onClick={onOpen}
                  isDisable={currentBookingInRoom.currentContract?.id !== selectedContractId}
                />
              )}
          </Grid>
        </PermissionRender>
      </Grid>
      <ContractFeeModal
        data={contractFee}
        onChange={handleChangeContractFee}
        onClose={handleCloseContractFeeModal}
        isOpen={isOpen}
        listServiceType={listService}
        onSubmit={handleCreateContractFee}
        unitTypeId={selectedUnitId}
      />

      <ContractFeeDrawer
        data={contractFeeEdit}
        onChange={handleChangeContractFeeEdit}
        onClose={handleCloseContractFeeEditModal}
        isOpen={isOpenContractFeeEdit}
        listServiceType={listService}
        onSubmit={handleUpdateContractFee}
        isDisableServiceType={true}
        unitTypeId={selectedUnitId}
        isDisable={
          currentBookingInRoom.currentContract?.id !== selectedContractId ||
          !isAllow([PERMISSION.UPDATE_CONTRACT_FEE])
        }
      />
      <ConfirmModal
        isOpen={isOpenServiceConfirm}
        onClose={onCloseServiceConfirm}
        onSubmit={handleDeleteService}
      />
    </React.Fragment>
  );
};

export default ServiceTabContainer;
