import {
  getListRoomInBuilding,
  getListRoomInFloor,
  getListRoomType,
  getOptionRoomType,
} from 'services/property';
import { CommonReq, PagingReq } from 'types/common.type';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getListRoomTypeAction = createAsyncThunk(
  'property/getListRoomTypeAction',
  async (payload: CommonReq & PagingReq) => {
    const response = await getListRoomType(payload);

    return response.data;
  },
);
export const getListRoomAction = createAsyncThunk(
  'property/getListRoomAction',
  async (payload: CommonReq) => {
    const response = await getListRoomInBuilding(payload);

    return response.data;
  },
);
export const getOptionRoomAction = createAsyncThunk(
  'property/getOptionRoomAction',
  async (payload: CommonReq) => {
    const response = await getOptionRoomType(payload);

    return response.data;
  },
);

export const getListRoomInFloorAction = createAsyncThunk(
  'property/getListRoomInFloorAction',
  async (payload: CommonReq) => {
    const response = await getListRoomInFloor(payload);

    return response.data;
  },
);
