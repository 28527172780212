import _ from 'lodash';
import { CustomResponseType } from 'types/common.type';
import { GetListUserRes } from 'types/user.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getListUserInBuildingAction,
  getListUserPagingAction,
  getOverviewInvoiceByClientAction,
  getUserDetailAction,
  updateUserAction,
} from './actions';

interface UserState {
  listUserPaging: { data: GetListUserRes[]; total: number };
  listUser: GetListUserRes[];
  userDetailById: GetListUserRes;
  overviewInvoiceByClient: GetListUserRes;
}
const initialState: UserState = {
  listUserPaging: {
    data: [],
    total: 0,
  },
  listUser: [],
  userDetailById: {},
  overviewInvoiceByClient: {},
};
export const userSlice = createSlice({
  name: 'user',
  reducers: {},
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<UserState>) => {
    builder.addCase(
      getListUserPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listUserPaging = action.payload.data;
        }
      },
    );

    builder.addCase(
      updateUserAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          const dataRes = action.payload.data as GetListUserRes;
          const hashList = _.mapKeys(state.listUserPaging.data, 'id');
          state.listUserPaging = {
            total: state.listUserPaging.total,
            data: state.listUserPaging.data.map((user) => {
              return user.id == dataRes.id ? dataRes : hashList[user.id as keyof typeof hashList];
            }),
          };
        }
      },
    );

    builder.addCase(
      getUserDetailAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.userDetailById = action.payload.data;
        }
      },
    );

    builder.addCase(
      getListUserInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listUser = action.payload.data;
        }
      },
    );
    builder.addCase(
      getOverviewInvoiceByClientAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.overviewInvoiceByClient = action.payload.data;
        }
      },
    );
  },
});

export const {} = userSlice.actions;
export default userSlice.reducer;
