import { useState } from 'react';

import { DEFAULT_LIMIT } from 'constants/common';

type Props = {
  limit?: number;
};
const usePagination = ({ limit }: Props = { limit: DEFAULT_LIMIT }) => {
  const [rowsPerPage, setRowPerPage] = useState(limit);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangeCurrentPage = (newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeLimit = (newLimit: number) => {
    setRowPerPage(newLimit);
    setCurrentPage(1);
  };

  return { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage };
};

export default usePagination;
